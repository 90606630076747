/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultCreateButtonAndSearchFormContainer } from "../../components/DefaultCreateButtonAndSearchFormContainer";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import getErrorMessage from "../../helpers/get-error-message";
import Author from "../../models/author";
import {
  getAllAuthors,
  deleteAuthor,
  updateAuthor,
  toogleActiveAuthor as toogleActiveAuthorService,
} from "../../services/authors";
import { activateOrInactivateCourse as activateOrInactivateCourseService } from "../../services/modules";
import { AuthorsContainer } from "./style";
import CreateAndEditContent from "../CreateAndEditAuthors";

const Authors: React.FC = () => {
  const history = useHistory();

  const [authors, setAuthors] = useState([] as Author[]);
  const [authorName, setAuthorName] = useState("");
  const [createAndEditAuthor, setCreateAndEditAuthor ] = useState(false);

  const getAuthors = async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    const authors = await getAllAuthors({ name: authorName });
    setAuthors(authors || []);
  };

  const editAuthor = useCallback(
    (authorId: any) => {
      history.push(`edit-author/${authorId}`);
    },
    [history]
  );

  const toogleActiveAuthor = async (authorId: string, active: boolean) => {
    const result = await Swal.fire({
      icon: "question",
      text: `Tem certeza que deseja ${
        active ? "ativar" : "inativar"
      } este autor?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      try {
        await toogleActiveAuthorService(authorId, active);

        Swal.fire({
          icon: "success",
          title: `Autor ${active ? "ativado" : "inativado"} com sucesso!`,
        });

        await getAuthors();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: `Erro ao ${active ? "ativar" : "inativar"} autor`,
          text: errorMessage.replace('uma trilha','um curso'),
        });
      }
    }
  };

  const removeAuthor = useCallback(async (authorId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este autor?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteAuthor(authorId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Autor excluído com sucesso!",
          });

          await getAuthors();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir autor",
            text: errorMessage.replace('uma trilha','um curso')
          });
        }
      }
    });
  }, []);



  const createAuthor = () => {
  setCreateAndEditAuthor(true); 
  };

  const activateOrInactivateCourse = useCallback(
    async (courseId: string, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${
          activate ? "ativar" : "inativar"
        } este autor?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCourseService(courseId, activate);

            getAuthors();
          } catch (error) {
            const errorMessage = getErrorMessage(error)
            Swal.fire({
              title: "Erro",
              text: errorMessage,
            });
          }
        }
      });
    },
    []
  );

  const authorsToBeShown = useMemo(() => {
    return authors && authors.length
      ? authors.map((author) => ({
          id: author.author_id,
          name: author.name,
          description: author.description,
          active: author.is_active ? (
            <AiOutlineCheck size={16} />
          ) : (
            <AiOutlineClose size={16} />
          ),
          actions: (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
            >
              <DefaultButton
                className="small warning"
                onClick={() =>
                  toogleActiveAuthor(author.author_id!, !author.is_active!)
                }
                title="Ativar/Inativar Autor"
              >
                {author.is_active ? (
                  <AiOutlineClose size={16} />
                ) : (
                  <AiOutlineCheck size={16} />
                )}
              </DefaultButton>{" "}
              <DefaultButton
                className="small info"
                onClick={() => editAuthor(author.author_id)}
                title="Editar Autor"
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => removeAuthor(author.author_id)}
                className="small danger"
                title="Excluir Autor"
              >
                <BiTrash />
              </DefaultButton>
            </div>
          ),
        }))
      : [];
  }, [authors, editAuthor, removeAuthor, activateOrInactivateCourse]);

  useEffect(() => {
    getAuthors();
  }, [authorName]);


  return (
    <AuthorsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Autores</span>]}
      />
      <DefaultPageTitle>Autores</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createAuthor}>Criar Autor</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={getAuthors}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={authorName}
            onChange={(e) => setAuthorName(e.target.value)}
            style={{ margin: 0, width: "350px" }}
          />
          <DefaultButton className="small" type="submit" style={{ margin: 0 }}>
            <AiOutlineSearch size={26} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>
      {createAndEditAuthor ? <CreateAndEditContent estado={ setCreateAndEditAuthor} updateAuthors={  getAuthors }/> : null}

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          /* {
            headerLabel: <span>Descrição</span>,
            propName: "description",
            attributes: { style: { width: "35%" } },
          }, */
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
            order: (localAuthors: any[], currentOrder: string) => {
              setAuthors([
                ...authors.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes("active") &&
                    currentOrder.includes("asc");
                  if (isDescendant) {
                    return a.is_active ? 1 : -1;
                  } else {
                    return a.is_active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={authorsToBeShown}
        emptyListMessage={"Não foram encontrados autores cadastrados!"}
      />
    </AuthorsContainer>
  );
};

export default Authors;
