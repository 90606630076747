import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import CutImage from "../../components/CutImage";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";

import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { hideModal, showModal } from "../../helpers/modal";
import SelectCourse from "./components/SelectCourse";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";

import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
} from "../../services/courses";
import { 
  listCompany as listCompanyService,
  listDepartment as listDepartmentService,
  listConstructions as listConstructionsService
} from '../../services/segmentation';

import checkEmptyString from "../../helpers/check-empty-string";
import Select from "react-select";
import Category from "../../models/category";
import Author from "../../models/author";
import { getAllAuthors } from "../../services/authors";
import MediaFromResponse from "../../models/from-api-response/media";
import SelectSambaVideosContent from "../../components/SelectSambaVideosContent";
import Course from "../../models/course";

import {
  ThumbnailUploadContainer,
  ThumbnailAndBannerContainer,
  BannerUploadContainer,
  CourseImageContainer,
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditTrailContainer,
  ContentUploadOrSelectContainer,
  SelectCategoryAndTrail,
  Legend,
  ContentWithFlexRow,
  ButtonOnCenter
} from "./style";

import { getAllCategories } from "../../services/categories";
import getErrorMessage from "../../helpers/get-error-message";
import TrailCategory from "../../models/from-api-response/trail-category";
import { getTrails as getTrailCategoriesService } from "../../services/trail-category";
import DefaultSwitch from "../../components/DefaultSwitch";
import Swal from "sweetalert2";
import TextareaReactQuill from "../../components/TextareaReactQuill/TextareaReactQuill";
import { Segmentation } from "../../components/Segmentation";
import { SelectProps } from "../../models/select";
import { useSegmentation } from "../../context/SegmentationContext";
import { LoadingOnPage } from "../../components/LoadingOnPage";
import { getCertificates } from "../../services/certificate";

interface CreateAndEditTrailProps {
  trailId: string;
}

const CreateAndEditTrail: React.FC = () => {
  const history = useHistory();
  const { trailId } = useParams<CreateAndEditTrailProps>();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [selectedCourses, setSelectedCourses] = useState([] as Course[]);

  const [thumbnailFileToUpload, setThumbnailFileToUpload] = useState<File>();
  const [thumbnailFileToRemove, setThumbnailFileToRemove] = useState(false);
  const [thumbnail, setThumbnail] = useState("");

  const [bannerFileToUpload, setBannerFileToUpload] = useState<File>();
  const [bannerFileToRemove, setBannerFileToRemove] = useState(false);
  const [banner, setBanner] = useState("");

  const [categories, setCategories] = useState([] as Category[]);
  const [selectedCategory, setSelectedCategory] = useState(
    {} as { label: string; value: string } | null | undefined
  );

  const [trailCategories, setTrailCategories] = useState([] as TrailCategory[]);
  const [selectedTrailCategories, setSelectedTrailCategories] = useState(
    [] as { label: string; value: string }[] | undefined
  );

  const [authors, setAuthors] = useState([] as Author[]);
  const [selectedAuthor, setSelectedAuthor] = useState(
    {} as { label: string; value: string }
  );

  const [introImage, setIntroImage] = useState("");
  const [introImageFileUpload, setIntroImageFileUpload] = useState<File>();
  const [switchCourseIntro, setSwitchCourseIntro] = useState("IMAGEM");
  const [switchIsHightLighted, setSwitchIsHightLighted] = useState("not");

  const [listCompany, setListCompany] = useState<SelectProps[]>();
  const [listDepartment, setListDepartment] = useState<SelectProps[]>();
  const [listConstructions, setListConstructions] = useState<SelectProps[]>();
  const [listOfCertificates, setListOfCertificates] = useState<SelectProps[]>();
  const [certificate, setCertificate] = useState<SelectProps>();
  const [isLoading, setIsLoading] = useState(false);

  const { segmentationProps } = useSegmentation();

  const validate = () => {
    if (checkEmptyString(name)) {
      throw new Error("Informe um nome valido para a curso.");
    }

    const descriptionWithoutTags = description;
    if (checkEmptyString(descriptionWithoutTags.replace(/<[^>]*>?/gm, ''))) {
      throw new Error("Informe uma descrição válida para o curso.");
    }

    if (!selectedCourses || !selectedCourses.length) {
      throw new Error("Selecione um ou mais módulos antes.");
    }

    if (switchCourseIntro === "VIDEO" && !reference) {
      throw new Error("Informe um vídeo introdutório.");
    }

    if (!Object.keys(selectedAuthor).length) {
      throw new Error("Selecione um autor antes.");
    }

    if (reference && introImage) {
      throw new Error("Você selecionou uma imagem e um vídeo. Favor selecionar somente um tipo de introdução");
    }

    if(!certificate?.value) {
      throw new Error("Informe um template certificado.");
    }

    if (
      (!selectedTrailCategories || !selectedTrailCategories.length) &&
      (!selectedCategory || !selectedCategory.value)
    ) {
      throw new Error("Selecione uma Categoria ou ao menos uma Trilha.");
    }
  };

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;
          setReference(referenceUrl);
        }
      }
    }

    hideModal();
  };

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      await createCourseService({
        name,
        description: description.trim().length ? description : undefined,
        courses: selectedCourses.map((content, index) => ({
          course_id: content.course_id,
          position: index + 1,
        })),
        thumbnail: thumbnailFileToUpload,
        authors: [{ author_id: selectedAuthor.value }],
        categories:
          selectedCategory && selectedCategory.value
            ? [{ category_id: selectedCategory.value }]
            : undefined,
        tag_categories:
          selectedTrailCategories && selectedTrailCategories.length
            ? selectedTrailCategories.map((cat) => ({
                tag_category_id: cat.value,
              }))
            : undefined,
        video_reference: reference || undefined,
        info: {
          is_highlighted: switchIsHightLighted === "yes",
          intro_image: introImageFileUpload,
          banner: bannerFileToUpload,
          company_ids: segmentationProps['company'].tableData.map(item => item.value),
          departament_ids: segmentationProps['department'].tableData.map(item => item.value),
          construction_ids: segmentationProps['constructions'].tableData.map(item => item.value),
          certificate_template_id: certificate?.value
        },
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Curso criado com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      await updateCourseService(trailId, {
        name: name,
        description: description,
        courses: selectedCourses.map((content, index) => ({
          course_id: content.course_id,
          position: index + 1,
        })),
        thumbnail: thumbnailFileToRemove ? undefined : (thumbnailFileToUpload || thumbnail),
        authors: [{ author_id: selectedAuthor.value }],
        categories:
          selectedCategory && selectedCategory.value
            ? [{ category_id: selectedCategory!.value }]
            : [],
        tag_categories:
          selectedTrailCategories && selectedTrailCategories.length
            ? selectedTrailCategories.map((cat) => ({
                tag_category_id: cat.value,
              }))
            : [],
        video_reference: reference || null,
        info: {
          is_highlighted: switchIsHightLighted === "yes",
          intro_image: introImageFileUpload || introImage,
          // @ts-ignore
          banner: bannerFileToRemove ? undefined : (bannerFileToUpload || banner),
          company_ids: segmentationProps['company'].tableData.map(item => item.value),
          departament_ids: segmentationProps['department'].tableData.map(item => item.value),
          construction_ids: segmentationProps['constructions'].tableData.map(item => item.value),
          certificate_template_id: certificate?.value
        },
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Curso editado com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const selectCourses = () => {
    showModal(
      "Selecionar",
      <SelectCourse
        onApplySelection={applyCoursesSelection}
        selectedCourses={[...selectedCourses]}
      />
    );
  };

  const selectContent = () => {
    showModal(
      "Selecionar Conteúdo",
      <SelectSambaVideosContent onSelectContent={onSelectContent} />
    );
  };

  const selectIntroImage = () => {
    showModal(
      "Selecionar Imagem Introdutória",
      <CutImage
        aspect={16 / 9}
        onCutImage={(file) => onCutImage(file, "introImage")}
      />
    );
  };

  const selectThumbnail = () => {
    showModal(
      "Selecionar Imagem de Capa",
      <CutImage
        aspect={1 / 1}
        onCutImage={(file) => onCutImage(file, "thumbnail")}
      />
    );
  };

  const selectBanner = () => {
    showModal(
      "Selecionar Imagem de Banner",
      <CutImage
        aspect={16 / 9}
        onCutImage={(file) => onCutImage(file, "banner")}
      />
    );
  };

  const onCutImage = (file: File, imgSrc: string) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      if (imgSrc === "thumbnail") {
        reader.onload = () => setThumbnail(`${reader.result}`);
        setThumbnailFileToUpload(file);
        setThumbnailFileToRemove(false);
      } else if (imgSrc === "banner") {
        reader.onload = () => setBanner(`${reader.result}`);
        setBannerFileToUpload(file);
        setBannerFileToRemove(false);
      } else {
        reader.onload = () => setIntroImage(`${reader.result}`);
        setIntroImageFileUpload(file);
      }

      hideModal();
    }
  };

  const removeThumbnail = () => {
    setThumbnailFileToUpload(undefined);
    setThumbnail("");
    setThumbnailFileToRemove(true);
  };

  const removeBanner = () => {
    setBannerFileToUpload(undefined);
    setBanner("");
    setBannerFileToRemove(true);
  };

  const applyCoursesSelection = (localSelectedCourses: Course[]) => {
    hideModal();

    if (localSelectedCourses) {
      setSelectedCourses(localSelectedCourses);
    }
  };

  const reorderSelectedCourses = (lesson: Course, up: boolean) => {
    if (selectedCourses) {
      const localSelectedCourses = [...selectedCourses];
      const indexOfLesson = localSelectedCourses.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedCourses.length) {
        localSelectedCourses.splice(indexOfLesson, 1);
        if (up) {
          localSelectedCourses.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedCourses.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedCourses(localSelectedCourses);
    }
  };

  const getCourse = useCallback(async () => {
    setIsLoading(true);
    const foundAuthors = await getAllAuthors();
    const foundCategories = await getAllCategories({ is_active: true });
    const foundTrailCategories = await getTrailCategoriesService({
      is_active: true,
    });
    if (foundAuthors) {
      setAuthors(foundAuthors);
    }
    if (foundCategories) {
      setCategories(foundCategories);
    }
    if (foundTrailCategories) {
      setTrailCategories(foundTrailCategories);
    }
    if (trailId) {
      try {
        const course = await getCourseService(trailId);
        if (course && Object.keys(course).length) {
          setName(course.trail.name);
          setDescription(course.trail.description);
          // Sorry for the any's
          setSelectedCourses(course.trail.courses.sort((courseA: any, courseB: any) => courseA.position > courseB.position ? 1 : -1));
          setThumbnail(course.trail.thumbnail);
          setReference(course.trail.video_reference);
          setIntroImage(course.trail.info?.intro_image);
          setBanner(course.trail.info?.banner);
          setSwitchIsHightLighted(
            course.trail.info?.is_highlighted ? "yes" : "no"
          );
          setSelectedCategory(
            course.trail && course.trail.category
              ? {
                  label: course.trail.category.title,
                  value: course.trail.category.id,
                }
              : null
          );
          setSelectedTrailCategories(
            course.trail && course.trail.tag_categories
              ? course.trail.tag_categories.map(
                  (tag: { title: string; id: string }) => ({
                    label: tag.title,
                    value: tag.id,
                  })
                )
              : null
          );
          setSelectedAuthor({
            label: course.trail.authors[0].name,
            value: course.trail.authors[0].id,
          });
          setSwitchCourseIntro(course.trail.video_reference ? "VIDEO" : "IMAGEM");

          if(course.trail.info && course.trail.info.certificate_template) {
            setCertificate({ 
              label: course.trail.info.certificate_template.title,
              value: course.trail.info.certificate_template.certificate_template_id
            })
          }
          segmentationProps['company'].setTableData(
            course.trail.info.companies && course.trail.info.companies.length > 0 
            ? course.trail.info.companies.map(
                (company: { name: string, company_id: string }) => ({
                  label: company.name,
                  value: company.company_id
                })
              )
            : []
          );

          segmentationProps['department'].setTableData(
            course.trail.info.departaments && course.trail.info.departaments.length > 0 
            ? course.trail.info.departaments.map(
                (department: { name: string, departament_id: string }) =>({
                  label: department.name,
                  value: department.departament_id
                })
              )
            : []
          );

          segmentationProps['constructions'].setTableData(
            course.trail.info.constructions && course.trail.info.constructions.length > 0 
            ? course.trail.info.constructions.map(
                (construction: { name: string, construction_id: string }) =>({
                  label: construction.name,
                  value: construction.construction_id
                })
              )
            : []
          );
        }

        setIsLoading(false);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          title: "Erro",
          text: errorMessage,
          icon: "error",
        });

        setIsLoading(false);
      }
    }
  }, [trailId]);
  const authorsToBeSelected = useMemo(() => {
    if (authors && authors.length) {
      return authors.map((author) => ({
        label: author.name,
        value: `${author.author_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [authors]);

  const categoriesToBeSelected = useMemo(() => {
    if (categories && categories.length) {
      return categories.map((category) => ({
        label: category.title,
        value: `${category.category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [categories]);

  const trailCategoriesToBeSelected = useMemo(() => {
    if (trailCategories && trailCategories.length) {
      return trailCategories.map((trailCategory) => ({
        label: trailCategory.title,
        value: `${trailCategory.tag_category_id}`,
      }));
    }

    return [] as { label: string; value: string }[];
  }, [trailCategories]);

  const goToCourses = () => {
    history.push("/courses/courses");
  };

  const getSegmentationLists = useCallback(async () => {  
    setIsLoading(true);
    try {
      const allCompanies = await listCompanyService();
      const allDepartments = await listDepartmentService();
      const allConstructions = await listConstructionsService();
      
      setListCompany(allCompanies);
      setListDepartment(allDepartments);
      setListConstructions(allConstructions);
      if(!trailId) setIsLoading(false);
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      setIsLoading(false);

      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  } ,[]);

  const getCertificateList = useCallback(async () => {
    setIsLoading(true);

    try {
      const listOfCertificatesReturned = await getCertificates({ active: true });

      if(listOfCertificatesReturned && listOfCertificatesReturned.length > 0) {
        const listOfSignatureForSelect = listOfCertificatesReturned.map(
          signature => {
            return {
              label: signature.title,
              value: signature.certificate_template_id
            }
          });
  
        setListOfCertificates(listOfSignatureForSelect); 
       }

      if(!trailId) setIsLoading(false);
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      setIsLoading(false);

      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  },[]);

  const clear = () => {
    setSelectedCategory(null);

    segmentationProps['company'].setTableData([]);
    segmentationProps['department'].setTableData([]);
    segmentationProps['constructions'].setTableData([]);
  };

  useEffect(() => {
    clear();
  }, []);

  useEffect(() => {
    getCourse();
    getSegmentationLists();
    getCertificateList();
  }, [getCourse, getSegmentationLists, getCertificateList]);

  const isEditting = useMemo(() => {
    if (trailId) {
      return true;
    }

    return false;
  }, [trailId]);

  return (
    <CreateAndEditTrailContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/courses/courses">Cursos</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Curso</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Curso
      </DefaultPageTitle>

      {!isLoading ? (
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description" className="required">Descrição</label>
          <TextareaReactQuill state={description} setState={setDescription} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="tags">
            Autor
          </label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
            options={authorsToBeSelected}
            value={selectedAuthor}
            onChange={(options) => {
              options &&
                setSelectedAuthor({
                  label: options.label,
                  value: options.value,
                });
            }}
            id="authors"
            placeholder="Selecione um autor para este curso."
          />
        </DefaultCreationFormGroup>

        <SelectCategoryAndTrail>
          <Legend>
            <label className="required">
              Selecione uma categoria e/ou trilha
            </label>
          </Legend>
          <DefaultCreationFormGroup>
            <label htmlFor="tags">Categoria</label>
            <Select
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: "100%",
                }),
              }}
              options={categoriesToBeSelected}
              value={selectedCategory}
              onChange={(option) => {
                option ? setSelectedCategory({ ...option }) : clear();
              }}
              id="categories"
              placeholder="Selecione uma categoria para este curso."
              isClearable
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label htmlFor="trails">Trilha</label>

            <Select
              inputId="trails"
              styles={{
                container: (provided) => ({
                  ...provided,
                  flexGrow: 1,
                  width: "100%",
                }),
              }}
              options={trailCategoriesToBeSelected}
              value={selectedTrailCategories}
              onChange={(options) => {
                options &&
                  options.length !== undefined &&
                  setSelectedTrailCategories(
                    options.map((opt) => ({ ...opt }))
                  );
              }}
              id="categories"
              placeholder="Selecione uma trilha para este curso."
              isMulti
            />
          </DefaultCreationFormGroup>
        </SelectCategoryAndTrail>

        <SelectCategoryAndTrail>
          <Legend>
            <label className="required">
              Capa do curso
            </label>
          </Legend>

          <DefaultCreationFormGroup unsetLabel>
            <ContentWithFlexRow>
              <label className="required" htmlFor="reference">
                Destaque
              </label>

              <DefaultSwitch
                switchState={switchIsHightLighted}
                setSwitchState={setSwitchIsHightLighted}
                states={[
                  { label: "Ativado", value: "yes" },
                  { label: "Desativado", value: "not" },
                ]}
              />
            </ContentWithFlexRow>
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <ThumbnailAndBannerContainer>
              <ThumbnailUploadContainer>
                {/* <label htmlFor="reference">Capa</label> */}
                <header className="header">
                  <h5>Capa</h5>
                  <span>(715x715)</span>
                </header>
                <DefaultButton type="button" onClick={selectThumbnail}>
                  Selecionar Imagem
                </DefaultButton>

                {thumbnail && <CourseImageContainer src={thumbnail} />}

                {thumbnail && (
                  <DefaultButton type="button" onClick={removeThumbnail}>
                    Remover Imagem
                  </DefaultButton>
                )}
              </ThumbnailUploadContainer>

              {switchIsHightLighted === "yes" && (
                <BannerUploadContainer>
                  {/* <label htmlFor="reference">Banner</label> */}
                  <header className="header">
                    <h5>Banner</h5>
                    <span>(1280x720)</span>
                  </header>
                  <DefaultButton type="button" onClick={selectBanner}>
                    Selecionar Imagem
                  </DefaultButton>

                  {banner && <CourseImageContainer src={banner} />}

                  {banner && (
                    <DefaultButton type="button" onClick={removeBanner}>
                      Remover Imagem
                    </DefaultButton>
                  )}
                </BannerUploadContainer>
              )}
            </ThumbnailAndBannerContainer>
          </DefaultCreationFormGroup>
        </SelectCategoryAndTrail>

        <DefaultCreationFormGroup unsetLabel>
          <ContentWithFlexRow>
            <label htmlFor="reference">
              {switchCourseIntro === "VIDEO"
                ? "Vídeo Introdutório"
                : "Imagem Introdutória"}
            </label>

            <DefaultSwitch
              switchState={switchCourseIntro}
              setSwitchState={setSwitchCourseIntro}
              states={[
                { label: "Vídeo", value: "VIDEO" },
                { label: "Imagem", value: "IMAGEM" },
              ]}
            />
          </ContentWithFlexRow>

          <ContentUploadOrSelectContainer>
            {switchCourseIntro === "VIDEO" ? (
              <>
                <DefaultButton 
                  type="button" 
                  disabled={introImage ? true : false} 
                  onClick={selectContent}>
                  Selecionar Vídeo
                </DefaultButton>
                {introImage && (
                  <p className="warning-selected-intro">Já foi selecionado uma imagem,
                    <span 
                      onClick={() => {
                        setIntroImage("");
                        setIntroImageFileUpload(undefined);
                      }}>Deseja remover a imagem?</span>
                  </p>
                )}
                {reference ? (
                  <iframe
                    title="referenced-video"
                    allowFullScreen
                    src={reference}
                    frameBorder={0}
                  ></iframe>
                ) : (
                  <></>
                )}
              </>
            ) : (
              <>
               <header className="header">
                  <span>(1280x720)</span>
                </header>
                <DefaultButton 
                  type="button" 
                  disabled={reference ? true : false} 
                  onClick={selectIntroImage}
                >
                  Selecionar Imagem
                </DefaultButton>
                {reference && (
                  <p className="warning-selected-intro">Já foi selecionado um vídeo,
                    <span onClick={() => setReference("")}>Deseja remover o vídeo?</span>
                  </p>
                )}
                {introImage ? (
                  <img
                    className="intro-image"
                    src={introImage}
                    alt="introImage"
                  />
                ) : (
                  <></>
                )}
              </>
            )}
          </ContentUploadOrSelectContainer>

        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Módulos
          </label>

          <LessonSelectionContainer>
            {selectedCourses && selectedCourses.length ? (
              <SortLessonsContainer>
                {selectedCourses.map((lesson, index) => (
                  <div key={lesson.course_id} className="selected-lessons">
                    <div className="buttons">
                      {index > 0 ? (
                        <DefaultButton
                          type="button"
                          title="Subir Aula"
                          className="small white up"
                          onClick={() => reorderSelectedCourses(lesson, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedCourses.length - 1 ? (
                        <DefaultButton
                          type="button"
                          title="Descer Aula"
                          className="small white down"
                          onClick={() => reorderSelectedCourses(lesson, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="lesson-title">
                      {index + 1} - {lesson.title}
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <ButtonOnCenter>
              <DefaultButton type="button" onClick={selectCourses}>
                Selecionar Módulos
              </DefaultButton>
            </ButtonOnCenter>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label 
            htmlFor="certifcaite-template"
            className="required"
          >
            Template certificado
          </label>
          <Select
            options={listOfCertificates}
            value={certificate}
            onChange={(option) => {
              option 
                ? setCertificate(option)
                : setCertificate({ label: '', value: '' });
            }}
            id="signatures"
            placeholder="Selecione um template de certificado."
            noOptionsMessage={() => 'Não foi encontrado nenhum template de certificado!'}
            isClearable
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: "100%",
              }),
            }}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          {(listCompany && listCompany.length > 0) && 
            (listConstructions && listConstructions.length > 0) &&
            (listDepartment && listDepartment.length > 0) && (
            <>
              <label htmlFor="segmentation">Segmentação</label>
              <Segmentation 
                companyList={listCompany}
                departmentList={listDepartment}
                constructionsList={listConstructions} 
              />
            </>
          )}
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateCourse(e) : createCourse(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
      ) : (
        <LoadingOnPage />
      )}
    </CreateAndEditTrailContainer>
  );
};

export default CreateAndEditTrail;
