import httpClient from "../http-client";
import { uploadFile } from "./files";
import { ICreateCourse, IUpdateCourse } from "../models/for-create/course";
import getErrorMessage from "../helpers/get-error-message";

const getCourse = async (courseId: string) => {
  const course = (await httpClient.get<any>(`trails/${courseId}`)).data;

  return course;
};

const getAllCourses = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }
  const courses = (
    await httpClient.get<any>("/trails?is_admin=true", { params: filters })
  ).data;

  return courses;
};

const deleteCourse = async (courseId: string) => {
  await httpClient.delete<any>(`trails/${courseId}`);
};

const uploadImageFromDataURL = async (
  img?: File | null | string
): Promise<string | null | undefined> => {
  if (img && (typeof(img) !== "string")) {
    try {
      const formData = new FormData();
      formData.append("file", img);

      return (await uploadFile(formData)).reference;
    } catch (e) {
      throw new Error("Erro ao fazer upload da imagem. " + getErrorMessage(e));
    }
  }

  return img;
};

const createCourse = async (course: ICreateCourse) => {
  const [thumbnail, banner, intro_image] = await Promise.all(
    [course.thumbnail, course.info?.banner, course.info?.intro_image].map(
      async (img) => uploadImageFromDataURL(img)
    )
  );

  const createCourseResponse = (
    await httpClient.post<any>(`trails`, {
      ...course,
      thumbnail,
      info: {
        ...course.info,
        banner,
        intro_image,
      },
    })
  ).data;

  return createCourseResponse;
};

const updateCourse = async (courseId: string, course: IUpdateCourse) => {
  const [thumbnail, banner, intro_image] = await Promise.all(
    [course.thumbnail, course.info?.banner, course.info?.intro_image].map(
      async (img) => uploadImageFromDataURL(img)
    )
  );
  await httpClient.put<any>(`trails/${courseId}`, {
    ...course,
    thumbnail,
    info: {
      ...course.info,
      banner,
      intro_image,
    },
  });
};

const changeCourseThumbnail = async (courseId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`trails/${courseId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const changeTrailVideoReference = async (
  trailId: string,
  reference?: string | null
) => {
  await httpClient.patch(`/trails/${trailId}/video-reference`, {
    video_reference: reference,
  });
};

const activateOrInactivateCourse = async (
  courseId: string,
  activate: boolean
) => {
  await httpClient.put<any>(`trails/${courseId}/active`, {
    is_active: activate,
  });
};

const getCoursesForSelect = async () => {
  const courses = (await httpClient.get<any>("trails/")).data;

  if (courses && courses.length) {
    return courses.map((course: any) => ({
      value: course.trail_id,
      label: course.name,
    }));
  }

  return [] as { value: string; label: string }[];
};

export {
  getAllCourses,
  deleteCourse,
  updateCourse,
  createCourse,
  getCourse,
  changeCourseThumbnail,
  activateOrInactivateCourse,
  getCoursesForSelect,
  changeTrailVideoReference,
};
