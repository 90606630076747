import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import moment from "moment";

import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import { DefaultCreateButtonAndSearchFormContainer } from "../../components/DefaultCreateButtonAndSearchFormContainer";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";

import { listAllEvents, deleteEvent } from "../../services/googleEvents";
import GoogleEvent from "../../models/googleEvents";
import getErrorMessage from "../../helpers/get-error-message";

import { AuthorsContainer } from "./style";

const Events: React.FC = () => {
  const [eventName, setEventName] = useState("");
  const [allEvents, setAllEvents] = useState([] as GoogleEvent[]);
  const [isSearching, setIsSearching] = useState(false);
  const history = useHistory();

  const redirectToCreateEvent = () => {
    history.push("/events/create-event");
  };

  const getAllEvents = useCallback(async () => {
    const allEventsReturn = await listAllEvents();
    if(allEventsReturn && allEventsReturn.length){
      setAllEvents(allEventsReturn);
    }
  }, []);

  const editEvent = useCallback(
    (eventId: any) => {
      history.push(`/events/edit-event/${eventId}`);
    },
    [history]
  );

  const removeEvent = useCallback(async (eventId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este evento?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteEvent(eventId);
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Evento excluído com sucesso!",
          });

          await getAllEvents();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir evento!",
            text: errorMessage,
          });
        }
      }
    });
  }, []);

  const eventsToShown = useMemo(() => {
    return allEvents && allEvents.length ? 
    allEvents.map((event) => ({
      id: event.event_id,
      name: event.name,
      start_date: moment(event.start_date).format('DD/MM/YYYY HH:mm'),
      stop_date: moment(event.stop_date).format('DD/MM/YYYY HH:mm'),
      meet_url: event.meet_url,
      description: event.description,
      active: event.active ? <AiOutlineCheck /> : <AiOutlineClose />,
      actions: (
        <>
          <DefaultButton
            className="small info"
            onClick={() => editEvent(event.event_id)}
          >
            <BiEdit />
          </DefaultButton>{" "}
          <DefaultButton
            onClick={() => removeEvent(event.event_id)}
            className="small danger"
            title="Excluir Evento"
          >
            <BiTrash />
          </DefaultButton>
        </>
      )
    })) : [];
  }, [allEvents, editEvent, removeEvent]);

  const searchEvents = useCallback(async (event?: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      setIsSearching(true);

      const allEventsReturn = await listAllEvents({ name: eventName });
      setAllEvents(allEventsReturn);

      setIsSearching(false)
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao excluir evento!",
        text: errorMessage,
      });
    }

    setIsSearching(false)
  }, [eventName])

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  return (
    <AuthorsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Eventos</span>]}
      />

      <DefaultPageTitle>Eventos</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={() => redirectToCreateEvent()}>Criar Evento</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={searchEvents}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      {!isSearching && (
        <DefaultTable
          headersConfig={[
            {
              headerLabel: <span>Nome</span>,
              propName: "name",
            },
            {
              headerLabel: <span>Horário Início</span>,
              propName: "start_date",
            },
            {
              headerLabel: <span>Horário Fim</span>,
              propName: "stop_date",
            },
            {
              headerLabel: <span>Link</span>,
              propName: "meet_url",
            },
            {
              headerLabel: <span>Descrição</span>,
              propName: "description",
            },
            {
              headerLabel: <span>Ativo</span>,
              propName: "active",
            },
            {
              headerLabel: <span>Ações</span>,
              propName: "actions",
            },
          ]}
          items={eventsToShown}
          emptyListMessage={"Não foram encontrados eventos cadastrados!"}
        />
      )}
    </AuthorsContainer>
  );
};

export default Events;
