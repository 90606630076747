import styled from "styled-components";

export const FormContainer = styled.div`
  width: 50%;

  input {
    border-radius: 5px;
    border: solid 1px var(--default-dark-gray);
    outline: none;
    font-size: var(--font-regular);
    height: 44px;
    padding: 0 10px;
    color: var(--dark-gray);
    background-color: var(--light-gray);
    margin-top: 8px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  /* margin-bottom: 40px; */

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
  }

  svg {
    width: 20px;
    height: 20px;
    margin-left: 10px;
  }
`; 

export const LinkContainer = styled.div`
  margin-top: 40px !important; 
`;

export const LinkButton = styled.a`
  border: 1px solid var(--danger-color);
  border-radius: 4px;
  font-size: 16px;
  text-align: center;
  text-transform: uppercase;
  padding: 12px;
  color: var(--danger-color);
  background-color: transparent;
  cursor: pointer;
  text-decoration: none;
`;

export const DownloadButton = styled.a`
  border-radius: 4px;
  font-size: var(--font-medium);
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  padding: 12px;
  color: white;
  background-color: var(--primary-color);
  text-decoration: none;

  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--secondary-color-hover);
  }
`;

export const StatusText = styled.p`
  min-width: 145px;
  margin-bottom: 12px;
  font-size: var(--font-small);
  color: var(--primary-color);
  font-weight: 700;
`

interface StatusInfoProps {
  active: boolean;
}

export const StatusInfo = styled.button<StatusInfoProps>`
  background: ${(props) => props.active ? '#62B23F' : '#A31700'};
  border: 0px;
  border-radius: 10px;
  cursor: default;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  color: #F3F2F9;
  padding: 14px 0px;
  min-width: 130px;
`;