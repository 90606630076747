import React, { useCallback, useEffect, useMemo, useState } from "react";
import {useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { CreateAndEditAuthorContainer } from "./style";
import checkEmptyString from "../../helpers/check-empty-string";
import { createAuthor, getAuthor, updateAuthor } from "../../services/authors";
import getErrorMessage from "../../helpers/get-error-message";

interface CreateAndEditContentProps {
  authorId: string;
}

interface ChildComponentProps {
  estado:(a:boolean) => void;
  updateAuthors: any;
}

const CreateAndEditContent: React.FC<ChildComponentProps>= ({estado, updateAuthors}:ChildComponentProps) => {
  const { authorId } = useParams<CreateAndEditContentProps>();

  const history = useHistory();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");


  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para o autor.");
      }

      await createAuthor({
        name,
        description: description.trim().length ? description : undefined,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Autor criado com sucesso!",
        icon: "success",
      });

      goToAuthors();
      estado(false);
      await updateAuthors();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(name)) {
        throw new Error("Informe um nome valido para o autor.");
      }

      await updateAuthor(authorId, {
        name,
        description: description || undefined,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Autor editado com sucesso!",
        icon: "success",
      });
      

      goToAuthors();
 
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const goToAuthors = () => {
    history.push("/authors");
  };

  const getContent = useCallback(async () => {
    if (authorId) {
      const author = await getAuthor(authorId);
      if (author && Object.keys(author).length) {
        setName(author.name);
        setDescription(author.description);
      }
    }
  }, [authorId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (authorId) {
      return true;
    }

    return false;
  }, [authorId]);

  return (
    <CreateAndEditAuthorContainer id="createAndEditAutor">
{/* {Código comentado pois pode ser usado essa campo no futuro} */}
    {/*   <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/authors">Autor</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Autor</span>,
        ]}
      /> */}

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Autor
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>
{/* 
{Código comentado pois pode ser usado essa campo no futuro} */}
  {/*       <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <DefaultTextArea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            id="description"
          />
        </DefaultCreationFormGroup>
 */}
        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={() => estado(false)}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditAuthorContainer>
  );
};

export default CreateAndEditContent;
