import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultFilterForm from "../../components/DefaultFilterForm";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import ReactSelectComponent from "../../components/ReactSelectComponent";
import { generateUserAccessReport } from "../../services/reports";
import { getUsersForSelect } from "../../services/users";
import { FinishedCoursesContainer } from "./style";

const UserAccess: React.FC = () => {

  const [users, setUsers] = useState([] as { value: string; label: string }[]);
  const [selectedUsers, setSelectedUsers] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  const [startAccessStart, setStartAccessStart] = useState("");
  const [startAccessFinish, setStartAccessFinish] = useState("");

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      user_id_ft: selectedUsers,
      access_at_gte: startAccessStart,
      access_at_lte: startAccessFinish,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateUserAccessReport(reportType, queryStrings);
  };

  useEffect(() => { 
    setLoading(true);
    (async () => {
      const localUsers = await getUsersForSelect();
      if (localUsers && localUsers.length) {
        setUsers(localUsers);
      }
    })();
    setLoading(false);
  }, []);

  return (
    <FinishedCoursesContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <span>Relatórios</span>,
          <span>Acesso de Usuário</span>,
        ]}
      />

      <DefaultPageTitle>Cursos</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="users">Usuários</label>
          <ReactSelectComponent 
            placeHolder="Selecione um usuário"
            content={users}
            setState={setSelectedUsers}
            isLoading={loading || !users.length}
            notFoundMessage='Não foi encontrado nenhum usuário!'
          />
        </span>

        <span>
          <label htmlFor="startAt">Inicio em</label>
          <input
            type="date"
            id="startAtGte"
            max={startAccessFinish}
            onChange={(e) => setStartAccessStart(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={startAccessStart}
            onChange={(e) => setStartAccessFinish(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport("pdf")}
            className="success"
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{" "}
          <DefaultButton
            onClick={() => generateReport("csv")}
            className="success"
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </FinishedCoursesContainer>
  );
};

export default UserAccess;
