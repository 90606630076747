const checkDateFormat = (str: string) => {
  const birthDate = new Date(str)
  const actualDate = new Date()
  if (birthDate > actualDate) {
    return true;
  } else {
    return false;
  }
};

export default checkDateFormat;
