import { useMemo, useState } from 'react';
import Select from 'react-select';
import escapeRegExp from "lodash/escapeRegExp";

const MAX_DISPLAYED_OPTIONS = 500;

interface ReactSelectComponentProps {
  placeHolder: string;
  content: { value: string; label: string }[];
  setState: React.Dispatch<React.SetStateAction<any>>;
  notFoundMessage: string;
  isLoading: boolean
}

const ReactSelectComponent: React.FC<ReactSelectComponentProps> = ({
  placeHolder,
  content,
  setState,
  notFoundMessage,
  isLoading
}) => {
  const [inputValue, setInputValue] = useState("");

  const filteredOptions = useMemo(() => {
    if (!inputValue) {
      return content;
    }

    const matchByStart = [];
    const matchByInclusion = [];
    const regByInclusion = new RegExp(escapeRegExp(inputValue), "i");
    const regByStart = new RegExp(`^${escapeRegExp(inputValue)}`, "i");

    for (const option of content) {
      if (regByInclusion.test(option.label)) {
        if (regByStart.test(option.label)) {
          matchByStart.push(option);
        } else {
          matchByInclusion.push(option);
        }
      }
    }

    return [...matchByStart, ...matchByInclusion];
  }, [inputValue, content]);

  const slicedOptions = useMemo(
    () => filteredOptions.slice(0, MAX_DISPLAYED_OPTIONS),
    [filteredOptions]
  );

  return (
    <Select
      placeholder={placeHolder}
      isMulti
      options={slicedOptions}
      
      isLoading={isLoading}
      noOptionsMessage={() => notFoundMessage}
      loadingMessage={() => 'Carregando...'}
      onInputChange={(value) => setInputValue(value)}
      onChange={(options) =>
        setState(options.map((opt) => opt.value))
      }
      filterOption={() => true}
    />
  )
}

export default ReactSelectComponent;