import styled, { css } from "styled-components";

interface IPropsSwitch {
  switchState: string;
}

const DefaultSwitchContainer = styled.div`
  position: relative;
  min-width: 120px;
  height: 34px;
  cursor: pointer;
  margin-bottom: 15px;
`;

const Switch = styled.div`
  position: absolute;
  left: 0%;
  right: 0%;
  top: 0%;
  bottom: 0%;

  border: 1px solid #5e72e4;
  border-radius: 12px;
`;

const LabelSwitch = styled.label`
  position: absolute;
  min-width: 0px !important;
  height: 18px;
  right: 44.23%;
  top: calc(50% - 18px / 2);

  &.left {
    left: 2px;
  }

  &.right {
    left: 33px;
  }

  font-family: Open Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  /* identical to box height */

  text-align: center;
  transition: ease-in;
  color: #5e72e4;
`;

const CircleSwitch = styled.div`
  position: absolute;
  display: block;
  height: 28px;
  width: 28px;
  left: 31px;
  border-radius: 50%;
  background: #5e72e4;

  &.left {
    left: 61.62%;
    right: 5.77%;
    top: 7.5%;
    bottom: 12.5%;
  }

  &.right {
    left: 2.66%;
    right: 60.38%;
    top: 7.5%;
    bottom: 12.5%;
  }
`;

export { DefaultSwitchContainer, Switch, LabelSwitch, CircleSwitch };
