import { useState, useMemo } from 'react';
import Select, { OptionsType } from 'react-select';
import DefaultButton from '../DefaultButton';
import * as S from './style';

import { useSegmentation } from '../../context/SegmentationContext';
import { SegmentationLine } from './components/SegmentationLine';
import { SelectProps } from '../../models/select';
import { parseAlphabeticalOrder } from '../../helpers/parseInput';

interface SegmentationProps {
  companyList?: SelectProps[],
  departmentList?: SelectProps[],
  constructionsList?: SelectProps[],
}

export function Segmentation({ 
  companyList,
  departmentList,
  constructionsList,
}: SegmentationProps) {
  const { 
    segmentationTypes,
    segmentationProps,
    setSelectedSegmentationType,
    selectedSegmentationType,
  } = useSegmentation();
  
  const optionSelectAll = { label: "Selecionar tudo", value: "all" };
  const [selectedSegmentation, setSelectedSegmentation] = useState<
    OptionsType<SelectProps> | null
  >();

  const segmentationTypeSelected = useMemo(() => {
    switch (selectedSegmentationType.value) {
      case 'company': 
        return 'company';
      case 'department': 
        return 'department';
      case 'constructions': 
        return 'constructions';
      default:
       return 'company'
    }
  }, [selectedSegmentationType]);

  const selectList = useMemo(() => {
    return {
      company: {
        list: companyList && segmentationProps[segmentationTypeSelected].tableData.length > 0
          ? parseAlphabeticalOrder(companyList.filter(item => 
            !segmentationProps[segmentationTypeSelected].tableData.some(
              item2 => item.value === item2.value
            ))            
          )
          : parseAlphabeticalOrder(companyList)
      },
      department: {
        list: 
        departmentList && segmentationProps[segmentationTypeSelected].tableData.length > 0
          ? parseAlphabeticalOrder(departmentList.filter(item => 
            !segmentationProps[segmentationTypeSelected].tableData.some(
              item2 => item.value === item2.value
            ))            
          )
          : parseAlphabeticalOrder(departmentList),
      },
      constructions: {
        list: constructionsList && segmentationProps[segmentationTypeSelected].tableData.length > 0
        ? parseAlphabeticalOrder(constructionsList.filter(item => 
          !segmentationProps[segmentationTypeSelected].tableData.some(
            item2 => item.value === item2.value
          ))            
        )
        : parseAlphabeticalOrder(constructionsList)
      }
    }
  },[
    companyList, 
    departmentList, 
    constructionsList, 
    segmentationProps, 
    segmentationTypeSelected
  ])

  const selectAllOptionsShow = () => {
    if(segmentationTypeSelected) {
      const newSegmentation = selectList[segmentationTypeSelected].list.map((item: any) => {
        if(item.value !== 'all') return { ...item }
      });

      return setSelectedSegmentation(newSegmentation)
    }
  }

  const handleCreateSegmentationTable = () => {
    if(segmentationTypeSelected && selectedSegmentation && selectedSegmentation.length > 0) {
      const newSegmentationTable = selectedSegmentation.map(item => {
        return {
          label: item.label,
          value: item.value,
          checked: false
        }
      });

      setSelectedSegmentation([]);
      return segmentationProps[segmentationTypeSelected].setTableData(
        parseAlphabeticalOrder([
          ...segmentationProps[segmentationTypeSelected].tableData,
          ...newSegmentationTable
        ])
      ); 
    }
  }

  const handleSelectAllSegmentation = (checked: boolean) => {
    if(segmentationTypeSelected) {
      const newSegmentationTable = 
        segmentationProps[segmentationTypeSelected].tableData.map((item) => {
        return { ...item, checked: checked }
      });
  
      return segmentationProps[segmentationTypeSelected].setTableData(newSegmentationTable);
    }
  }

  const onDeleteCheckedSegmentation = () => {
    if(segmentationTypeSelected) {
      const newSegmentationTable = 
        segmentationProps[segmentationTypeSelected].tableData.filter(
          item => !item.checked
        );
  
      return segmentationProps[segmentationTypeSelected].setTableData(newSegmentationTable);
    }
  }

  const onDeleteSelectedSegmentation = (index: number) => {
    if(segmentationTypeSelected && 
      segmentationProps[segmentationTypeSelected].tableData.length > 0
    ){
      const newSegmentationTable = [...segmentationProps[segmentationTypeSelected].tableData];
      newSegmentationTable.splice(index, 1); 
      
      return segmentationProps[segmentationTypeSelected].setTableData(newSegmentationTable)
    }
  };

  const handleCheckSegmentation = (index: number) => {
    if(segmentationTypeSelected && 
      segmentationProps[segmentationTypeSelected].tableData.length > 0
    ){
      const newSegmentationTable = [...segmentationProps[segmentationTypeSelected].tableData];
      newSegmentationTable[index].checked = !newSegmentationTable[index].checked; 
      
      return segmentationProps[segmentationTypeSelected].setTableData(newSegmentationTable)
    }
  };

  return (
    <>
      <S.Wrapper>
        <S.SelectWrapper>
          <Select 
            options={segmentationTypes}
            placeholder='Tipo'
            value={selectedSegmentationType}
            isSearchable={false}
            onChange={(option) => {
              if(option)
              setSelectedSegmentationType(option);
              setSelectedSegmentation([]);
            }}
          />
          <Select
            isMulti
            options={selectedSegmentationType && segmentationTypeSelected
              ? [optionSelectAll, ...selectList[segmentationTypeSelected].list]
              : []
            }
            placeholder='Tipo'
            value={selectedSegmentation}
            noOptionsMessage={() => 'Nenhuma opção'}
            onChange={(options, meta: any) => {
              const { option } = meta;

              if(option && option.value === optionSelectAll.value) {
                selectAllOptionsShow();
              } else {
                setSelectedSegmentation(options);
              }
            }}
            closeMenuOnSelect={false}
            classNamePrefix="segmentationLimitHeight"
          />
        </S.SelectWrapper>

        <DefaultButton 
          className="medium" 
          onClick={(e) => {
            e.preventDefault();
            handleCreateSegmentationTable();
          }}
        >
          Criar
        </DefaultButton>
        <DefaultButton 
          className="medium" 
          style={{ background: '#CFD2D4', color: '#565656'}}
          onClick={(e) => {
            e.preventDefault();
            setSelectedSegmentation([]);
          }}
        >
          Limpar
        </DefaultButton>
      </S.Wrapper>
            
      {segmentationTypeSelected && 
        segmentationProps[segmentationTypeSelected].tableData.length > 0 && (
        <S.TableContainer>
          <S.TableHeader>
            <S.TableTitle>
              {segmentationProps[segmentationTypeSelected].name}
            </S.TableTitle>
          </S.TableHeader>
          <S.TableActions>
            <S.SelectAllContainer>
              <input 
                type='checkbox'
                onChange={(e) => handleSelectAllSegmentation(e.target.checked)} 
              />
              <label>Selecionar todos</label>
            </S.SelectAllContainer>
            <S.ActionButton 
              danger
              onClick={(e) => {
                e.preventDefault()
                onDeleteCheckedSegmentation();
              }}
            >
              Excluir
            </S.ActionButton>
          </S.TableActions>
          <S.SegmentationLineContainer>
            {segmentationProps[segmentationTypeSelected].tableData.map((item, index) => (
              <SegmentationLine 
                key={index}
                rowName={item.label}
                isChecked={item.checked}
                onDeleteItem={(e) => {
                  e.preventDefault();
                  onDeleteSelectedSegmentation(index);
                }}
                onCheckItem={() => handleCheckSegmentation(index)}
              />
            ))}
          </S.SegmentationLineContainer>
        </S.TableContainer>
      )}
    </>
  )
}