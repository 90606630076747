import httpClient from "../http-client";
import { IRequestSignature } from "../models/for-request/signature";
import { ISignatureUpdate } from "../models/for-update/signatures";
import { ISignatureResponse } from "../models/from-api-response/Signatures";
import { ISignature } from "../models/signatures";

export const getSignatures = async (
    data?: IRequestSignature
  ): Promise<ISignatureResponse[]> => {
  return(await httpClient.get('/signs', { params: data })).data 
}

export const getSignature = async (
  signatureId: string
): Promise<ISignatureResponse> => {
  return(await httpClient.get(`/signs/${signatureId}`)).data
}

export const createSignatures = async ({
  name,
  role,
  company,
  signature
}: ISignature): Promise<void> => {
  await httpClient.post(`/signs`, {
    name,
    role,
    company,
    signature
  });
}

export const updateSignature = async ({
  signatureId,
  name,
  role,
  company,
  signature,
  active
}: ISignatureUpdate): Promise<void> => {
  await httpClient.put(`/signs/${signatureId}`, {
    name,
    role,
    company,
    signature,
    active
  });
}

export const deleteSignature = async (signatureId: string): Promise<void> => {
  await httpClient.delete(`/signs/${signatureId}`);
};