const checkPhoneNumber = (str?: string) => {
  var phoneRegex = /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/;
  if (str) {
    if (str !== '' && !str.match(phoneRegex)) {
      return true;
    } else {
      return false;
    }
  }
};

export default checkPhoneNumber;
