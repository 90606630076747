import React, { lazy } from "react";
import { useAuth } from "../../context/AuthContext";
import { Route, Switch, Redirect } from "react-router-dom";
import { RouteProps } from "react-router";
import Authors from "../../pages/Authors";
import Categories from "../../pages/Categories";
import Contents from "../../pages/Contents";
import Courses from "../../pages/Courses";
import CreateAndEditAuthors from "../../pages/CreateAndEditAuthors";
import CreateAndEditCategory from "../../pages/CreateAndEditCategory";
import CreateAndEditContent from "../../pages/CreateAndEditContent";
import CreateAndEditCourse from "../../pages/CreateAndEditCourse";
import CreateAndEditExam from "../../pages/CreateAndEditExam";
import CreateAndEditFAQ from "../../pages/CreateAndEditFAQ";
import CreateAndEditModule from "../../pages/CreateAndEditModule";
import CreateAndEditQuestion from "../../pages/CreateAndEditQuestion";
import CreateAndEditTrail from "../../pages/CreateAndEditTrail";
import CreateAndEditUsers from "../../pages/CreateAndEditUsers";
import Engagement from "../../pages/Engagement";
import Exams from "../../pages/Exams";
import FAQ from "../../pages/FAQ";
import FinishedCourses from "../../pages/FinishedCourses";
import Modules from "../../pages/Modules";
import Profile from "../../pages/Profile";
import Questions from "../../pages/Questions";
import Trails from "../../pages/Trails";
import UserAccess from "../../pages/UserAccess";
import Users from "../../pages/Users";
import Events from "../../pages/Events";
import CreateAndEditEvent from "../../pages/CreateAndEditEvents";
import GoogleCalendarConfiguration from "../../pages/GoogleCalendarConfiguration";
import { Certificate } from "../../pages/Certificate";
import { CreateAndEditCertificate } from "../../pages/CreateAndEditCertificate"
import { CreateAndEditSignature } from "../../pages/CreateAndEditSignatures";
import { Signatures } from "../../pages/Signatures";

const Login = lazy(() => import("../../pages/Login"));



const AuthRequired = (props: RouteProps) => {
  const { user } = useAuth();

  if (!user) {
    return <Redirect to="/" />;
  }

  return <Route {...props} />;

};

export default function Routes() {
  return (
    <Switch>
      <Route path="/" exact>
        <Login />
      </Route>
      <AuthRequired exact path="/profile" component={Profile} />
      <AuthRequired exact path="/users" component={Users} />
      <AuthRequired exact path="/create-user" component={CreateAndEditUsers} />
      <AuthRequired exact path="/edit-user/:userId" component={CreateAndEditUsers} />
      <AuthRequired exact path="/authors" component={Authors} />
      <AuthRequired
        exact
        path="/edit-author/:authorId"
        component={CreateAndEditAuthors}
      />
      <AuthRequired exact path="/categories" component={Categories} />
      <AuthRequired exact path="/create-category" component={CreateAndEditCategory} />
      <AuthRequired
        exact
        path="/edit-category/:categoryId"
        component={CreateAndEditCategory}
      />
      <AuthRequired exact path="/courses/contents" component={Contents} />
      <AuthRequired
        exact
        path="/courses/create-content"
        component={CreateAndEditContent}
      />
      <AuthRequired
        exact
        path="/courses/edit-content/:contentId"
        component={CreateAndEditContent}
      />
      <AuthRequired exact path="/courses/modules" component={Modules} />
      <AuthRequired
        exact
        path="/courses/create-module"
        component={CreateAndEditModule}
      />
      <AuthRequired
        exact
        path="/courses/edit-module/:moduleId"
        component={CreateAndEditModule}
      />
      <AuthRequired exact path="/courses/courses" component={Courses} />
      <AuthRequired
        exact
        path="/courses/create-course"
        component={CreateAndEditCourse}
      />
      <AuthRequired
        exact
        path="/courses/edit-course/:trailId"
        component={CreateAndEditCourse}
      />
      <AuthRequired exact path="/faqs" component={FAQ} />
      <AuthRequired exact path="/create-faq" component={CreateAndEditFAQ} />
      <AuthRequired exact path="/edit-faq/:faqId" component={CreateAndEditFAQ} />
      <AuthRequired exact path="/reports/engagement" component={Engagement} />
      <AuthRequired
        exact
        path="/reports/finished-courses"
        component={FinishedCourses}
      />
      <AuthRequired exact path="/reports/user-access" component={UserAccess} />
      <AuthRequired exact path="/exams" component={Exams} />
      <AuthRequired exact path="/exams/create-exam" component={CreateAndEditExam} />
      <AuthRequired
        exact
        path="/exams/edit-exam/:examId"
        component={CreateAndEditExam}
      />
      <AuthRequired exact path="/questions" component={Questions} />
      <AuthRequired
        exact
        path="/questions/create-question"
        component={CreateAndEditQuestion}
      />
      <AuthRequired
        exact
        path="/questions/edit-question/:questionId"
        component={CreateAndEditQuestion}
      />
      <AuthRequired exact path="/trails" component={Trails} />
      <AuthRequired
        exact
        path="/trails/create-trail"
        component={CreateAndEditTrail}
      />
      <AuthRequired
        exact
        path="/trails/edit-trail/:trailId"
        component={CreateAndEditTrail}
      />
      
        <AuthRequired
        exact path="/events" component={Events} 
      />
      
        <AuthRequired
       exact path="/events/create-event/" component={CreateAndEditEvent} 
      />
      
        <AuthRequired
        exact path="/events/edit-event/:eventId" component={CreateAndEditEvent}
      />
      
        <AuthRequired
        exact path="/configuration/calendar" component={GoogleCalendarConfiguration} 
      />

      <AuthRequired
        exact path="/certificates" component={Certificate} 
      />
      
      <AuthRequired
       exact path="/certificate/create-certificate/" component={CreateAndEditCertificate} 
      />
      
      <AuthRequired
        exact path="/certificate/edit-certificate/:certificateId" component={CreateAndEditCertificate}
      />

      <AuthRequired
        exact path="/signatures" component={Signatures} 
      />

      <AuthRequired
        exact path="/signature/create-signature" component={CreateAndEditSignature} 
      />

      <AuthRequired
        exact path="/signature/edit-signature/:signatureId" component={CreateAndEditSignature} 
      />
      <Route component={Profile} />
    </Switch>
  );
}
