import User from "../models/user";

const getActualUser = async (): Promise<User> => {
  let user = {} as User;
  if (window.localStorage.getItem("leve-educacao-user")) {
    user = JSON.parse(window.localStorage.getItem("leve-educacao-user") || "");
  }

  return user;
};

export default getActualUser;
