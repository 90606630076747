import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import {
  activateOrInactivateCategory as activateOrInactivateCategoryService,
  getAllCategories,
  deleteCategory,
} from "../../services/categories";
import { CategoriesContainer } from "./style";
import Category from "../../models/category";
import getErrorMessage from "../../helpers/get-error-message";
import DefaultButton from "../../components/DefaultButton";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultTable from "../../components/DefaultTable";

const Categories: React.FC = () => {
  const history = useHistory();

  const [categories, setCategories] = useState([] as Category[]);

  const getCategories = async () => {
    const localCategories = await getAllCategories();
    if (localCategories && localCategories.length) {
      setCategories(localCategories);
    }
  };

  const editCategory = useCallback(
    (categoryId: any) => {
      history.push(`edit-category/${categoryId}`);
    },
    [history]
  );

  const removeCategory = useCallback(async (categoryId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover esta categoria?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCategory(categoryId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Categoria excluída com sucesso!",
          });

          await getCategories();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro",
            text: errorMessage.replace('uma trilha','um curso')
          });
        }
      }
    });
  }, []);

  const createCategory = async () => {
    history.push("create-category");
  };

  const activateOrInactivateCategory = useCallback(
    async (categoryId: any, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${
          activate ? "ativar" : "inativar"
        } esta categoria?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCategoryService(categoryId, activate);

            getCategories();
          } catch (error) {
            const errorMessage = getErrorMessage(error);
            Swal.fire({
              icon: "error",
              title: "Erro",
              text: errorMessage.replace('uma trilha','um curso').slice(16),
            });
          }
        }
      });
    },
    []
  );

  const contentsToBeShown = useMemo(() => {
    return categories && categories.length
      ? categories.map((category) => ({
          id: category.category_id,
          title: category.title,
          description: category.description,
          active: category.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <DefaultButton
                onClick={() => editCategory(category.category_id)}
                className="small info"
                title="Editar Categoria"
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateCategory(
                    category?.category_id,
                    !category.is_active
                  )
                }
                className="small warning"
                title={
                  (category.is_active ? "Inativar" : "Ativar") + " Conteúdo"
                }
              >
                {category.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => {
                  removeCategory(category.category_id);
                }}
                className="small danger"
                title="Excluir Categoria"
              >
                <BiTrash />
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [categories, editCategory, removeCategory, activateOrInactivateCategory]);

  useEffect(() => {
    getCategories();
  }, []);

  return (
    <CategoriesContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Categorias</span>]}
      />

      <DefaultPageTitle>Categorias</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCategory}>Criar Categoria</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={contentsToBeShown}
        emptyListMessage={"Não foram encontradas categorias cadastradas!"}
      />
    </CategoriesContainer>
  );
};

export default Categories;
