import { BiTrash } from 'react-icons/bi';
import DefaultButton from '../../../DefaultButton';
import * as S from './style'; 

interface SegmentationLineProps {
  rowName: string;
  isChecked: boolean;
  onDeleteItem: (event: React.MouseEvent<HTMLButtonElement>) => void;
  onCheckItem: () => void;
}

export function SegmentationLine({ 
  rowName,
  isChecked,
  onDeleteItem, 
  onCheckItem 
}: SegmentationLineProps) {

  return (
    <S.TableContainer>
      <S.Content>
        <input 
          type='checkbox' 
          onChange={onCheckItem}
          checked={isChecked}
        />
        <S.RowName>{rowName}</S.RowName>
      </S.Content>

      <DefaultButton
        onClick={onDeleteItem}
        className="small danger"
        title="Excluir"
      >
          <BiTrash />
      </DefaultButton>
    </S.TableContainer> 
  )
}
