import styled from "styled-components";

const CreateAndEditTrailContainer = styled.div`
  .intro-image {
    max-width: 400px;
    margin-top: 10px;
  }
`;

const SelectCategoryAndTrail = styled.fieldset`
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  margin-top: 20px;
`;

const Legend = styled.legend`
  flex-grow: 1;
  color: var(--primary-color);
  font-size: var(--font-small);
  font-weight: 600;
  padding: 0 5px;
`;

const ThumbnailAndBannerContainer = styled.section`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;

  div > .header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 12px;
    color: var(--default-dark-gray);


    h5 {
      font-weight: normal;
      font-size: 18px;
    }

    span {
      font-size: 14px;
    }
  }

`

const ThumbnailUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 20px;
  width: 100%;

  img {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BannerUploadContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 20px;
  width: 100%;

  img {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LessonSelectionContainer = styled.div`
  width: 100%;
`;

const CourseImageContainer = styled.img`
  width: 50%;
`;

const CourseThumbnail= styled.img`
  max-width: 600px;
`;

const SortLessonsContainer = styled.div`
  margin-bottom: 40px;
  width: 100%;
  & .selected-lessons {
    width: 100%;
    border-radius: 4px;
    border: solid 1px var(--default-dark-gray);
    padding: 5px;

    & > .buttons {
      & > :not(:last-child) {
        margin-bottom: 5px;
      }

      button {
        svg {
          display: block;
        }
        &.down {
          svg {
            fill: var(--danger-color);
          }
        }
        &.up {
          svg {
            fill: var(--success-color);
          }
        }
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
    }

    & > .lesson-title {
      flex-grow: 1;
      color: var(--primary-color);
      font-size: var(--font-small);
      font-weight: 600;
    }

    & > :not(:last-child) {
      margin-right: 10px;
    }

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  & > div:not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  
   .header {
  
    align-items: center;
    padding-bottom: 12px;
    color: var(--default-dark-gray);



    span {
      font-size: 14px;
    }
  }
`;

const ContentWithFlexRow = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
`;

const ButtonOnCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export {
  CreateAndEditTrailContainer,
  LessonSelectionContainer,
  CourseImageContainer,
  ThumbnailUploadContainer,
  BannerUploadContainer,
  ThumbnailAndBannerContainer,
  CourseThumbnail,
  SortLessonsContainer,
  ContentUploadOrSelectContainer,
  SelectCategoryAndTrail,
  Legend,
  ContentWithFlexRow,
  ButtonOnCenter
};
