import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultCreateButtonAndSearchFormContainer } from "../../components/DefaultCreateButtonAndSearchFormContainer";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import getErrorMessage from "../../helpers/get-error-message";
import Trail from "../../models/trail";
import {
  deleteCourse,
  getAllCourses,
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from "../../services/courses";
import { CoursesContainer } from "./style";

const Courses: React.FC = () => {
  const history = useHistory();

  const [courses, setCourses] = useState([] as Trail[]);
  const [isLoading, setIsLoading] = useState(true);
  const [courseName, setCourseName] = useState("");

  const getCourses = async (page = 0) => {
    setIsLoading(true);
    
    try {
      const localCourses = await getAllCourses({
        name: courseName,
      });

      if (localCourses && localCourses.length) {
        setCourses(localCourses);
        setIsLoading(false);
      }
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
    setIsLoading(false);
  };

  const editCourse = useCallback(
    (courseId: any) => {
      history.push(`/courses/edit-course/${courseId}`);
    },
    [history]
  );

  const removeCourse = useCallback(async (courseId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este curso?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCourse(courseId);
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Curso excluido com sucesso!",
          });

          await getCourses();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir curso",
            text: errorMessage,
          });
        }
      }
    });
  }, []);

  const createCourse = async () => {
    history.push("/courses/create-course");
  };

  const activateOrInactivateCourse = useCallback(
    async (courseId: any, activate: boolean) => {
      Swal.fire({
        title: "Confirmação",
        text: `Tem certeza que deseja ${
          activate ? "ativar" : "inativar"
        } este curso?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCourseService(courseId, activate);

            getCourses();
          } catch (error) {
            const errorMessage = getErrorMessage(error);
            Swal.fire({
              title: "Erro",
              text: errorMessage,
            });
          }
        }
      });
    },
    []
  );

  const coursesToBeShown = useMemo(() => {
    return courses && courses.length
      ? courses.map((course) => ({
          id: course.id,
          course_id: course.course_id,
          name: course.name,
          description: course.description,
          active: course.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <DefaultButton
                className="small info"
                onClick={() => editCourse(course.trail_id)}
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateCourse(course.trail_id, !course.is_active)
                }
                className="small warning"
                title={(course.active ? "Inativar" : "Ativar") + " Conteúdo"}
              >
                {course.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => removeCourse(course.trail_id)}
                className="small danger"
                title="Excluir Curso"
              >
                <BiTrash />
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [courses, editCourse, removeCourse, activateOrInactivateCourse]);

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <CoursesContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Cursos</span>]}
      />

      <DefaultPageTitle>Cursos</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createCourse}>Criar Cursos</DefaultButton>
        </CreateButtonDefaultContainer>
        
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getCourses()
          }}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Digite sua pesquisa aqui..."
            value={courseName}
            onChange={(e) => setCourseName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={coursesToBeShown}
        emptyListMessage={"Não foram encontradas cursos cadastrados!"}
        isLoading={isLoading}
      />
    </CoursesContainer>
  );
};

export default Courses;
