import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import getErrorMessage from "../../helpers/get-error-message";
import TrailCategory from "../../models/from-api-response/trail-category";
import {
  getTrails as getTrailsService,
  enableOrDisableTrail as enableOrDisableTrailService,
  deleteTrail as deleteTrailService,
} from "../../services/trail-category";

const TrailsContainer = styled.div``;

const Trails: React.FC = () => {
  const history = useHistory();
  const [trails, setTrails] = useState([] as TrailCategory[]);

  const createTrail = () => {
    history.push(`/trails/create-trail`);
  };

  const getTrails = async () => {
    const trails = await getTrailsService();
    setTrails(trails);
  };

  const editTrail = (trailId: string) => {
    history.push(`/trails/edit-trail/${trailId}`);
  };

  const deleteTrail = async (trailId: string) => {
    const result = await Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja remover esta trilha?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        await deleteTrailService(trailId);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: "Trilha removida com sucesso",
        });

        await getTrails();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: "Erro ao excluir trilha!",
          text: errorMessage,
        });
      }
    }
  };

  const enableOrDisableTrail = async (trailId: string, enable: boolean) => {
    const result = await Swal.fire({
      icon: "question",
      text: `Tem certeza que deseja ${
        enable ? "ativar" : "inativar"
      } esta trilha?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        await enableOrDisableTrailService(trailId, enable);

        Swal.fire({
          icon: "success",
          title: "Sucesso!",
          text: `Trilha ${enable ? "ativada" : "inativada"} com sucesso`,
        });

        await getTrails();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: `Erro ao ${enable ? "ativar" : "inativar"} trilha!`,
          text: errorMessage.replace('tag','trilha').replace('uma trilha', 'um curso').slice(16),
        });
      }
    }
  };

  const trailsToShow = useMemo(() => {
    return trails && trails.length
      ? trails.map((trail) => ({
          title: trail.title,
          description: trail.description,
          active: trail.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <DefaultButton
                className="small info"
                onClick={() => editTrail(trail.tag_category_id)}
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                className="small danger"
                onClick={() => deleteTrail(trail.tag_category_id)}
              >
                <BiTrash />
              </DefaultButton>{" "}
              <DefaultButton
                className="small warning"
                onClick={() =>
                  enableOrDisableTrail(trail.tag_category_id, !trail.is_active)
                }
              >
                {trail.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [trails]);

  useEffect(() => {
    getTrails();
  }, []);

  return (
    <TrailsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Trilhas</span>]}
      />

      <DefaultPageTitle>Trilhas</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createTrail}>Criar Trilha</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "title",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
            order: (localUsers: any[], currentOrder: string) => {
              setTrails([
                ...trails.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes("active") &&
                    currentOrder.includes("asc");
                  if (isDescendant) {
                    return a.is_active ? 1 : -1;
                  } else {
                    return a.is_active ? -1 : 1;
                  }
                }),
              ]);
            },
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={trailsToShow}
        emptyListMessage="Não foram encontradas trilhas cadastradas!"
      />
    </TrailsContainer>
  );
};

export default Trails;
