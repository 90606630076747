import httpClient from "../http-client";
import UserFromResponse from "../models/from-api-response/user";

const getActualUser = async () => {
  return (await httpClient.get("users/me")).data;
};

const getAllUsers = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }

  const users = (
    await httpClient.get<{ 
      users: UserFromResponse[], 
      total: number
    }>(`users/`, { params: filters })
  ).data;

  if (users && users.users.length) {
    return users;
  }
};

const getUsersForSelect = async () => {
  const users = (await httpClient.get<UserFromResponse[]>(`users/`)).data;

  if (users && users.length) {
    return users.map((user) => ({
      value: user.user_id,
      label: user.name,
    }));
  }

  return [] as { value: string; label: string }[];
};

const updateUserPermission = async (user_id: string) => {
  return (await httpClient.patch(`users/set-user-admin`, { user_id })).data;
};

const createUser = async (newUser: any) => {
  await httpClient.post(`/users/create-user`, newUser);
};

const updateUser = async (userId: string, newUser: any) => {
  await httpClient.put(`/users/${userId}`, newUser);
};

const getUser = async (userId: string) => {
  const users = (await httpClient.get(`/users`)).data || [];
  return users.find((user: any) => user.user_id === userId);
};

const getOneUser = async (userId: string) => {
  return (await httpClient.get(`/users/${userId}`)).data;
}

const removeUser = async (userId: string) => {
  await httpClient.delete(`/users/${userId}`);
};

const toogleActiveUser = async (userId: string) => {
  await httpClient.patch(`/users/${userId}/active-deactive`);
};

export {
  getUsersForSelect,
  getActualUser,
  updateUserPermission,
  getAllUsers,
  createUser,
  updateUser,
  getUser,
  removeUser,
  toogleActiveUser,
  getOneUser
};
