import React from "react";
import {
  AppContainer,
  AppContent,
  AppLeftContent,
  AppRightContent,
} from "./AppStyle";
import Routes from "./components/Routes";
import SideBar from "./components/SideBar";
import SegmentationProvider from "./context/SegmentationContext";

function App() {
  return (
    <AppContainer>
      <AppLeftContent>
        <SideBar />
      </AppLeftContent>
      <AppRightContent>
        <SegmentationProvider>
          <AppContent>
            <Routes />
          </AppContent>
        </SegmentationProvider>
      </AppRightContent>
    </AppContainer>
  );
}

export default App;
