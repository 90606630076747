import convertToContent from "../helpers/converters/convert-content";
import httpClient from "../http-client";
import Content from "../models/content";
import ContentForCreate from "../models/for-create/content";
import ContentForUpdate from "../models/for-update/content";
import ContentFromReponse from "../models/from-api-response/content";
import { uploadFile } from "./files";

const getContent = async (contentId: string) => {
  const content = (await httpClient.get(`contents/${contentId}`)).data;

  return content;
};

const getAllContents = async () => {
  const contents = (await httpClient.get<ContentFromReponse[]>("contents"))
    .data;

  let convertedContents = [] as Content[];
  if (contents && contents.length) {
    convertedContents = contents.map(convertToContent);
  }

  return convertedContents;
};

const createContent = async (newContent: ContentForCreate) => {
  const createContentResponse = (
    await httpClient.post<ContentFromReponse>(`contents`, newContent)
  ).data;

  return createContentResponse;
};

const updateContent = async (
  contentId: string,
  newContent: ContentForUpdate
) => {
  await httpClient.put(`contents/${contentId}`, newContent);
};

const deleteContent = async (contentId: string) => {
  await httpClient.delete(`contents/${contentId}`);
};

const changeContentThumbnail = async (
  contentId: string,
  formData: FormData
) => {
  const uploadedFile = await uploadFile(formData);
  await httpClient.patch(`contents/${contentId}/thumbnail`, {
    thumbnail: uploadedFile.reference,
  });
};

const changeContentBanner = async (contentId: string, formData: FormData) => {
  const uploadedFile = await uploadFile(formData);

  await httpClient.patch(`contents/${contentId}/banner`, {
    banner: uploadedFile.reference,
  });
};

const activateOrInactivateContent = async (
  contentId: string,
  activate: boolean
) => {
  await httpClient.put(`contents/${contentId}/active`, { is_active: activate });
};

const getContentsForSelect = async () => {
  const courses = (await httpClient.get<any>('contents/')).data;

  if (courses && courses.length) {
    return courses.map((course: any) => ({
      value: course.content_id,
      label: course.title,
    }));
  }

  return [] as { value: string; label: string }[];
};

export {
  getAllContents,
  deleteContent,
  updateContent,
  createContent,
  changeContentThumbnail,
  changeContentBanner,
  getContent,
  activateOrInactivateContent,
  getContentsForSelect,
};
