import httpClient from "../http-client";

const login = async (userName: string, password: string, company?: string) => {
  const response = (
    await httpClient.post("/auth/login", {
      registration: userName,
      password: password,
      company: company
    })
  ).data;

  if (!response.user.is_admin) {
    throw new Error(
      "Você não possui permissão para acessar o painel do administrador!"
    );
  }

  window.localStorage.setItem("leveeducacao-api-token", response.api_token);

  return response;
};

export { login };
