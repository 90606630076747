import styled from "styled-components";

export const LoadingContent = styled.div`
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .spinner {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid var(--primary-color);
    width: 80px;
    height: 80px;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }

  .text {
    margin-top: 5px;
    font-weight: bold;
    font-size: 14px;
  }

  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
`;