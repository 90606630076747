import styled from "styled-components";

export const SelectCategoryAndTrail = styled.fieldset`
  width: 100%;
  padding: 15px;
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  margin-top: 20px;
`;

export const Legend = styled.legend`
  flex-grow: 1;
  color: var(--primary-color);
  font-size: var(--font-small);
  font-weight: 600;
  padding: 0 5px;
`;

export const CenterContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ShowImage = styled.img`
  margin: 10px 0;
  max-height: 300px;
`;

export const AddMoreSignaturesButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: white;
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  cursor: pointer;
  font-size: var(--font-large);
  font-weight: bold;
  text-decoration: none;
  outline: none;
  padding: 15px 20px;
  border-radius: 5px ;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08), 0px 4px 6px rgba(50, 50, 93, 0.1);

  svg {
    margin-right: 10px;
  }
`;

export const SignatureContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
`

export const ExcludeSignatureButton = styled.button`
  border: 0px;
  background-color: white;
  cursor: pointer;

  svg {
    width: 21px;
    height: 21px;
    margin: 8px ;
    color: red;
  }
`;