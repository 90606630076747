import httpClient from "../http-client";
import TrailCategory from "../models/from-api-response/trail-category";

const getTrails = async (filters?: any) => {
  const trails = (
    await httpClient.get<TrailCategory[]>(`/tag-categories`, {
      params: filters,
    })
  ).data;
  return trails;
};

const getTrail = async (trailId: string) => {
  const trail = (
    await httpClient.get<TrailCategory>(`/tag-categories/${trailId}`)
  ).data;
  return trail;
};

const createTrail = async (newTrail: {
  title: string;
  description?: string;
}) => {
  await httpClient.post(`/tag-categories`, newTrail);
};

const updateTrail = async (
  trailId: string,
  newTrail: {
    title: string;
    description?: string;
  }
) => {
  await httpClient.put(`/tag-categories/${trailId}`, newTrail);
};

const deleteTrail = async (trailId: string) => {
  await httpClient.delete(`/tag-categories/${trailId}`);
};

const enableOrDisableTrail = async (trailId: string, enable: boolean) => {
  await httpClient.put(`/tag-categories/${trailId}/active`, {
    is_active: enable,
  });
};

export {
  getTrails,
  getTrail,
  updateTrail,
  createTrail,
  deleteTrail,
  enableOrDisableTrail,
};
