import React, { useEffect, useState, useCallback, useMemo } from "react";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";

import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";

import { getSignatures, deleteSignature, updateSignature } from "../../services/signatures";
import getErrorMessage from "../../helpers/get-error-message";
import { ISignatureResponse } from "../../models/from-api-response/Signatures";
import { DefaultCreateButtonAndSearchFormContainer } from "../../components/DefaultCreateButtonAndSearchFormContainer";
import { AiOutlineCheck, AiOutlineClose, AiOutlineSearch } from "react-icons/ai";
import DefaultInput from "../../components/DefaultInput";

export function Signatures() {
  const history = useHistory();

  const [signatureName, setSignatureName] = useState<string>('');
  const [allSignatures, setAllSignatures] = useState<ISignatureResponse[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const redirectToCreateSignature = () => {
    history.push("/signature/create-signature");
  };

  const getAllSignatures = useCallback(async () => {
    setIsLoading(true);

    const allSignaturesReturn = await getSignatures();
    if(allSignaturesReturn && allSignaturesReturn.length){
      setAllSignatures(allSignaturesReturn);
    }

    setIsLoading(false);
  }, []);

  const editSignature = useCallback(
    (signatureId: any) => {
      history.push(`/signature/edit-signature/${signatureId}`);
    },
    [history]
  );

  const removeSignature = useCallback(async (signatureId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover esta assinatura?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteSignature(signatureId);
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Assinatura excluída com sucesso!",
          });

          await getSignatures();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir a assinatura!",
            text: errorMessage,
          });
        }
      }
    });
  }, []);

  const ActiveOrInactiveSignature = async (signature: ISignatureResponse) => {
    const result = await Swal.fire({
      icon: "question",
      text: `Tem certeza que deseja ${
        signature.active ? "inativar" : "ativar"
      } esta assinatura?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        await updateSignature({ 
          active: !signature.active, 
          signatureId: signature.sign_id,
        });

        Swal.fire({
          icon: "success",
          title: `Assinatura ${signature.active ? "inativada" : "ativada"} com sucesso!`,
        });

        await getAllSignatures();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: `Erro ao ${signature.active ? "inativar" : "ativar"} a assinatura`,
          text: errorMessage,
        });
      }
    }
  }

  const signaturesToShown = useMemo(() => {
    return allSignatures && allSignatures.length ? 
    allSignatures.map((signature) => ({
      id: signature.sign_id,
      name: signature.name,
      office: signature.role,
      company: signature.company,
      active: signature.active ? <AiOutlineCheck /> : <AiOutlineClose />,
      actions: (
        <div>
          <DefaultButton
            className="small info"
            onClick={() => editSignature(signature.sign_id)}
          >
            <BiEdit size={16}/>
          </DefaultButton>{" "}
          <DefaultButton
            title="Ativar/Inativar Assinatura"
            className="small warning"
            onClick={() => ActiveOrInactiveSignature(signature)}
          >
            {signature.active ? (
              <AiOutlineClose size={16}/>
            ) : (
              <AiOutlineCheck size={16}/>
            )}
          </DefaultButton>{" "}
          <DefaultButton
            onClick={() => removeSignature(signature.sign_id)}
            className="small danger"
            title="Excluir Assinatura"
          >
            <BiTrash size={16}/>
          </DefaultButton>
        </div>
      )
    })) : [];
  }, [allSignatures, editSignature, removeSignature]);

  const searchSignatures = useCallback(async (signature?: React.FormEvent) => {
    if (signature && signature.preventDefault) {
      signature.preventDefault();
    }

    setIsLoading(true);
    try {
      const allSignaturesReturn = await getSignatures({ 
        name: signatureName ? signatureName : undefined,
      });
      setAllSignatures(allSignaturesReturn);

      setIsLoading(false)
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao excluir o template!",
        text: errorMessage,
      });
    }

    setIsLoading(false)
  }, [signatureName])

  useEffect(() => {
    getAllSignatures();
  }, [getAllSignatures]);

  return (
    <>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Assinaturas</span>]}
      />

      <DefaultPageTitle>Assinaturas</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={() => redirectToCreateSignature()}>Criar Assinatura</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={searchSignatures}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Pesquise por nome aqui..."
            value={signatureName}
            onChange={(e) => setSignatureName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Empresa</span>,
            propName: "office",
          },
          {
            headerLabel: <span>Curso</span>,
            propName: "company",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={signaturesToShown}
        emptyListMessage={"Não foram encontrados assinaturas cadastradas!"}
        isLoading={isLoading}
      />
    </>
  );
};
