import styled from "styled-components";

const CreateAndEditQuestionContainer = styled.div``;

const AlternativesList = styled.div`
  & > * {
    border-bottom: solid 2px var(--default-dark-gray);
    padding: 10px 0;
  }

  margin-top: 10px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px;
  border-radius: 5px;
`;

const ContentUploadOrSelectContainer = styled.div`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px 15px;
  width: 100%;

  iframe {
    margin-top: 10px;
    width: 600px;
    height: 340px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export {
  CreateAndEditQuestionContainer,
  AlternativesList,
  ContentUploadOrSelectContainer
};

