import styled from "styled-components";

const DefaultCreationForm = styled.form`
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  & .react-datepicker-wrapper {
    flex-grow: 1;

    input {
      width: 100%;
      height: 40px;
      border-radius: 5px;
      border: solid 1px var(--default-dark-gray);
      padding: 10px 15px;
    }
  }

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
`;

interface DefaultCreationProps {
  unsetLabel?: boolean;
}

export const DefaultCreationFormGroup = styled.div<DefaultCreationProps>`
  width: 100%;

  form {
    width: 100%;
  }

  & + div {
    margin-top: 16px;
  }

  label {
    min-width: ${(props) => !props.unsetLabel ? '145px' : 'unset'};
    margin-bottom: 12px;
    font-size: var(--font-small);
    color: var(--primary-color);
    font-weight: 700;
  }

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .warning-selected-intro {
    color: red; 
    margin-top: 10px;

    span {
      text-decoration: underline; 
      cursor: pointer; 
      margin-left: 3px;
    }
  }

  > .clear-image-button {
    font-size: 14px;
    font-weight: 600;
    color: #dc143c;

    margin-top: 8px;

    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .ql-toolbar.ql-snow + .ql-container.ql-snow {
    border-radius: 5px;
  }
  #textarea-react-quill .ql-editor {
    border-radius: 5px;
  }
`;

export const DefaultCreationFormButtonGroup = styled.div`
  width: 100%;
  margin: 10px 0;

  & > :not(:last-child) {
    margin-right: 10px;
  }

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

export default DefaultCreationForm;
