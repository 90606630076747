import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineDownload } from 'react-icons/ai';
import Swal from "sweetalert2";


import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, { DefaultCreationFormButtonGroup, DefaultCreationFormGroup } from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";

import { getGoogleCalendarConfig, getGoogleCalendarToken, putGoogleCalendarConfig } from "../../services/googleCalendar";
import checkEmptyString from "../../helpers/check-empty-string";
import getErrorMessage from "../../helpers/get-error-message";

import * as S from './style';


const GoogleCalendarConfiguration: React.FC = () => {
  const [status, setStatus] = useState(false);
  const [clientId, setClientId] = useState("");
  const [clientSecret, setClientSecret] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [configurationLink, setConfigurationLink] = useState("");

  const location = useLocation();

  const validate = () => {
    if(!clientId) {
      throw new Error("É nescessário informar um Client ID");
    }

    if(!clientSecret) {
      throw new Error("É nescessário informar um Client Secret");
    }
  }

  const startConfiguration = async () => {
    try {
      validate();

      await putGoogleCalendarConfig({
        clientId,
        clientSecret
      });

      await getConfig();
    } catch(error){
      const errorMessage = getErrorMessage(error);

      Swal.fire({
        icon: "error",
        title: "Erro ao fazer a configuração",
        text: errorMessage,
      });
    }
  }

  const getConfig = async () => {
    try {
      const config = await getGoogleCalendarConfig();
  
      if(config.data.active === false || !!config.data.link) {
        const link = config.data.link ? config.data.link : "";
        setConfigurationLink(link);
        setStatus(false);

        setAccessToken("");
        setRefreshToken("");
        setExpiryDate("");
      } else {
        const authJson = config.data.auth_json;
        const dateExpiry = new Date(authJson.expiry_date).toISOString().substring(0, 10);

        setAccessToken(authJson.access_token);
        setRefreshToken(authJson.refresh_token);
        setExpiryDate(dateExpiry);
        setStatus(config.data.active);
      }
    } catch(error){
      const errorMessage = getErrorMessage(error);

      Swal.fire({
        icon: "error",
        title: "Erro ao buscar a configuração",
        text: errorMessage,
      });
    }
  }

  useEffect(() => {
    getConfig();
  }, [])

  
  const useQuery = useCallback(() => {
    return new URLSearchParams(location.search)
  }, [location.search]) 
    
  let query = useQuery();



  const validationToken = useCallback(
    async () => {
      try {
  
        const code = query.get("code");
        const scope = query.get("scope");
  
        if(!code || !scope) return
  
        await getGoogleCalendarToken({
          code,
          scope
        });

        const loading = document.getElementById("loading");
        if(loading) loading.style.display = "flex";

        setTimeout(() => {
          window.history.pushState({}, document.title, window.location.pathname);
          window.location.reload();
          if (loading) loading.style.display = "none";
          
        }, 1000);
      } catch(error){
        const errorMessage = getErrorMessage(error);
        const loading = document.getElementById("loading");
        if (loading) loading.style.display = "none";
  
        Swal.fire({
          icon: "error",
          title: "Erro para enviar o token",
          text: errorMessage,
        });
      }
    },
    [query],
  )
  

  useEffect(() => {
    validationToken()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div>
      <BreadCrumb 
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <span>Configuração</span>,
          <span>Google calendar</span>,
        ]}
      />

      <DefaultPageTitle>
        Google calendar
      </DefaultPageTitle>

      <S.StatusText>Status</S.StatusText>
      <S.ButtonsContainer>
        <S.StatusInfo 
          type="button"
          active={status}
        >
          {status ? "CONECTADO" : "INATIVO"} 
        </S.StatusInfo>

        <S.DownloadButton
          href={process.env.REACT_APP_GOOGLE_CALENDAR_DOCS}
          target="_blank"
          className={"primary"} 
        >
          BAIXAR PDF PASSO A PASSO
          <AiOutlineDownload width={30} height={30}/>
        </S.DownloadButton>

      </S.ButtonsContainer>

      <DefaultCreationForm>
        <S.FormContainer>
          <DefaultCreationFormGroup>
            <label style={{ marginBottom: '40px', marginTop: '40px' }}>
              Configurações google calendar
            </label>

            <label htmlFor="clientId">
              Client ID
            </label>

            <DefaultInput
              id="clientId"
              value={clientId}
              onChange={(e) => setClientId(e.target.value)}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label htmlFor="clientSecret">
              Client Secret
            </label>

            <DefaultInput
              id="clientSecret"
              value={clientSecret}
              onChange={(e) => setClientSecret(e.target.value)}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label style={{ marginBottom: '40px', marginTop: '40px' }}>
              Informações da conexão com a API
            </label>

            <label>
              Access token
            </label>

            <DefaultInput
              readOnly
              value={accessToken}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label>
              Refresh token
            </label>

            <DefaultInput
              readOnly
              value={refreshToken}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label>
              Expiração do Token
            </label>

            <input
              type="date"
              readOnly
              value={expiryDate}
              onKeyDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return false;
              }}
            />
          </DefaultCreationFormGroup>
  
          {!checkEmptyString(configurationLink) && (
            <S.LinkContainer>
              <S.LinkButton href={configurationLink}>
                  Autorizar a aplicação
              </S.LinkButton>
            </S.LinkContainer>
          )}
        </S.FormContainer>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" >
            Cancelar
          </DefaultButton>
          <DefaultButton
            type="button"
            className="success"
            onClick={() => startConfiguration()}
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </div>
  )
}

export default GoogleCalendarConfiguration;