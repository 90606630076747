import React, { useEffect, useState } from 'react';
import getActualUser from '../../helpers/get-actual-user';
import User from '../../models/user';
import { ProfileContainer } from './style';

const Profile: React.FC = () => {
  const [user, setUser] = useState({} as User);

  useEffect(() => {
    (async () => {
      setUser(await getActualUser());
    })()
  }, [])

  return (
    <ProfileContainer>
      {user.name ? (
        <h2>Seja Bem Vindo, {user.name}!</h2>
      ) : (
        <h2>Seja bem vindo!</h2>
      )}
    </ProfileContainer>
  )
}

export default Profile;