import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { BiDownload, BiTrash } from "react-icons/bi";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import {
  createExtraMaterial as createExtraMaterialService,
  deleteExtraMaterial as deleteExtraMaterialService,
} from "../../services/extra-materials";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import SelectSambaVideosContent from "../../components/SelectSambaVideosContent";
import { hideModal, showModal } from "../../helpers/modal";
import MediaFromResponse from "../../models/from-api-response/media";
import {
  ContentUploadOrSelectContainer,
  CreateAndEditContentContainer,
  ExtraMaterial,
  ExtraMaterialList,
  ExtraMaterialsInput,
  GroupSelectButtons,
} from "./style";
import {
  createContent as createContentService,
  getContent as getContentService,
  updateContent as updateContentService,
} from "../../services/contents-trail";
import checkEmptyString from "../../helpers/check-empty-string";
import { getSignedUrl, uploadFile } from "../../services/files";
import getErrorMessage from "../../helpers/get-error-message";
import DefaultSwitchScorm from "./DefaultSwitchScorm";
import ScormCourses from "../ScormCourses";
import { formatName } from "../../helpers/extra-materials";
import download from "downloadjs";
import TextareaReactQuill from "../../components/TextareaReactQuill/TextareaReactQuill";

interface ExtendedFile extends File {
  webkitRelativePath: string;
}

interface CreateAndEditContentProps {
  contentId: string;
}

const CreateAndEditContent: React.FC = () => {
  const [extraMaterials, setExtraMaterials] = useState<any>([] as any[]);
  const [extraMaterialsToBeDeleted, setExtraMaterialsToBeDeleted] =
    useState<any>([] as any[]);
  const { contentId } = useParams<CreateAndEditContentProps>();
  const history = useHistory();
  const formRef = useRef<HTMLFormElement | null>(null);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [reference, setReference] = useState("");
  const [duration, setDuration] = useState(0);
  const [type, setType] = useState("");
  const [switchState, setSwitchState] = useState("video");
  const [isScormVersion12, setIsScormVersion12] = useState<boolean>(false);

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;

          setReference(referenceUrl);
          setDuration(firstFile.fileInfo.duration / 1000);
          setType(firstFile.qualifier.toUpperCase());
        }
      }
    }
  };

  hideModal();

  const createContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o conteúdo.");
      }

      if (checkEmptyString(description)) {
        throw new Error("Informe uma descrição valida para o conteúdo.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      const createdContent = await createContentService({
        title: title,
        description: description.trim().length ? description : undefined,
        duration: duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toUpperCase(),
        info:
          switchState === "scorm"
            ? { scorm_version: isScormVersion12 ? "1.2" : "2004" }
            : undefined,
      });

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved
        )) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(
          createdContent.content_id,
          uploadedMaterials
        );
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Conteúdo criado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      Swal.fire({
        title: "Erro",
        text: "Houve um erro ao criar o conteúdo. ",
        icon: "error",
      });
    }
  };

  const updateContent = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(title)) {
        throw new Error("Informe um título valido para o conteúdo.");
      }

      if (!reference) {
        throw new Error("Selecione um conteúdo antes.");
      }

      await updateContentService(contentId, {
        title: title,
        description: description || undefined,
        duration: duration,
        reference: reference,
        type:
          switchState === "scorm"
            ? switchState.toUpperCase()
            : type.toUpperCase(),
        is_active: true,
      });

      for (let material of extraMaterialsToBeDeleted) {
        await deleteExtraMaterialService(material.id);
      }

      let uploadedMaterials = [] as { filename: string; reference: string }[];

      if (extraMaterials && extraMaterials.length >= 1) {
        for (let material of extraMaterials.filter(
          (extraMaterial: any) => !extraMaterial.alreadySaved
        )) {
          const formData = new FormData();
          formData.append("file", material);

          await uploadFile(formData).then(({ reference, name }) =>
            uploadedMaterials.push({ reference, filename: name })
          );
        }
      }

      if (uploadedMaterials.length >= 1) {
        await createExtraMaterialService(contentId, uploadedMaterials);
      }

      Swal.fire({
        title: "Sucesso!",
        text: "Conteúdo editado com sucesso!",
        icon: "success",
      });

      goToContents();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const selectContent = () => {
    showModal(
      "Selecionar Conteúdo",
      <SelectSambaVideosContent onSelectContent={onSelectContent} />
    );
  };

  const goToContents = () => {
    history.push("/courses/contents");
  };

  const getContent = useCallback(async () => {
    if (contentId) {
      const { content } = await getContentService(contentId);

      if (content && Object.keys(content).length) {
        setTitle(content.title);
        setDescription(content.description);
        setReference(content.reference);
        setDuration(content.duration);
        setType(content.type);

        const localExtraMaterials =
          content.extra_materials &&
          content.extra_materials.length &&
          content.extra_materials.map((extraMaterial: any) => ({
            id: extraMaterial.extra_material_id,
            name: extraMaterial.name,
            alreadySaved: true,
            reference: extraMaterial.reference,
          }));

        setExtraMaterials(localExtraMaterials);
      }
    }
  }, [contentId]);

  useEffect(() => {
    getContent();
  }, [getContent]);

  const isEditting = useMemo(() => {
    if (contentId) {
      return true;
    }

    return false;
  }, [contentId]);

  const addExtraMaterial = (event: any) => {
    event.preventDefault();

    if (event) {
      let file = event.target.files[0];
      file.alreadySaved = false;

      setExtraMaterials([...(extraMaterials || []), file]);
    }
  };

  const removeExtraMaterial = (extraMaterialToBeRemoved: any) => {
    if (extraMaterialToBeRemoved.alreadySaved) {
      setExtraMaterialsToBeDeleted([
        ...(extraMaterialsToBeDeleted || []),
        extraMaterialToBeRemoved,
      ]);
    }

    let localExtraMaterials = [...(extraMaterials || [])];
    localExtraMaterials = localExtraMaterials.filter(
      (extraMaterial) => extraMaterial !== extraMaterialToBeRemoved
    );

    setExtraMaterials(localExtraMaterials);
    if (formRef) {
      formRef.current?.reset();
    }
  };

  const downloadMaterial = (extraMaterial: any) => {
    download(extraMaterial.reference, formatName(extraMaterial.name));
  };

  const onChangeSwitchState = () => {
    setSwitchState(switchState === "video" ? "scorm" : "video");
    setReference("");
  };

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/contents">Conteúdos</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Conteúdo</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Conteúdo
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label htmlFor="title" className="required">
            Título
          </label>
          <DefaultInput
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="title"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description" className="required">
            Descrição
          </label>
          <TextareaReactQuill state={description} setState={setDescription} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <GroupSelectButtons>
            <label htmlFor="reference" className="required">
              Conteúdo
            </label>
            <DefaultSwitchScorm
              switchState={switchState}
              onChangeSwitchState={onChangeSwitchState}
            />
          </GroupSelectButtons>

          <ContentUploadOrSelectContainer>
            {switchState === "video" ? (
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar Conteúdo
              </DefaultButton>
            ) : (
              <ScormCourses
                setReference={setReference}
                setIsScormVersion12={setIsScormVersion12}
              />
            )}

            {reference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={reference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label>Materiais de Apoio</label>
          <form ref={formRef}>
            <ExtraMaterialsInput
              type="file"
              onChange={(e) => addExtraMaterial(e)}
            />
          </form>
          <ExtraMaterialList>
            {extraMaterials && extraMaterials.length ? (
              extraMaterials.map((material: any, index: number) => (
                <ExtraMaterial key={index}>
                  <h5>{formatName(material.name)}</h5>
                  {material.reference ? (
                    <button
                      onClick={() => downloadMaterial(material)}
                      type="button"
                    >
                      <BiDownload color="green" size={14} />
                    </button>
                  ) : (
                    <></>
                  )}

                  <button
                    onClick={() => removeExtraMaterial(material)}
                    type="button"
                  >
                    <BiTrash size={14} />
                  </button>
                </ExtraMaterial>
              ))
            ) : (
              <p>Não foi encontrado nenhum material de apoio.</p>
            )}
          </ExtraMaterialList>
        </DefaultCreationFormGroup>

        <div className="isrequired">
          <span>*</span>
          <label>Campos obrigatórios</label>
        </div>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToContents}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateContent(e) : createContent(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditContent;
