import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import BreadCrumb from "../../components/BreadCrumb";
import CutImage from "../../components/CutImage";
import DefaultButton from "../../components/DefaultButton";
import { 
  DefaultCreationFormButtonGroup, 
  DefaultCreationFormGroup 
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { LoadingOnPage } from "../../components/LoadingOnPage";

import getErrorMessage from "../../helpers/get-error-message";
import { hideModal, showModal } from "../../helpers/modal";
import { 
  createSignatures as createSignaturesService,
  getSignature as getSignatureService,
  updateSignature as updateSignatureService
 } from "../../services/signatures";
 
import * as S from "./style";

interface CreateAndEditSignatureParams {
  signatureId: string;
}

export function CreateAndEditSignature() {
  const history = useHistory();
  const { signatureId } = useParams<CreateAndEditSignatureParams>();

  const [userName, setUserName] = useState<string>('');
  const [role, setRole] = useState<string>('');
  const [company, setCompany] = useState<string>('');
  const [signature, setSignature] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goToCertificates = () => {
    history.push('/signatures');
  }

  const validate = () => {
    if(!userName) {
      throw new Error("Informe um nome para a assinatura!");
    }

    if(!company) {
      throw new Error("Informe uma empresa para a assinatura!");
    }

    if(!role) {
      throw new Error("Informe um cargo para a assinatura!");
    }

    if(!signature) {
      throw new Error("Anexar imagem da assinatura!");
    }
  }
  
  const createSignature = async () => {
    try {
      validate();

      await createSignaturesService({
        name: userName,
        role,
        company,
        signature,
      });

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Assinatura cadastrada com sucesso!'
      });

      goToCertificates();
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao criar assinatura",
        text: errorMessage,
      });
    }
  }

  const updateSignature = async () => {
    try {
      validate();
      
      await updateSignatureService({
        signatureId,
        name: userName,
        role,
        company,
        signature
      });
      
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Assinatura editada com sucesso!'
      });

      goToCertificates();
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao editar a assinatura",
        text: errorMessage,
      });
    }
  }

  const getSignature = useCallback(async () => {
    setIsLoading(true);
    try {
      const signatureReturn = await getSignatureService(signatureId);
      
      setUserName(signatureReturn.name);
      setRole(signatureReturn.role);
      setCompany(signatureReturn.company);
      setSignature(signatureReturn.signature);

    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao buscar a assinatura",
        text: errorMessage,
      });
    }

    setIsLoading(false);
  }, [signatureId]);

  const selectSignatureImage = () => {
    showModal(
      "Selecionar uma Imagem",
      <CutImage
        aspect={1 / 1}
        onCutImage={(file) => onCutImage(file)}
      />
    );
  };
  
  const onCutImage = (file: File) => {
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => setSignature(`${reader.result}`);

      hideModal();
    }
  };

  const removeSignatureFile = () => {
    setSignature('');
  }

  const isEditing = useMemo(() => {
    return !!signatureId
  }, [signatureId]);

  useEffect(() => {
    if(isEditing) {
      getSignature();
    }
  }, [isEditing, getSignature])

  return (
    <>
      <BreadCrumb 
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/certificates">Assinatura</Link>,
          <span>{isEditing ? "Editar" : "Criar"} Assinatura</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditing ? "Editar" : "Criar"} Assinatura
      </DefaultPageTitle>
        
      {!isLoading ? (
        <>
          <S.SelectCategoryAndTrail>
            <S.Legend>
              <label>
                Assinatura
              </label>
            </S.Legend>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor='user'>Usuário</label>
              <DefaultInput
                id='user'
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor='company'>Empresa</label>
              <DefaultInput
                id='company'
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label className="required" htmlFor='role'>Cargo</label>
              <DefaultInput
                id='role'
                value={role}
                onChange={(e) => setRole(e.target.value)}
              />
            </DefaultCreationFormGroup>

            <DefaultCreationFormGroup>
              <label 
                className="required" 
                htmlFor='signature'
              >
                Anexar imagem da assinatura (fundo transparente ou branco)
              </label>

              {!signature ? (
                <S.CenterContent>
                  <DefaultButton 
                    type="button" 
                    onClick={() => selectSignatureImage()}
                  >
                    Selecionar Imagem
                  </DefaultButton>
                </S.CenterContent>
              ) : (
                <S.CenterContent>
                  <S.ShowImage src={signature}/>
                  <DefaultButton 
                    type="button" 
                    onClick={() => removeSignatureFile()}
                  >
                    Remover Imagem
                  </DefaultButton>
                </S.CenterContent>
              )}
            </DefaultCreationFormGroup>
          </S.SelectCategoryAndTrail>
          

          <DefaultCreationFormButtonGroup>
            <DefaultButton type="button" className="danger" onClick={goToCertificates}>
              Cancelar
            </DefaultButton>
            <DefaultButton
              type="button"
              onClick={() => (isEditing ? updateSignature() : createSignature())}
              className="success"
            >
              Salvar
            </DefaultButton>
          </DefaultCreationFormButtonGroup>
        </>
      ) : (
        <LoadingOnPage />
      )}
    </>
  )
};
