import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import { DefaultCreateButtonAndSearchFormContainer } from "../../components/DefaultCreateButtonAndSearchFormContainer";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import { getAllUsers, updateUserPermission } from "../../services/users";
import { AuthorsContainer } from "./style";
import Switch from "react-switch";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import { BiEdit, BiTrash } from "react-icons/bi";
import getErrorMessage from "../../helpers/get-error-message";
import {
  removeUser as removeUserService,
  toogleActiveUser as toogleActiveUserService,
} from "../../services/users";

const Users: React.FC = () => {
  const numberOfUserPerPage = 20;

  const [users, setUsers] = useState([] as any[]);
  const [userName, setUserName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [totalUsers, setTotalUsers] = useState<number>();
  const [actualPage, setActualPage] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const history = useHistory();

  const createUser = () => {
    history.push("/create-user");
  };

  const handleRefreshPage = useMemo(() => {
    if (refreshPage) {
      return true;
    }
     return false
  },[refreshPage]);

  const getUsers = async (page: number, event?: React.FormEvent) => {
    setIsLoading(true);
    
    try {
      if (event && event.preventDefault) {
        event.preventDefault();
        page = 0;
        setRefreshPage(true);
      } else {
        setRefreshPage(false);
      }

      const users = await getAllUsers({ 
        name: userName, 
        limit:  numberOfUserPerPage,
        offset: (page) * numberOfUserPerPage + 1,
      });
      
      const newUsers = [...users?.users || []];
      
      setUsers(newUsers);
      setTotalUsers(users?.total);
      setActualPage(page);

      setIsLoading(false);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao buscar os usuários",
        text: errorMessage,
      });
    }

    setIsLoading(false);
  };

  const editUser = (userId: string) => {
    history.push(`/edit-user/${userId}`);
  };

  const toogleActiveUser = async (userId: string, active: boolean) => {
    const result = await Swal.fire({
      icon: "question",
      text: `Tem certeza que deseja ${
        active ? "ativar" : "inativar"
      } este usuário?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });
    if (result.isConfirmed) {
      try {
        await toogleActiveUserService(userId);

        Swal.fire({
          icon: "success",
          title: `Usuário ${active ? "ativado" : "inativado"} com sucesso!`,
        });

        await getUsers(actualPage);
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: `Erro ao ${active ? "ativar" : "inativar"} usuário`,
          text: errorMessage,
        });
      }
    }
  };

  const updatePermission = async (userId: string) => {
    const result = await Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja mudar a permisão deste usuário?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        const updatedUser = await updateUserPermission(userId);
        const newUsers = [...users];
        const userIndex = newUsers.findIndex(user => user.user_id === userId);

        newUsers[userIndex].is_admin = updatedUser.is_admin;
        setUsers(newUsers);
        
        Swal.fire({ icon: "success", title: "Permissão alterada com sucesso!" });
      } catch (err) {
        Swal.fire({
          title: "Erro",
          text: "Não foi alterada a permissão do usuário.",
          icon: "error",
        });
      }
    }
  };

  const usersToBeShown = useMemo(() => {
    return users && users.length
      ? users.map((user) => ({
          id: user.user_id,
          name: user.name,
          registration: user.registration,
          company: user.company?.name,
          construction: user.construction?.name,
          department: user.departament?.name,
          active: !!(user.info && user.info.is_active) ? (
            <AiOutlineCheck size={16} />
          ) : (
            <AiOutlineClose size={16} />
          ),
          is_admin: (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                gap: "5px",
              }}
              title={user.is_admin ? "Remover permissões" : "Tornar admin"}
            >
              <Switch
                onChange={(checked) => updatePermission(user.user_id)}
                checked={user.is_admin}
              />
            </div>
          ),
          actions: (
            <>
              <DefaultButton
                title="Editar Usuário"
                type="button"
                onClick={() => editUser(user.user_id)}
                className="small info"
              >
                <BiEdit size={16} />
              </DefaultButton>{" "}
              {user.info && !user.info.is_rubi && (
                <DefaultButton
                  title="Ativar/Inativar Usuário"
                  type="button"
                  className="small warning"
                  onClick={() =>
                    toogleActiveUser(
                      user.user_id,
                      !user.info || !user.info.is_active
                    )
                  }
                >
                  {user.info.is_active ? (
                    <AiOutlineClose size={16} />
                  ) : (
                    <AiOutlineCheck size={16} />
                  )}
                </DefaultButton>
              )}{" "}
            </>
          ),
        }))
      : [];
  }, [users, updatePermission]);

  useEffect(() => {
    if (!userName) {
      getUsers(actualPage);
    }
  }, [userName]);

  return (
    <AuthorsContainer>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Usuários</span>]}
      />

      <DefaultPageTitle>Usuários</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={createUser}>Criar Usuário</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={(e) => getUsers(actualPage, e)}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Pesquise por nome aqui..."
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Matrícula</span>,
            propName: "registration",
          },
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Empresa</span>,
            propName: "company",
          },
          {
            headerLabel: <span>Departamento</span>,
            propName: "department",
          },
          {
            headerLabel: <span>Obra</span>,
            propName: "construction",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
            attributes: { style: { textAlign: "center" } },
          },
          {
            headerLabel: <span>Admin</span>,
            propName: "is_admin",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={usersToBeShown}
        totalItems={totalUsers}
        itemsPerPage={numberOfUserPerPage}
        emptyListMessage={"Não foram encontrados usuários cadastrados!"}
        isLoading={isLoading}
        handleChangePage={(page) => getUsers(page)}
        refreshPagination={handleRefreshPage}
      />
    </AuthorsContainer>
  );
};

export default Users;
