import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import type { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { login as signInService } from '../services/login';

import type User  from './../models/user';
import type ISignInData from '../models/signInData';


interface UserToken extends User {
  api_token: string;
  user: User;
}

type AuthContext = {
  user: UserToken | undefined;
  login: (data: ISignInData) => Promise<void>;
  logout: () => void;
};

type ProviderProps = {
  children: ReactNode;
};

export const Context = createContext<AuthContext>({} as AuthContext);

export default function AuthProvider({ children }: ProviderProps) {
  const [ user, setUser ] = useState<UserToken | undefined>(() => {
    const token = localStorage.getItem('leve-educacao-user');
    return token ? JSON.parse(token) : undefined;
  });

  const history = useHistory();

  const login = useCallback(async (data: ISignInData) => {
    try {
      const { registration, password, company } = data;
      const user = await signInService(registration, password, company);

      setUser(user);
      localStorage.setItem('leve-educacao-token', user.api_token);
      localStorage.setItem('leve-educacao-user', JSON.stringify(user.user));
      history.push('/profile');
    } catch (error) {
      Swal.fire({
        title: 'Erro de efetuar login.',
        text: 'A matrícula e a senha estão incorretas ou não existem.',
        icon: 'error',
        confirmButtonColor: '#1d2680',
      });
    }
  }, [history]);

  const logout = useCallback(() => {
    setUser(undefined);
    localStorage.removeItem('leve-educacao-api-token');
    localStorage.removeItem('leve-educacao-user');
    history.push('/');
  }, [history]);

  const value = useMemo(() => ({ user, login, logout }), [user, login, logout]);

  return (
    <Context.Provider  value={value}>
      {children}
    </Context.Provider>
  );
}

export function useAuth() {
  const { user, login, logout } = useContext(Context);
  return { user, login, logout };
}

