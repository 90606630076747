import httpClient from "../http-client";
interface EventProps {
  name: string;
  description: string;
  startDate: string;
  stopDate: string;
}

const createEvent = async ({
  name,
  description,
  startDate,
  stopDate
}: EventProps) => {
  return (await httpClient.post('/events', {
    name,
    description,
    start_date: startDate,
    stop_date: stopDate
  }));
}

const editEvent = async (eventId: string, {
  name,
  description,
  startDate,
  stopDate,
}: EventProps) => {
  return (await httpClient.put(`/events/${eventId}`, {
    name,
    description,
    start_date: startDate,
    stop_date: stopDate
  }));
}

const  listAllEvents = async (filters?: any) => {
  if (filters) {
    for (let param of Object.keys(filters)) {
      if (!filters[param]) {
        delete filters[param];
      }
    }
  }
  return (await httpClient.get('/events', { params: filters })).data
}

const getEvent = async (eventId: string) => {
  return (await httpClient.get(`/events/${eventId}`)).data;
}

const activateOrInactiveEvent = async (eventId: string) => {
  return (await httpClient.put(`/events/${eventId}/toggle-status`));
};

const deleteEvent = async (eventsId: string) => {
  await httpClient.delete(`/events/${eventsId}`);
};

export { 
  createEvent, 
  editEvent, 
  listAllEvents, 
  getEvent, 
  activateOrInactiveEvent, 
  deleteEvent 
};