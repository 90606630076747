import styled from "styled-components";

export const InputDate = styled.input`
  border-radius: 5px;
  border: solid 1px var(--default-dark-gray);
  outline: none;
  font-size: var(--font-regular);
  width: 100%;
  height: 44px;
  padding: 0 10px;
  color: var(--dark-gray);
  background-color: var(--light-gray);
  margin-top: 8px;
`

export const WrapperDate = styled.div`
  display: flex;
  width: 100%;
  gap: 2rem;
  margin: 1.5rem 0 !important;

  div {
    margin-top: 0;
  }
`