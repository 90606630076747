import React, { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultButton from '../../components/DefaultButton';
import DefaultInput from '../../components/DefaultInput';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import checkEmptyString from '../../helpers/check-empty-string';
import checkLettersField from '../../helpers/check-letters-field';
import checkDateFormat from '../../helpers/check-date-format';
import checkPhoneNumber from '../../helpers/check-phone-number';
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from '../../components/DefaultCreationForm';
import { CreateAndEditContentContainer } from '../CreateAndEditUsers/style';
import Swal from 'sweetalert2';
import getErrorMessage from '../../helpers/get-error-message';
import cep from 'cep-promise';
import VMasker from 'vanilla-masker';
import {
  createUser as createUserService,
  updateUser as updateUserService,
  getOneUser as getUserService,
} from '../../services/users';
import { getAllCategories } from '../../services/categories';
import Select from 'react-select';
import checkMailField from '../../helpers/check-mail-field';
import { LoadingOnPage } from '../../components/LoadingOnPage';
interface CreateAndEditUsersParams {
  userId: string;
  userName: string;
  userMail: string;
}

const CreateAndEditUsers: React.FC = () => {
  const { userId } = useParams<CreateAndEditUsersParams>();
  const history = useHistory();
  const [name, setName] = useState('');
  const [cpf, setCpf] = useState('');
  const [nascimento, setNascimento] = useState('');
  const [naturalidade, setNaturalidade] = useState('');
  const [civil, setCivil] = useState('');
  const [mail, setMail] = useState('');
  const [tel, setTel] = useState('');
  const [adress, setAdress] = useState('');
  const [adressNumber, setAdressNumber] = useState('');
  const [adressComp, setAdressComp] = useState('');
  const [adressDist, setAdressDist] = useState('');
  const [adressCity, setAdressCity] = useState('');
  const [adressZip, setAdressZip] = useState('');
  const [adressState, setAdressState] = useState('');
  const [scholarLevel, setScholarLevel] = useState('');
  const [occupation, setOccupation] = useState('');
  const [role, setRole] = useState('');
  const [company, setCompany] = useState('');
  const [construction, setConstruction] = useState('');
  const [department, setDepartment] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpassword, setConfirmPassword] = useState('');
  const [categories, setCategories] = useState<
    { label: string; value: string }[] | undefined
  >();
  const [selectedCategories, setSelectedCategories] = useState<
    { label: string; value: string }[] | undefined
  >();
  const [shouldShowPassword, setShouldShowPassword] = useState(true);
  const [firstLogin, setFirstLogin] = useState(true);
  const [isRubi, setIsRubi] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const goToUsers = () => {
    history.push('/users');
  };

  const isEditting = useMemo(() => {
    if (userId) {
      return true;
    }

    setIsLoading(false);
    return false;
  }, [userId]);

  const getUser = async () => {
    try {
      const user = await getUserService(userId);
      if (user) {
        setFirstLogin(user.info.first_login);
        setName(user.name);
        setCpf(user.cpf);
        setMail(user.email);
        setOccupation(user.occupation);
        setTel(user.phone);
        setNascimento(user.birth_date);
        setRole(user.role ? user.role.name : '');
        setCompany(user.company ? user.company.name : '');
        setConstruction(user.construction ? user.construction.name : '');
        setDepartment(user.departament ? user.departament.name : '')

        if (user.info) {
          setCivil(user.info.civil_status);
          setScholarLevel(user.info.schooling);
          setNaturalidade(user.info.place_of_birth);

          const localSelectedCategories = (categories || []).filter((cat) =>
            ((user.info || {}).interests || [])
              .map((int: any) => int.category_id)
              .includes(cat.value)
          );
          setSelectedCategories(localSelectedCategories);
          setIsRubi(user.info.is_rubi);
        }

        if (user.address) {
          setAdressCity(user.address.city);
          setAdressComp(user.address.complement);
          setAdressDist(user.address.district);
          setAdressNumber(user.address.number);
          setAdressState(user.address.state);
          setAdress(user.address.street);
          setAdressZip(user.address.zip_code);
        }
      }

      setIsLoading(false);
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar o usuário',
        text: errorMessage,
      });
    }

    setIsLoading(false);
  };

  const validate = () => {
    if (checkMailField(mail) && mail !== '') {
      throw new Error('O campo E-mail deve possuir um e-mail válido!');
    }
    if (checkEmptyString(name)) {
      throw new Error('Informe o campo Nome do usuário!');
    }

    if (checkLettersField(name)) {
      throw new Error('O campo Nome deve conter apenas letras!');
    }

    if (checkDateFormat(nascimento)) {
      throw new Error(
        'O campo Data de Nascimento foi preenchido incorretamente!'
      );
    }

    if (checkPhoneNumber(tel)) {
      throw new Error('O campo Telefone foi preenchido incorretamente!');
    }

    if (!isEditting) {
      if (!password) {
        throw new Error('Informe a senha do usuário!');
      }

      if (!confirmpassword) {
        throw new Error('Informe a confirmação da senha do usuário!');
      }

      if (confirmpassword !== password) {
        throw new Error(
          'A confirmação da senha do usuário está diferente da senha informada!'
        );
      }
    }
  };

  const createUser = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      const newUser = {
        name: name,
        email: mail || undefined,
        password: password,
        cpf: (cpf || '').replaceAll('.', '').replaceAll('-', '') || undefined,
        birth_date: nascimento || undefined,
        phone: tel || undefined,
        occupation: occupation || undefined,
        roles: role ? [role] : undefined,
        address: {
          street: adress || undefined,
          number: adressNumber || undefined,
          district: adressDist || undefined,
          city: adressCity || undefined,
          state: adressState || undefined,
          zip_code: adressZip || undefined,
          complement: adressComp || undefined,
        },
        info: {
          first_login: true,
          civil_status: civil || undefined,
          schooling: scholarLevel || undefined,
          place_of_birth: naturalidade || undefined,
          interests:
            selectedCategories && selectedCategories.length
              ? selectedCategories.map((cat) => ({ category_id: cat.value }))
              : undefined,
        },
      };

      await createUserService(newUser);

      Swal.fire({ icon: 'success', title: 'Usuário criado com sucesso!' });

      goToUsers();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao criar usuário',
        text: errorMessage,
      });
    }
  };

  const updateUser = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      const newUser = {
        name: name,
        email: mail || undefined,
        password: password || undefined,
        cpf: (cpf || '').replaceAll('.', '').replaceAll('-', '') || undefined,
        birth_date: nascimento || undefined,
        phone: tel || undefined,
        occupation: occupation || undefined,
        roles: role ? [role] : undefined,
        address: {
          street: adress || undefined,
          number: adressNumber || undefined,
          district: adressDist || undefined,
          city: adressCity || undefined,
          state: adressState || undefined,
          zip_code: adressZip || undefined,
          complement: adressComp || undefined,
        },
        info: {
          first_login: password === '' ? firstLogin : true,
          civil_status: civil || undefined,
          schooling: scholarLevel || undefined,
          place_of_birth: naturalidade || undefined,
          interests:
            selectedCategories && selectedCategories.length
              ? selectedCategories.map((cat) => ({ category_id: cat.value }))
              : undefined,
        },
      };

      await updateUserService(userId, newUser);

      Swal.fire({ icon: 'success', title: 'Usuário editado com sucesso!' });

      goToUsers();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao criar usuário',
        text: errorMessage,
      });
    }
  };

  const getAddress = async () => {
    try {
      const adress = await cep(adressZip);
      setAdress(adress.street);
      setAdressState(adress.state);
      setAdressDist(adress.neighborhood);
      setAdressCity(adress.city);
      setAdressState(adress.state);
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao buscar endereço via CEP',
        text: errorMessage,
      });
    }
  };

  useEffect(() => {
    (async () => {
      const localCategories = await getAllCategories();
      if (localCategories) {
        setCategories(
          localCategories.map((cat) => ({
            value: cat.category_id,
            label: cat.title,
          }))
        );
      }
    })();
  }, []);

  useEffect(() => {
    if (isEditting && categories) {
      getUser();
    }
  }, [userId, categories]);

  useEffect(() => {
    if (isEditting) {
      setShouldShowPassword(false);
    }
  }, [userId]);

  return (
    <CreateAndEditContentContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <Link to='/users'>Usuários</Link>,
          <span>{isEditting ? 'Editar' : 'Criar'} Usuário</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? 'Editar' : 'Criar'} Usuário
      </DefaultPageTitle>

      {!isLoading ? (
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className='required' htmlFor='name'>
            Nome
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id='name'
            type='text'
            required
            disabled={isRubi}
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='cpf'>CPF</label>
          <DefaultInput
            value={cpf}
            onChange={(e) =>
              setCpf(VMasker.toPattern(e.target.value, '999.999.999-99'))
            }
            id='cpf'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='nascimento'>Data de Nascimento</label>
          <DefaultInput
            value={nascimento}
            onChange={(e) => setNascimento(e.target.value)}
            id='birth'
            type='date'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='naturalidade'>Naturalidade</label>
          <DefaultInput
            value={naturalidade}
            onChange={(e) => setNaturalidade(e.target.value)}
            id='birthplace'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='civil'>Estado Civil</label>
          <DefaultInput
            value={civil}
            onChange={(e) => setCivil(e.target.value)}
            id='civil'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='mail'>E-mail</label>
          <DefaultInput
            value={mail}
            onChange={(e) => setMail(e.target.value)}
            id='mail'
            type='email'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='tel'>Telefone</label>
          <DefaultInput
            value={tel}
            onChange={(e) =>
              setTel(VMasker.toPattern(e.target.value, '(99) 99999-9999'))
            }
            id='tel'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adressZip'>CEP</label>
          <DefaultInput
            value={adressZip}
            onChange={(e) =>
              setAdressZip(VMasker.toPattern(e.target.value, '99999-999'))
            }
            onBlur={
              adressZip === '' || adressZip === undefined
                ? undefined
                : getAddress
            }
            id='adress-zip'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adress'>Endereço</label>
          <DefaultInput
            value={adress}
            onChange={(e) => setAdress(e.target.value)}
            id='adress'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adressNumber'>Número</label>
          <DefaultInput
            value={adressNumber}
            onChange={(e) => setAdressNumber(e.target.value)}
            id='adress-number'
            type='number'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adressComp'>Complemento</label>
          <DefaultInput
            value={adressComp}
            onChange={(e) => setAdressComp(e.target.value)}
            id='adress-comp'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adressDist'>Bairro</label>
          <DefaultInput
            value={adressDist}
            onChange={(e) => setAdressDist(e.target.value)}
            id='adress-dist'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adressCity'>Cidade</label>
          <DefaultInput
            value={adressCity}
            onChange={(e) => setAdressCity(e.target.value)}
            id='adress-city'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='adressState'>Estado</label>
          <DefaultInput
            value={adressState}
            onChange={(e) => setAdressState(e.target.value)}
            id='adress-state'
            type='text'
          />
        </DefaultCreationFormGroup>
        <DefaultCreationFormGroup>
          <label htmlFor='scholarLevel'>Nível escolar</label>
          <DefaultInput
            value={scholarLevel}
            onChange={(e) => setScholarLevel(e.target.value)}
            id='scholar'
            type='text'
          />
        </DefaultCreationFormGroup>
        {isRubi ? (
          <>
          <DefaultCreationFormGroup>
            <label htmlFor='company'>Empresa Atual</label>
            <DefaultInput
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              id='company'
              type='text'
              disabled={isRubi}
            />
          </DefaultCreationFormGroup>
          <DefaultCreationFormGroup>
            <label htmlFor='role'>Cargo</label>
            <DefaultInput
              value={role}
              onChange={(e) => setRole(e.target.value)}
              id='role'
              type='text'
              disabled={isRubi}
            />
          </DefaultCreationFormGroup>
          <DefaultCreationFormGroup>
            <label htmlFor='role'>Obra</label>
            <DefaultInput
              value={construction}
              onChange={(e) => setConstruction(e.target.value)}
              id='role'
              type='text'
              disabled={isRubi}
            />
          </DefaultCreationFormGroup>
          <DefaultCreationFormGroup>
            <label htmlFor='department'>Departamento</label>
            <DefaultInput
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              id='department'
              type='text'
              disabled={isRubi}
            />
          </DefaultCreationFormGroup>
        </>
        ) : (
          <DefaultCreationFormGroup>
            <label htmlFor='occupation'>Emprego/Ocupação</label>
            <DefaultInput
              value={occupation}
              onChange={(e) => setOccupation(e.target.value)}
              id='occupation'
              type='text'
            />
          </DefaultCreationFormGroup>
        )}
        <DefaultCreationFormGroup>
          <label htmlFor='categories'>Interesses</label>
          <Select
            styles={{
              container: (provided) => ({
                ...provided,
                flexGrow: 1,
                width: '100%',
              }),
            }}
            options={categories}
            value={selectedCategories}
            isMulti
            onChange={(options) => {
              options &&
                !isNaN(options.length) &&
                setSelectedCategories([...options]);
            }}
            id='categories'
            placeholder='Selecione um ou mais interesses para este usuário.'
          ></Select>
        </DefaultCreationFormGroup>
        {shouldShowPassword ? (
          <>
            <DefaultCreationFormGroup>
              <label className='required' htmlFor='password'>
                Senha
              </label>
              <DefaultInput
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                {...{ autocomplete: 'off' }}
                id='password'
                type='password'
                required
              />
            </DefaultCreationFormGroup>
            <DefaultCreationFormGroup>
              <label className='required' htmlFor='confirmpassword'>
                Confirmar Senha
              </label>
              <DefaultInput
                value={confirmpassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                id='confirm-password'
                {...{ autocomplete: 'off' }}
                type='password'
                required
              />
            </DefaultCreationFormGroup>
          </>
        ) : (
          <DefaultButton
            onClick={() => setShouldShowPassword(true)}
            style={{ margin: '0 auto' }}
          >
            Mudar senha
          </DefaultButton>
        )}
        <DefaultCreationFormButtonGroup>
          <DefaultButton type='button' className='danger' onClick={goToUsers}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateUser(e) : createUser(e))}
            className='success'
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
      ): (
        <LoadingOnPage />
      )}
    </CreateAndEditContentContainer>
  );
};

export default CreateAndEditUsers;
