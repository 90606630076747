import styled from 'styled-components';

const DefaultCreateButtonAndSearchFormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 400px;
  margin: 10px 0;

  form {
    button {
      width: 50px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    input {
      width: 320px;
    }
  }
`;

export { DefaultCreateButtonAndSearchFormContainer };
