import httpClient from "../http-client";

interface UrlSignedResponse {
  uploadURL: string;
  Key: string;
}

const getSignedUrl = async (contentType: string, filePath: string) => {
  return (
    await httpClient.post<UrlSignedResponse>("/files/url-signed", {
      content_type: contentType,
      path: `scorm/leve-educacao/${filePath}`,
    })
  ).data;
};

export { getSignedUrl };
