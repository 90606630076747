import React, { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import TextareaReactQuill from "../../components/TextareaReactQuill/TextareaReactQuill";
import getErrorMessage from "../../helpers/get-error-message";
import {
  createTrail as createTrailService,
  updateTrail as updateTrailService,
  getTrail as getTrailService,
} from "../../services/trail-category";

const CreateAndEditTrailContainer = styled.div``;

interface CreateAndEditTrailParams {
  trailId: string;
}

const CreateAndEditTrail: React.FC = () => {
  const history = useHistory();
  const { trailId } = useParams<CreateAndEditTrailParams>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const gotoTrails = () => {
    history.push(`/trails`);
  };

  const getTrail = async () => {
    const localTrail = await getTrailService(trailId);
    setTitle(localTrail.title);
    setDescription(localTrail.description);
  };

  const validate = () => {
    if (!title) {
      throw new Error("Informe um título para a trilha!");
    }
  };

  const createTrail = async (event: React.MouseEvent) => {
    try {
      validate();

      await createTrailService({
        title,
        description: description || undefined,
      });

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Trilha cadastrada com sucesso!",
      });

      gotoTrails();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao criar trilha",
        text: errorMessage,
      });
    }
  };

  const updateTrail = async (event: React.MouseEvent) => {
    try {
      validate();

      await updateTrailService(trailId, {
        title,
        description: description || undefined,
      });

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: "Trilha atualizada com sucesso!",
      });

      gotoTrails();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao criar trilha",
        text: errorMessage,
      });
    }
  };

  const isEditting = useMemo(() => {
    return !!trailId;
  }, [trailId]);

  useEffect(() => {
    if (isEditting) {
      getTrail();
    }
  }, [trailId]);

  return (
    <CreateAndEditTrailContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <span>Trilhas</span>,
          <span>{isEditting ? "Editar" : "Criar"} Trilha</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Trilha
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>

          <DefaultInput
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <TextareaReactQuill state={description} setState={setDescription} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={gotoTrails}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            type="button"
            onClick={(e) => (isEditting ? updateTrail(e) : createTrail(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditTrailContainer>
  );
};

export default CreateAndEditTrail;
