import React, { useCallback, useEffect, useMemo, useState } from "react";
import Swal from "sweetalert2";
import Select, { StylesConfig } from 'react-select';
import DefaultButton from "../../components/DefaultButton";
import DefaultInput from "../../components/DefaultInput";
import LogoLeve from "../../assets/logo-leve.svg";
import { useAuth } from "../../context/AuthContext";

import { SelectProps } from "../../models/select";
import { listCompany as listCompanyService } from '../../services/segmentation';
import { LoginContainer, LoginForm, LoginFormGroup } from "./style";

const Login: React.FC = () => {
  const [password, setPassword] = useState("");
  const [user, setUser] = useState('');
  const [selectCompany, setSelectCompany] = useState<SelectProps>();
  const [listCompany, setListCompany] = useState<SelectProps[]>();
  const [isLoading, setIsLoading] = useState(false);
  const { login } = useAuth();

  const handleShowCompanies = useMemo(() => {
    const validateEmail = /\S+@\S+\.\S+/;
    const validateHasLetter = /[a-zA-Z]/;
    
    return (validateEmail.test(user) || validateHasLetter.test(user))
  },[user]);

  const loginForm = async (event: React.FormEvent) => {
    event.preventDefault();
    const data = { registration: user, password, company: selectCompany?.value };

    if(!data.registration) {
      return Swal.fire({
        title: 'Erro ao efetuar login.',
        text: 'Informe uma matricula válida.',
        icon: 'error',
        confirmButtonColor: '#1d2680',
      });
    }

    if(!data.password) {
      return Swal.fire({
        title: 'Erro ao efetuar login.',
        text: 'Informe uma senha válida.',
        icon: 'error',
        confirmButtonColor: '#1d2680',
      });
    }

    if(!handleShowCompanies && !data.company) {
      return Swal.fire({
        title: 'Erro ao efetuar login.',
        text: 'Informe uma empresa válida.',
        icon: 'error',
        confirmButtonColor: '#1d2680',
      });
    }
    
    return login(data);
  }

  const getAllCompanies = useCallback(async () => {
    setIsLoading(true);

    const allCompanies = await listCompanyService();
    setListCompany(allCompanies);
    setIsLoading(false);
  },[]);

  useEffect(() => {
    getAllCompanies()
  }, [getAllCompanies]);

  const dropdownStyles: StylesConfig<any, false> = {
    container: (style) => ({
      ...style,
      width: '100%',
      marginTop: '8px',
    }),
    control: (style) => ({
      ...style,
      borderColor: 'var(--default-dark-gray)',
      borderRadius: '6px',
      height: '44px'
    }),
    valueContainer: (style) => ({
      ...style,
      padding: '10px'
    }),
  }

  return (
    <LoginContainer className="content">
      <LoginForm onSubmit={loginForm}>
        <img src={LogoLeve} alt="Portal construir" />
        <h2>Olá, seja bem vindo!</h2>
        <LoginFormGroup>
          <label htmlFor="login">Login</label>
          <DefaultInput
            value={user}
            onChange={(e) => setUser(e.target.value)}
            required
            id="login"            
          />
        </LoginFormGroup>
        <LoginFormGroup>
          <label htmlFor="senha">Senha</label>
          <DefaultInput
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            type="password"
            id="senha"            
          />
        </LoginFormGroup>
        {!handleShowCompanies && (
          <LoginFormGroup>
            <label htmlFor="company">Empresa</label>
            <Select 
              options={listCompany}
              placeholder='Selecione uma empresa'
              noOptionsMessage={() => 'Não foi encontrado nenhuma empresa'}
              isLoading={isLoading}
              loadingMessage={() => 'Carregando...'}
              value={selectCompany}
              onChange={(option) => {
                if(option)
                setSelectCompany(option);
              }}
              styles={dropdownStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: 'var(--default-dark-gray)',
                  primary: 'var(--default-dark-gray)',
                },
              })}
            />
          </LoginFormGroup>
        )}

        <DefaultButton type="submit">Login</DefaultButton>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
