import { createContext, useContext, useMemo, useState } from "react";
import type { ReactNode } from 'react';
import { SelectProps } from '../models/select';

interface SegmentationTableProps {
  label: string;
  value: string;
  checked: boolean;
}

type ProviderProps = {
  children: ReactNode;
};

const segmentationTypes = [
  {
    label: 'Empresa',
    value: 'company',
  },
  {
    label: 'Departamento',
    value: 'department',
  },
  {
    label: 'Obra',
    value: 'constructions'
  }
];

type SegmentationContext = {
  segmentationTypes: SelectProps[],
  selectedSegmentationType: SelectProps,
  setSelectedSegmentationType: (SelectProps: SelectProps) => void,
  segmentationTypeSelected: string,
  segmentationProps: {
    company: {
      name: string,
      tableData: SegmentationTableProps[],
      setTableData: (segmentationTableProps: SegmentationTableProps[]) => void,
    },
    department: {
      name: string,
      tableData: SegmentationTableProps[],
      setTableData: (segmentationTableProps: SegmentationTableProps[]) => void,
    },
    constructions: {
      name: string,
      tableData: SegmentationTableProps[],
      setTableData: (segmentationTableProps: SegmentationTableProps[]) => void,
    }
  }
}

export const Context = createContext<SegmentationContext>({} as SegmentationContext);

export default function SegmentationProvider({ children }: ProviderProps) {
  const [selectedSegmentationType, setSelectedSegmentationType] = useState<
    SelectProps
  >({ label: 'Empresa', value: 'company' });
  const [segmentationTableCompany, setSegmentationTableCompany] = useState<
    SegmentationTableProps[]
  >([]);
  const [segmentationTableDepartment, setSegmentationTableDepartment] = useState<
    SegmentationTableProps[]
  >([]);
  const [segmentationTableConstructions, setSegmentationTableConstructions] = useState<
    SegmentationTableProps[]
  >([]);

  const segmentationProps = useMemo(() => {
    return {
      company: {
        name: 'Empresa',
        tableData: segmentationTableCompany,
        setTableData: setSegmentationTableCompany,
      },
      department: {
        name: 'Departamento',
        tableData: segmentationTableDepartment,
        setTableData: setSegmentationTableDepartment,
      },
      constructions: {
        name: 'Obra',
        tableData: segmentationTableConstructions,
        setTableData: setSegmentationTableConstructions,
      }
    }
  },[
    segmentationTableCompany,
    segmentationTableDepartment,
    segmentationTableConstructions
  ]);

  const segmentationTypeSelected = useMemo(() => {
    switch (selectedSegmentationType.value) {
      case 'company': 
        return 'company';
      case 'department': 
        return 'department';
      case 'constructions': 
        return 'constructions';
      default:
        return 'company'
    }
  }, [selectedSegmentationType]);

  const value = useMemo(() => ({ 
    segmentationTypes,
    selectedSegmentationType,
    setSelectedSegmentationType,
    segmentationTypeSelected,
    segmentationProps
  }), [
    segmentationProps, 
    selectedSegmentationType,
    segmentationTypeSelected,
    setSelectedSegmentationType
  ]);

  return (
    <Context.Provider value={value}>
      {children}
    </Context.Provider>
  )
}

export function useSegmentation() {
  const { 
    segmentationTypes, 
    selectedSegmentationType,
    setSelectedSegmentationType,
    segmentationTypeSelected,
    segmentationProps,
  } = useContext(Context);

  return { 
    segmentationTypes,
    selectedSegmentationType,
    setSelectedSegmentationType,
    segmentationTypeSelected,
    segmentationProps,
  };
}