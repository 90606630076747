import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import {
  getFaq as getFaqService,
  updateFaq as updateFaqService,
  createFaq as createFaqService,
} from "../../services/faq";
import { Container } from "./style";

import checkEmptyString from "../../helpers/check-empty-string";
import getErrorMessage from "../../helpers/get-error-message";

interface CreateAndEditFAQProps {
  faqId: string;
}

const CreateAndEditFAQ: React.FC = () => {
  const { faqId } = useParams<CreateAndEditFAQProps>();

  const history = useHistory();

  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");

  const createFaq = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(question)) {
        throw new Error("Informe uma pergunta válida.");
      }

      if (checkEmptyString(answer)) {
        throw new Error("Informe uma resposta válida.");
      }

      await createFaqService(question, answer);

      Swal.fire({
        title: "Sucesso!",
        text: "FAQ criado com sucesso!",
        icon: "success",
      });

      goToFaq();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const updateFaq = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      if (checkEmptyString(question)) {
        throw new Error("Informe uma pergunta válida.");
      }

      if (checkEmptyString(answer)) {
        throw new Error("Informe uma resposta válida.");
      }

      await updateFaqService(faqId, question, answer);

      Swal.fire({
        title: "Sucesso!",
        text: "FAQ editado com sucesso!",
        icon: "success",
      });

      goToFaq();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const goToFaq = () => {
    history.push("/faqs");
  };

  const getFaq = useCallback(async () => {
    if (faqId) {
      const faq = await getFaqService(faqId);
      if (faq && Object.keys(faq).length) {
        setQuestion(faq.question);
        setAnswer(faq.answer);
      }
    }
  }, [faqId]);

  useEffect(() => {
    getFaq();
  }, [getFaq]);

  const isEditting = useMemo(() => {
    if (faqId) return true;

    return false;
  }, [faqId]);

  return (
    <Container>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/faqs">FAQs</Link>,
          <span>{isEditting ? "Editar" : "Criar"} FAQ</span>,
        ]}
      />

      <DefaultPageTitle>{isEditting ? "Editar" : "Criar"} FAQ</DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="question">
            Pergunta
          </label>
          <DefaultInput
            value={question}
            onChange={(e) => setQuestion(e.target.value)}
            id="question"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="answer">
            Resposta
          </label>
          <DefaultTextArea
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            id="answer"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToFaq}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateFaq(e) : createFaq(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </Container>
  );
};

export default CreateAndEditFAQ;
