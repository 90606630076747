import styled from 'styled-components';

const CreateAndEditExamContainer = styled.div``;

const QuestionsList = styled.div`
  & > * {
    border-bottom: solid 2px var(--default-dark-gray);
    padding: 10px 0;
  }

  margin-top: 10px;
  border: solid 1px var(--default-dark-gray);
  padding: 10px;
  border-radius: 5px;
`;


export { CreateAndEditExamContainer, QuestionsList };

