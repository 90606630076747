import React, {
  useCallback,
  useEffect,
  useMemo, useState
} from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { Link, useHistory, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import checkEmptyString from "../../helpers/check-empty-string";
import getErrorMessage from "../../helpers/get-error-message";
import { hideModal, showModal } from "../../helpers/modal";
import Question from "../../models/question";
import {
  createExam as createExamService, getExam as getExamService, updateExam as updateExamService
} from "../../services/exams";
import SelectQuestions from "./components/SelectQuestions";
import { CreateAndEditExamContainer, QuestionsList } from "./style";

interface CreateAndEditExamParams {
  examId: string;
}

const CreateAndEditExam: React.FC = () => {
  const { examId } = useParams<CreateAndEditExamParams>();
  const history = useHistory();
  const [title, setTitle] = useState("");
  const [instructions, setInstructions] = useState("");
  const [attempts, setAttepmts] = useState(0);
  const [average, setAverage] = useState(0);
  const [questionsQuantity, setQuestionsQuantity] = useState(0);
  const [questions, setQuestions] = useState([] as Question[]);


  
  const selectQuestions = () => {
    showModal(
      "Selecionar Questões",
      <SelectQuestions
        selectedQuestions={[...questions]}
        onApplySelection={changeSelectedQuestions}
      />
    );
  };

  const changeSelectedQuestions = (questions: Question[]) => {
    setQuestions([...questions]);
    hideModal();
  };

  const goToExams = () => {
    history.push("/exams");
  };

  const getExam = useCallback(async () => {
    const localExam = await getExamService(examId);
    if (localExam && Object.keys(localExam).length) {
      setTitle(localExam.title);
      setInstructions(localExam.instructions);
      setQuestions(localExam.questions);
      setAttepmts(localExam.attempts);
      setAverage(localExam.average);
      setQuestionsQuantity(localExam.amount);
    }

  }, [examId]);

  const validate = () => {
    if (checkEmptyString(title)) {
      throw new Error("Informe um título válido para a prova!");
    }

    if (checkEmptyString(instructions)) {
      throw new Error("Informe instruções válidas para a prova!");
    }

    if (!questions || questions.length < 1) {
      throw new Error("Informe ao menos uma questão!");
    }

    if (attempts <= 0) {
      throw new Error("O número de tentativas deve ser superior a 0!");
    }

    if (average <= 0) {
      throw new Error(
        "A média precisa ser maior que zero"
      );
    }

    if (questionsQuantity <= 0) {
      throw new Error(
        "Adicione ao menos uma questão para ser exibida na prova!"
      );
    }

    if (questionsQuantity > questions.length) {
      throw new Error(
        "A quantidade máxima de questões deve ser o número de questões selecionadas!"
      );
    }
  };

  const createExam = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      validate();
      const exam = {
        title: title,
        instructions: instructions,
        questions:
          questions && questions.length
            ? questions.map((q) => ({ question_id: q.id }))
            : [],  
        amount_questions: [{ points: 1, amount: questionsQuantity }],
        attempts: attempts,
        average: average,
      }
      
      await createExamService(exam);

      Swal.fire({
        title: "Sucesso",
        text: "Prova cadastrada com sucesso!",
        icon: "success",
      });

      goToExams();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const updateExam = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      validate();
      const exam = {
        title: title,
        instructions: instructions,
        questions:
          questions && questions.length
            ? questions.map((q) => ({ question_id: q.id }))
            : [],
        amount_questions: [{ points: 1, amount: questionsQuantity }],
        attempts: attempts,
        average: average,
      }
    
      await updateExamService(examId, exam);

      Swal.fire({
        title: "Sucesso",
        text: "Prova editada com sucesso!",
        icon: "success",
      });

      goToExams();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const removeQuestion = (question: Question) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja remover esta questão?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        const newQuestions = [...questions.filter((q) => q !== question)];
        setQuestions(newQuestions);

        if (newQuestions.length < questionsQuantity) {
          setQuestionsQuantity(newQuestions.length);
        }
      }
    });
  };

  const changeQuestionPosition = (question: Question, up: boolean) => {
    const indexOfQuestion = questions.indexOf(question);
    if (up) {
      const nextQuestion = questions[indexOfQuestion - 1];

      questions[indexOfQuestion] = nextQuestion;
      questions[indexOfQuestion - 1] = question;
    } else {
      const previousQuestion = questions[indexOfQuestion + 1];

      questions[indexOfQuestion] = previousQuestion;
      questions[indexOfQuestion + 1] = question;
    }

    setQuestions([...questions]);
  };

  const isEditting = useMemo(() => {
    return !!examId;
  }, [examId]);

  useEffect(() => {
    if (examId) {
      getExam();
    }
  }, [examId, getExam]);

  return (
    <CreateAndEditExamContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,

          <span>{isEditting ? "Editar" : "Criar"} Prova</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Prova
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Título
          </label>
          <DefaultInput
            id="title"
            type="text"
            required
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="instructions" className="required">
            Instruções
          </label>
          <DefaultTextArea
            id="instructions"
            required
            value={instructions}
            onChange={(e) => setInstructions(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="attempts">
            Tentativas
          </label>

          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <DefaultInput
              type="number"
              id="attempts"
              onWheel={(e: any) => e.target.blur()}
              min={1}
              value={attempts}
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  setAttepmts(parseInt(value));
                  return;
                }
                setAttepmts(0);
              }}
              required
            />
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="average">
            Média
          </label>

          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <DefaultInput
              style={{ flexGrow: 1, marginRight: "10px", padding: 0 }}
              type="range"
              id="average"
              min={0}
              max={100}
              value={average}
              onChange={(e) => setAverage(+e.target.value.replaceAll("_", ""))}
              required
            />
            <p>{average}%</p>
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Banco de questões
          </label>
          <div
            style={{
              border: "solid 1px var(--default-dark-gray)",
              borderRadius: "5px",
              width: "100%",
              padding: "10px",
            }}
          >
            <div className="center">
              <DefaultButton onClick={selectQuestions} type="button">
                Selecionar Questões
              </DefaultButton>
            </div>

            {questions && questions.length ? (
              <QuestionsList>
                {questions.map((question, index) => (
                  <div
                    key={index}
                    style={{
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ padding: "16px" }}>
                      <p
                        style={{ flexGrow: 1 }}
                        dangerouslySetInnerHTML={{
                          __html: question.enunciated,
                        }}
                      ></p>
                    </div>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      {questions[0] !== question ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={() =>
                              changeQuestionPosition(question, true)
                            }
                            className="small white up"
                          >
                            <AiOutlineUp />
                          </DefaultButton>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                      {questions[questions.length - 1] !== question ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={() =>
                              changeQuestionPosition(question, false)
                            }
                            className="small white down"
                          >
                            <AiOutlineDown />
                          </DefaultButton>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                      <DefaultButton
                        type="button"
                        onClick={() => removeQuestion(question)}
                        className="small danger"
                      >
                        <BiTrash />
                      </DefaultButton>
                    </div>
                  </div>
                ))}
              </QuestionsList>
            ) : (
              <></>
            )}
          </div>
        </DefaultCreationFormGroup>
        
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="average">
            Número de questões exibidas na prova
          </label>

          <div style={{ width: "100%", display: "flex", alignItems: "center" }}>
            <DefaultInput
              required
              type="number"
              id="average"
              onWheel={(e: any) => e.target.blur()}
              min={1}
              max={questions.length}
              value={questionsQuantity}
              disabled={!questions.length}
              onChange={(e) => {
                const value = e.target.value;
                if (value) {
                  setQuestionsQuantity(parseInt(value));
                  return;
                }
                setQuestionsQuantity(0);
              }}
            />
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToExams}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateExam(e) : createExam(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditExamContainer>
  );
};

export default CreateAndEditExam;
