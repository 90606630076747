import React, { useState } from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import {
  AiOutlineLeft,
  AiOutlineRight,
  AiOutlineSortAscending,
  AiOutlineSortDescending,
} from "react-icons/ai";
import { FaSort } from "react-icons/fa";
import DefaultButton from "../DefaultButton";
import { LoadingOnPage } from "../LoadingOnPage";
import {
  DefaultTableContainer,
  DefaultTablePagination,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "./style";

interface ExtendedWindow extends Window {
  $clamp: any;
}

declare let window: ExtendedWindow;

interface HeaderConfig {
  propName: string;
  headerLabel: React.ReactElement;
  noWrap?: boolean;
  order?: (items: any, currentOrder: string) => void;
  attributes?: React.StyleHTMLAttributes<HTMLElement>;
}

interface DefaultTableProps {
  headersConfig: HeaderConfig[];
  items: any[];
  totalItems?: number;
  emptyListMessage?: string;
  itemsPerPage?: number;
  usePagination?: boolean;
  isLoading?: boolean;
  handleChangePage?: (newPage: number) => void;
  refreshPagination?: boolean;
}

const DefaultTable: React.FC<DefaultTableProps> = ({
  headersConfig,
  items: initialItems,
  totalItems: initialTotalItems,
  emptyListMessage,
  itemsPerPage: initialItemsPerPage,
  usePagination: initialUsePagination,
  isLoading,
  handleChangePage,
  refreshPagination,
}) => {
  const [items, setItems] = useState([] as any[]);
  const [currentOrder, setCurrentOrder] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [usePagination, setUsePagination] = useState(true);
  const [totalItems, setTotalItems] = useState<number | undefined>()
  const [tableIsLoading, setTableIsLoading] = useState<boolean | undefined>(false);

  const changeCurrentPage = (newPage: number) => {
    if(handleChangePage) {
      handleChangePage(newPage);
    }
    setCurrentPage(newPage);
  };

  const removeAccentuation = (str: string) => {
    const com_acento = `ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ`;
    const sem_acento = `AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr`;
    let novastr = "";
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < com_acento.length; a++) {
        if (str.substr(i, 1) == com_acento.substr(a, 1)) {
          novastr += sem_acento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca == false) {
        novastr += str.substr(i, 1);
      }
    }
    return novastr;
  };

  const configureDefaultOrder = () => {
    for (let headerConfig of headersConfig) {
      if (!headerConfig.order) {
        const firstItem = items && items.length && items[0];
        if (firstItem) {
          const itemProp = firstItem[headerConfig.propName];
          if (
            !itemProp ||
            (itemProp &&
              (typeof itemProp === "string" || typeof itemProp === "number"))
          ) {
            headerConfig.order = (localItems: any[], currentOrder: string) => {
              setItems([
                ...items.sort((a, b) => {
                  const isDescendant =
                    currentOrder.includes(headerConfig.propName) &&
                    currentOrder
                      .replace(headerConfig.propName, "")
                      .includes("asc");

                  const treatedAProp = a[headerConfig.propName]
                    ? removeAccentuation(
                        `${a[headerConfig.propName]}`.trim().toLocaleLowerCase()
                      )
                    : "";
                  const treatedBProp = b[headerConfig.propName]
                    ? removeAccentuation(
                        `${b[headerConfig.propName]}`.trim().toLocaleLowerCase()
                      )
                    : "";

                  if (isDescendant) {
                    return treatedAProp < treatedBProp ? 1 : -1;
                  } else {
                    return treatedAProp < treatedBProp ? -1 : 1;
                  }
                }),
              ]);
            };
          }
        }
      }
    }
  };

  const itemsToShow = useMemo(() => {
    return (usePagination && !handleChangePage) 
      ? [...items].splice(currentPage * itemsPerPage, itemsPerPage)
      : items;
  }, [items, itemsPerPage, currentPage, usePagination, handleChangePage]);

  const numberOfPages = useMemo(() => {
    return Math.ceil((totalItems || items.length) / itemsPerPage);
  }, [itemsPerPage, items, totalItems]);

  useEffect(() => {
    if (initialItemsPerPage) {
      setItemsPerPage(initialItemsPerPage);
    }
  }, [initialItemsPerPage]);

  useEffect(() => {
    setItems(initialItems);
  }, [initialItems]);

  useEffect(() => {
    if (initialUsePagination !== undefined) {
      setUsePagination(initialUsePagination);
    }
  }, [initialUsePagination]);

  useEffect(() => {
    setTotalItems(initialTotalItems)
  },[initialTotalItems])

  useEffect(() => {
    setTableIsLoading(isLoading);
  },[isLoading]);

  useEffect(() => {
    if(refreshPagination) {
      setCurrentPage(0);
    }
  }, [refreshPagination])

  useEffect(() => {
    configureDefaultOrder();
  }, [headersConfig, items]);

  useEffect(() => {
    if (window.$clamp) {
      const tableTds = Array.from(document.querySelectorAll(".table-td"));
      for (let td of tableTds) {
        window.$clamp(td, { clamp: 3 });
      }
    }
  }, [currentPage, items]);

  return (
    <DefaultTableContainer>
    {!tableIsLoading ? (
      <Table>
        <Thead>
          <Tr>
            {headersConfig && headersConfig.length ? (
              headersConfig.map((headerConfig, index) => (
                <Th
                  style={{
                    cursor: headerConfig.order ? "pointer" : "normal",
                    ...headerConfig.attributes?.style,
                  }}
                  onClick={() =>
                    headerConfig.order
                      ? (() => {
                          headerConfig.order &&
                            headerConfig.order(items, currentOrder);
                          setCurrentOrder(
                            `${headerConfig.propName} ${
                              !currentOrder
                                .replace(headerConfig.propName, "")
                                .includes("desc") &&
                              !currentOrder
                                .replace(headerConfig.propName, "")
                                .includes("asc")
                                ? "asc"
                                : currentOrder
                                    .replace(headerConfig.propName, "")
                                    .includes("desc")
                                ? "asc"
                                : "desc"
                            }`
                          );
                        })()
                      : undefined
                  }
                  key={index}
                >
                  <span>{headerConfig.headerLabel}</span>
                  {headerConfig.order ? (
                    currentOrder.includes(headerConfig.propName) ? (
                      currentOrder
                        .replace(headerConfig.propName, "")
                        .includes("asc") ? (
                        <AiOutlineSortAscending size={16} />
                      ) : (
                        <AiOutlineSortDescending size={16} />
                      )
                    ) : (
                      <FaSort />
                    )
                  ) : (
                    <></>
                  )}
                </Th>
              ))
            ) : (
              <></>
            )}
          </Tr>
        </Thead>
        <Tbody>
          {itemsToShow && itemsToShow.length ? (
            itemsToShow.map((item, index) => (
              <Tr key={index}>
                {item &&
                Object.keys(item).length &&
                headersConfig &&
                headersConfig.length ? (
                  headersConfig.map((headerConfig, index) => (
                    <Td
                      key={index}
                      style={{
                        whiteSpace: headerConfig.noWrap ? "nowrap" : "normal",
                        ...headerConfig.attributes?.style,
                      }}
                    >
                      {(() => {
                        switch (typeof item[headerConfig.propName]) {
                          case "boolean":
                            return `${item[headerConfig.propName]}`;
                          case "string":
                            return (
                              <span
                                id="table-td-react-quill"
                                className="table-td"
                                dangerouslySetInnerHTML={{
                                  __html: item[headerConfig.propName],
                                }}
                              />
                            );
                          default:
                            return (
                              <span
                                {...(typeof item[headerConfig.propName] ===
                                "number"
                                  ? {
                                      title: item[headerConfig.propName],
                                    }
                                  : {})}
                                className="table-td"
                              >
                                {item[headerConfig.propName]}
                              </span>
                            );
                        }
                      })()}
                    </Td>
                  ))
                ) : (
                  <></>
                )}
              </Tr>
            ))
          ) : (
            <Tr>
              <Td
                style={{ textAlign: "center" }}
                colSpan={headersConfig.length}
              >
                {emptyListMessage || "Não foram fornecidos itens para a lista"}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
      ) : (
        <LoadingOnPage />
      )}
      {usePagination && numberOfPages > 1 && (
        <DefaultTablePagination>
          <DefaultButton
            className="medium white"
            onClick={() => changeCurrentPage(currentPage - 1)}
            disabled={currentPage <= 0}
          >
            <AiOutlineLeft size={18} />
          </DefaultButton>
          {Array(Math.min(5, numberOfPages))
            .fill("")
            .map((_, index) => (
              <DefaultButton
                key={index}
                className="white medium"
                onClick={() => changeCurrentPage(currentPage + index)}
                style={
                  currentPage === currentPage + index
                    ? { background: "var(--primary-color)", color: "white" }
                    : {}
                }
                disabled={currentPage + (index + 1) > numberOfPages}
              >
                {currentPage + index + 1}
              </DefaultButton>
            ))}
          <DefaultButton
            className="medium white"
            onClick={() => changeCurrentPage(currentPage + 5)}
            disabled={currentPage + 5 >= numberOfPages - 1}
          >
            <AiOutlineRight size={18} />
          </DefaultButton>
        </DefaultTablePagination>
      )}
    </DefaultTableContainer>
  );
};

export default DefaultTable;
