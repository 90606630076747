const checkMailField = (mail: string) => {
  let regex = /^[a-z0-9.]+@[a-z0-9]+\.[a-z]+\.?[a-z]+$/i;
  if (mail) {
    if (!mail.match(regex)) {
      return true;
    }
    return false;
  }
};

export default checkMailField;
