import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import BreadCrumb from '../../components/BreadCrumb';
import DefaultFilterForm from '../../components/DefaultFilterForm';
import { DefaultPageTitle } from '../../components/DefaultPageTitle';
import { EngagementContainer } from './style';
import Select from 'react-select';
import DefaultButton from '../../components/DefaultButton';
import ReactSelectComponent from '../../components/ReactSelectComponent';
import { getContentsForSelect } from '../../services/contents';
import { getUsersForSelect } from "../../services/users";
import { generateEngagementReport } from '../../services/reports';

const Engagement: React.FC = () => {
  const [content, setContent] = useState(
    [] as { value: string; label: string }[]
  );
  const [users, setUsers] = useState([] as { value: string; label: string }[]);
  const [selectedContent, setSelectedContent] = useState([] as string[]); // nao é course e sim aula, content
  const [selectedUsers, setSelectedUsers] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  const [startAt, setStartAt] = useState('');
  const [finishAt, setFinishAt] = useState('');

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      user_id_ft: selectedUsers,
      content_id_ft: selectedContent,
      start_at_gte: startAt,
      start_at_lte: finishAt,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateEngagementReport(reportType, queryStrings);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const localCourses = await getContentsForSelect();
      if (localCourses && localCourses.length) {
        setContent(localCourses);
      }

      const localUsers = await getUsersForSelect();
      if (localUsers && localUsers.length) {
        setUsers(localUsers);
      }
    })();
    setLoading(false);
  }, []);

  return (
    <EngagementContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <span>Relatórios</span>,
          <span>Engajamento</span>,
        ]}
      />

      <DefaultPageTitle>Cursos</DefaultPageTitle>

      <DefaultFilterForm>
        <span className='entire-row'>
          <label htmlFor='users'>Usuários</label>
          <ReactSelectComponent 
            placeHolder="Selecione um usuário"
            content={users}
            setState={setSelectedUsers}
            isLoading={loading || !users.length}
            notFoundMessage='Não foi encontrado nenhum usuário!'
          />
        </span>
        <span className='entire-row'>
          <label htmlFor='course'>Conteúdos</label>
          <ReactSelectComponent 
            placeHolder="Selecione um conteúdo"
            content={content}
            setState={setSelectedContent}
            isLoading={loading || !content.length}
            notFoundMessage='Não foi encontrado nenhum conteúdo!'
          />
        </span>

        <span>
          <label htmlFor='startAt'>Inicio em</label>
          <input
            type='date'
            id='startAtGte'
            max={finishAt}
            onChange={(e) => setStartAt(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className='label'>até</span>
          <input
            type='date'
            id='startAtLte'
            min={startAt}
            onChange={(e) => setFinishAt(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span className='entire-row center'>
          <DefaultButton
            onClick={() => generateReport('pdf')}
            className='success'
            type='button'
          >
            Gerar Relatório (PDF)
          </DefaultButton>{' '}
          <DefaultButton
            onClick={() => generateReport('csv')}
            className='success'
            type='button'
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </EngagementContainer>
  );
};

export default Engagement;
