import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultFilterForm from "../../components/DefaultFilterForm";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import ReactSelectComponent from "../../components/ReactSelectComponent";
import { getCoursesForSelect } from "../../services/courses";
import { generateFinishedCoursesReport } from "../../services/reports";
import { getUsersForSelect } from "../../services/users";
import { FinishedCoursesContainer } from "./style";

const FinishedCourses: React.FC = () => {
  const [courses, setCourses] = useState(
    [] as { value: string; label: string }[]
  );
  const [selectedCourses, setSelectedCourses] = useState([] as string[]);

  const [users, setUsers] = useState([] as { value: string; label: string }[]);
  const [selectedUsers, setSelectedUsers] = useState([] as string[]);
  const [loading, setLoading] = useState(false);

  const [startCourseStart, setStartCourseStart] = useState("");
  const [startCourseFinish, setStartCourseFinish] = useState("");

  const generateReport = async (reportType: string) => {
    const queryStrings: any = {
      user_id_ft: selectedUsers,
      trail_id_ft: selectedCourses,
      finish_at_gte: startCourseStart,
      finish_at_lte: startCourseFinish,
    };

    for (let prop of Object.keys(queryStrings)) {
      if (!queryStrings[prop]) {
        delete queryStrings[prop];
      }
    }

    await generateFinishedCoursesReport(reportType, queryStrings);
  };

  useEffect(() => {
    setLoading(true);
    (async () => {
      const localCourses = await getCoursesForSelect();
      if (localCourses && localCourses.length) {
        setCourses(localCourses);
      }

      const localUsers = await getUsersForSelect();
      if (localUsers && localUsers.length) {
        setUsers(localUsers);
      }
    })();
    setLoading(false)
  }, []);

  return (
    <FinishedCoursesContainer>
      <BreadCrumb
        crumbs={[
          <Link to='/profile'>Perfil</Link>,
          <span>Relatórios</span>,
          <span>Cursos Finalizados</span>,
        ]}
      />

      <DefaultPageTitle>Cursos</DefaultPageTitle>

      <DefaultFilterForm>
        <span className="entire-row">
          <label htmlFor="users">Usuários</label>
          <ReactSelectComponent 
            placeHolder="Selecione um usuário"
            content={users}
            setState={setSelectedUsers}
            isLoading={loading || !users.length}
            notFoundMessage='Não foi encontrado nenhum usuário!'
          />
        </span>

        <span className="entire-row">
          <label htmlFor="course">Cursos</label>
          <ReactSelectComponent 
            placeHolder="Selecione um curso"
            content={courses}
            setState={setSelectedCourses}
            isLoading={loading || !courses.length}
            notFoundMessage='Não foi encontrado nenhum curso!'
          />
        </span>

        <span>
          <label htmlFor="startAt">Inicio em</label>
          <input
            type="date"
            id="startAtGte"
            max={startCourseFinish}
            onChange={(e) => setStartCourseStart(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span>
          <span className="label">até</span>
          <input
            type="date"
            id="startAtLte"
            min={startCourseStart}
            onChange={(e) => setStartCourseFinish(e.target.value)}
            onKeyDown={(e) => {
              e.stopPropagation();
              e.preventDefault();
              return false;
            }}
          />
        </span>

        <span className="entire-row center">
          <DefaultButton
            onClick={() => generateReport("pdf")}
            className="success"
            type="button"
          >
            Gerar Relatório (PDF)
          </DefaultButton>{" "}
          <DefaultButton
            onClick={() => generateReport("csv")}
            className="success"
            type="button"
          >
            Gerar Relatório (CSV)
          </DefaultButton>
        </span>
      </DefaultFilterForm>
    </FinishedCoursesContainer>
  );
};

export default FinishedCourses;
