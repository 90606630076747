const checkLettersField = (str: string) => {
  var letters = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
  if (!str || !str.match(letters)) {
    return true;
  } else {
    return false;
  }
};

export default checkLettersField;
