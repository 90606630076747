import React from "react";
import { AiOutlineFileText, AiOutlineUser } from "react-icons/ai";
import { BsClipboardData, BsFlagFill, BsPersonLinesFill, BsGearFill } from "react-icons/bs";
import { RiCalendarCheckFill, RiCalendarLine } from "react-icons/ri";
import { FaQuestionCircle } from "react-icons/fa";
import { FiUsers } from "react-icons/fi";
import { GiPencil, GiTeamUpgrade, GiTrail } from "react-icons/gi";
import { IoMdPeople } from "react-icons/io";
import { IoLibraryOutline, IoReaderOutline } from "react-icons/io5";
import { GrTemplate } from 'react-icons/gr';

const menus = [
  {
    label: (
      <>
        <span className="icon">
          <AiOutlineUser />
        </span>
        <span className="text">Perfil</span>
      </>
    ),
    path: "/profile",
  },
  {
    label: (
      <>
        <span className="icon">
          <FiUsers />
        </span>
        <span className="text">Usuários</span>
      </>
    ),
    path: "/users",
  },
  {
    label: (
      <>
        <span className="icon">
          <IoMdPeople />
        </span>
        <span className="text">Autores</span>
      </>
    ),
    path: "/authors",
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Categorias</span>
      </>
    ),
    path: "/categories",
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Certificado</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <IoReaderOutline />
            </span>
            <span className="text">Template</span>
          </>
        ),
        path: "/certificates",
      },
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Assinatura</span>
          </>
        ),
        path: "/signatures",
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <GiTrail />
        </span>
        <span className="text">Trilhas</span>
      </>
    ),
    path: "/trails",
  },
  {
    label: (
      <>
        <span className="icon">
          <IoLibraryOutline />
        </span>
        <span className="text">Cursos</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Conteúdos</span>
          </>
        ),
        path: "/courses/contents",
      },
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Módulos</span>
          </>
        ),
        path: "/courses/modules",
      },
      {
        label: (
          <>
            <span className="icon">
              <IoLibraryOutline />
            </span>
            <span className="text">Cursos</span>
          </>
        ),
        path: "/courses/courses",
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <RiCalendarCheckFill />
        </span>
        <span className="text">Eventos</span>
      </>
    ),
    path: "/events",
  },
  {
    label: (
      <>
        <span className="icon">
          <GiPencil />
        </span>
        <span className="text">Provas</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <AiOutlineFileText />
            </span>
            <span className="text">Questões</span>
          </>
        ),
        path: "/questions",
      },
      {
        label: (
          <>
            <span className="icon">
              <GiPencil />
            </span>
            <span className="text">Provas</span>
          </>
        ),
        path: "/exams",
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <FaQuestionCircle />
        </span>
        <span className="text">FAQs</span>
      </>
    ),
    path: "/faqs",
  },
  {
    label: (
      <>
        <span className="icon">
          <BsClipboardData />
        </span>
        <span className="text">Relatórios</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <GiTeamUpgrade />
            </span>
            <span className="text">Engajamento</span>
          </>
        ),
        path: "/reports/engagement",
      },
      {
        label: (
          <>
            <span className="icon">
              <BsFlagFill />
            </span>
            <span className="text">Cursos Finalizados</span>
          </>
        ),
        path: "/reports/finished-courses",
      },
      {
        label: (
          <>
            <span className="icon">
              <BsPersonLinesFill />
            </span>
            <span className="text">Acesso de Usuário</span>
          </>
        ),
        path: "/reports/user-access",
      },
    ],
  },
  {
    label: (
      <>
        <span className="icon">
          <BsGearFill />
        </span>
        <span className="text">Configurações</span>
      </>
    ),
    children: [
      {
        label: (
          <>
            <span className="icon">
              <RiCalendarLine />
            </span>
            <span className="text">Google calendar</span>
          </>
        ),
        path: "/configuration/calendar",
      },
    ],
  },
];

export default menus;
