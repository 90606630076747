import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DefaultButton from '../../../../components/DefaultButton';
import DefaultTable from '../../../../components/DefaultTable';
import Course from '../../../../models/course';
import { getAllCourses } from '../../../../services/modules';
import { SelectCourseContainer } from './style';

interface SelectCourseProps {
  selectedCourses: Course[];
  onApplySelection: (selectedCourses: Course[]) => void;
}

const SelectCourse: React.FC<SelectCourseProps> = ({
  selectedCourses,
  onApplySelection,
}) => {
  const [allCourses, setAllCourses] = useState([] as Course[]);
  const [checkedCourses, setCheckedCourses] = useState(selectedCourses);
  const [isLoading, setIsLoading] = useState(true);

  const getCourses = async () => {
    const localCourses = await getAllCourses();
    if (localCourses && localCourses.length) {
      const filteredCourses = localCourses.filter(
        (localCouse: Course) => localCouse.is_active,
      );

      setAllCourses(filteredCourses);
    }
  
    setIsLoading(false);
  };

  const handleCheckCourse = useCallback(
    (event: React.FormEvent<HTMLInputElement>, course: Course) => {
      if (
        !selectedCourses.find(
          selectedCourse => selectedCourse.course_id === course.course_id,
        )
      ) {
        selectedCourses.push(course);

        event.currentTarget.checked = true;
      } else {
        const indexOfCourse = selectedCourses
          .map(course => course.course_id)
          .indexOf(course.course_id);

        if (indexOfCourse > -1 && indexOfCourse < selectedCourses.length) {
          selectedCourses.splice(indexOfCourse, 1);

          event.currentTarget.checked = false;
        }
      }
    },
    [checkedCourses],
  );

  const applySelection = (event: React.FormEvent<HTMLButtonElement>) => {
    onApplySelection(checkedCourses);
  };

  const lessonsToBeShown = useMemo(() => {
    return allCourses.map(course => {
      const shouldBeChecked = checkedCourses
        .map(checkedCourse => checkedCourse.course_id)
        .includes(course.course_id);

      return {
        thumbnail: (
          <img
            src={course.thumbnail}
            style={{ width: '300px' }}
            alt="thumbnail"
          />
        ),
        title: course.title,
        description: course.description,
        check: (
          <>
            <input
              type="checkbox"
              value={course.course_id}
              defaultChecked={shouldBeChecked}
              onChange={e => handleCheckCourse(e, course)}
            />
          </>
        ),
      };
    });
  }, [allCourses, checkedCourses, handleCheckCourse]);

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <SelectCourseContainer>
      <DefaultButton
        style={{ position: 'fixed', right: '11%', bottom: '11%' }}
        onClick={applySelection}
      >
        Aplicar Seleção
      </DefaultButton>
      <DefaultTable
        usePagination={false}
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: 'title',
            attributes: { style: { width: '35%' } },
          },
          { headerLabel: <span>Descrição</span>, propName: 'description' },
          { headerLabel: <span>Selecionar</span>, propName: 'check' },
        ]}
        items={lessonsToBeShown}
        isLoading={isLoading}
      />
    </SelectCourseContainer>
  );
};

export default SelectCourse;
