import React, { useState } from "react";
import { useDropzone } from "react-dropzone";
import { ProgressBarScormContainer, ScormCoursesContainer } from "./style";
import DefaultButton from "../../components/DefaultButton";
import { IFile } from "./IScormCourses";
import UploadProgressBar from "../../components/SelectSambaVideosContent/components/UploadProgressBar";
import Swal from "sweetalert2";
import { getSignedUrl } from "../../services/scorm";

interface IPropsScormCourses {
  setReference: React.Dispatch<React.SetStateAction<string>>;
  setIsScormVersion12: React.Dispatch<React.SetStateAction<boolean>>;
}

const ScormCourses: React.FC<IPropsScormCourses> = ({ setReference, setIsScormVersion12 }) => {
  const [uploadProgress, setUploadProgress] = useState(0);

  function InputDropzone(props: any) {
    const onDrop = async (acceptedFiles: any[]) => {
      try {
        Swal.fire({
          title: "Iniciando Upload",
          text: "Upload de conteúdo iniciado!",
          timer: 2500,
        });
        let pathIndexInFolder: string = "";

        const searchIndexInFolder = (
          fileImsmanifest: IFile
        ): Promise<string> => {
          return new Promise((resolve, reject) => {
            const readerManifest = new FileReader();
            readerManifest.readAsText(fileImsmanifest);

            readerManifest.onload = (data) => {
              try {
                const readerData = data.target!.result;
                if (typeof readerData == "string") {
                  const parser = new DOMParser();
                  const xml = parser.parseFromString(readerData!, "text/xml");
                  const schemaVersion = xml.querySelector("schemaversion");
                  if(!schemaVersion){
                    throw new Error("Não foi possível encontrar a versão do scorm.");
                  }
                  setIsScormVersion12(schemaVersion.textContent?.trim() === '1.2');
                  const teste = xml.querySelector("resources");
                  const resourceWithHref = teste?.children[0];
                  const hrefIndex =
                    resourceWithHref?.getAttribute("href") || "";
                  pathIndexInFolder = hrefIndex.split("?")[0];
                  if (!pathIndexInFolder) {
                    resolve(pathIndexInFolder);
                  } else {
                    const folderName = 
                      fileImsmanifest.path.split("/")[0] ? 
                      fileImsmanifest.path.split("/")[0] :
                      fileImsmanifest.path.split("/")[1]
                    resolve(
                      `${folderName}/${pathIndexInFolder}`
                    );
                  }
                }
              } catch (error) {
                reject(error.message);
              }
            };
          });
        };

        const uploadFileScorm = (
          file: IFile,
          contentType: string,
          uploadURL: string,
          index: number
        ): Promise<void> => {
          return new Promise((resolve, reject) => {
            try {
              const reader = new FileReader();
              if (file.size > 100000000) {
                reader.onload = async (data: any) => {
                  const buffer = data.target.result;

                  const fileBlob = new Blob([new Uint8Array(buffer)], {
                    type: file.type,
                  });

                  await fetch(uploadURL, {
                    method: "PUT",
                    body: fileBlob,
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                    },
                  });
                  if (index === acceptedFiles.length) {
                    const pathIndex = `${
                      process.env.REACT_APP_ASSETS_SCORM
                    }scorm/leve-educacao/${encodeURI(pathIndexInFolder)}`;

                    setReference(pathIndex);
                    setUploadProgress(0);
                    Swal.fire({
                      title: "Sucesso!",
                      icon: "success",
                      text: "Upload do scorm realizado com sucesso!",
                    });
                  }
                  resolve();
                };
                reader.readAsArrayBuffer(file);
              } else {
                reader.onload = async (data: any) => {
                  const dataFileUrl = data.target.result;
                  let array = [];
                  if (!!dataFileUrl.split(",")[1]) {
                    let binary = atob(dataFileUrl.split(",")[1]);
                    for (let i = 0; i < binary.length; i++) {
                      array.push(binary.charCodeAt(i));
                    }
                  }
                  let blobData = new Blob([new Uint8Array(array)], {
                    type: contentType,
                  });

                  await fetch(uploadURL, {
                    method: "PUT",
                    body: blobData,
                    headers: {
                      "Access-Control-Allow-Origin": "*",
                    },
                  });
                  if (index === acceptedFiles.length) {
                    const pathIndex = `${
                      process.env.REACT_APP_ASSETS_SCORM
                    }scorm/leve-educacao/${encodeURI(pathIndexInFolder)}`;

                    setReference(pathIndex);
                    setUploadProgress(0);
                    Swal.fire({
                      title: "Sucesso!",
                      icon: "success",
                      text: "Upload do scorm realizado com sucesso!",
                    });
                  }
                  resolve();
                };
                reader.readAsDataURL(file);
              }
            } catch (error) {
              reject();
            }
          });
        };
        let hasImsmanifest = false;

        for (const file of acceptedFiles) {
          if (file.path.includes("imsmanifest")) {
            try {
              hasImsmanifest = true;
              pathIndexInFolder = await searchIndexInFolder(file);
            } catch (error) {
              throw new Error(typeof error == 'string' ? error : 'Erro ao acessar o arquivo imsmanifest.xml');
            }
          }
        }
        if (!pathIndexInFolder && hasImsmanifest) {
          throw new Error(
            "Não foi possível encontrar o index base do scorm, no arquivo imsmanifest.xml"
          );
        }
        if(!hasImsmanifest){
          throw new Error(
            "Não foi possível encontrar o arquivo imsmanifest.xml. Por favor insira o scorm na versão 1.2 ou 2004."
          );
        }
        let countFilesUpload: number = 0;
        for (const file of acceptedFiles) {
          let contentType: string = file.type;
          if (!contentType) {
            contentType =
              file.path.split(".")[1] === "xsd"
                ? "application/xml"
                : "application/octet-stream";
          }

          const { uploadURL } = await getSignedUrl(contentType, file.path);
          await uploadFileScorm(
            file,
            contentType,
            uploadURL,
            ++countFilesUpload
          );
          const progress = (countFilesUpload / acceptedFiles.length) * 100;
          if (progress === 100) {
            setUploadProgress(0);
          } else {
            setUploadProgress(progress);
          }
        }
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Erro",
          text: error.message,
        });
      }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
      onDrop,
    });

    return (
      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          directory=""
          webkitdirectory=""
          type="file"
          {...props}
        />
        {isDragActive ? (
          <DefaultButton type="button">Solte o diretório aqui!!</DefaultButton>
        ) : (
          <DefaultButton type="button">
            Arraste o diretório que contém os arquivos scorms ou clique aqui e
            selecione-o.
          </DefaultButton>
        )}
      </div>
    );
  }

  return (
    <ScormCoursesContainer>
      <InputDropzone />
      {uploadProgress > 0 ? (
        <ProgressBarScormContainer>
          <UploadProgressBar progress={uploadProgress} />
        </ProgressBarScormContainer>
      ) : (
        <></>
      )}
    </ScormCoursesContainer>
  );
};

export default ScormCourses;
