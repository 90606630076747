import styled from "styled-components";

const SelectQuestionsContainer = styled.div`
  height: 100%;
  overflow-y: auto;
  padding-bottom: 60px;

  & input[type="checkbox"] {
    display: block;
    margin: 0 auto;
    width: 25px;
    height: 25px;
    cursor: pointer;
  }
  & > :not(:last-child) {
    margin-bottom: 10px;
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

export { SelectQuestionsContainer };
