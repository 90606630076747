import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup,
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { UpdatedAtLabel } from "../../components/UpdatedAt";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import { hideModal, showModal } from "../../helpers/modal";
import Lesson from "../../models/lesson";
import {
  SortLessonsContainer,
  LessonSelectionContainer,
  CreateAndEditCourseContainer,
} from "./style";
import SelectLesson from "./components/SelectLesson";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import {
  getCourse as getCourseService,
  updateCourse as updateCourseService,
  createCourse as createCourseService,
} from "../../services/modules";
import Swal from "sweetalert2";
import checkEmptyString from "../../helpers/check-empty-string";
import Content from "../../models/content";
import convertContent from "../../converters/convert-content";
import getErrorMessage from "../../helpers/get-error-message";
import SelectExam from "./components/SelectExam";
import Exam from "../../models/exam";
import { BiTrash } from "react-icons/bi";
import { getExam } from "../../services/exams";
import TextareaReactQuill from "../../components/TextareaReactQuill/TextareaReactQuill";

interface CreateAndEditCourseProps {
  moduleId: string;
}

const CreateAndEditCourse: React.FC = () => {
  const history = useHistory();
  const { moduleId } = useParams<CreateAndEditCourseProps>();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [selectedLessons, setSelectedLessons] = useState([] as any[]);
  const [updatedAt, setUpdatedAt] = useState("");
  const [selectedExam, setSelectedExam] = useState(
    undefined as Exam | undefined
  );

  const validate = () => {
    if (checkEmptyString(name)) {
      throw new Error("Informe um nome válido para o módulo.");
    }

    if (!selectedLessons || !selectedLessons.length) {
      throw new Error("Selecione um ou mais vídeos.");
    }
  };

  const createCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      await createCourseService({
        title: name,
        description: description || " ",
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.id,
          position: index + 1,
        })),
        exam_id: selectedExam ? selectedExam.id : undefined,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Módulo criado com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const updateCourse = async (event: React.FormEvent) => {
    event.preventDefault();

    try {
      validate();

      await updateCourseService(moduleId, {
        title: name,
        description: description || " ",
        contents: selectedLessons.map((lesson, index) => ({
          content_id: lesson.id,
          position: index + 1,
        })),
        exam_id: selectedExam ? selectedExam.id : undefined,
      });

      Swal.fire({
        title: "Sucesso!",
        text: "Módulo editado com sucesso!",
        icon: "success",
      });

      goToCourses();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text: errorMessage,
        icon: "error",
      });
    }
  };

  const selectLessons = () => {
    showModal(
      "Selecionar Vídeos",
      <SelectLesson
        onApplySelection={applyLessonsSelection}
        selectedLessons={[...selectedLessons]}
      />
    );
  };

  const applyLessonsSelection = (localSelectedLessons: Content[]) => {
    hideModal();

    if (localSelectedLessons) {
      setSelectedLessons(localSelectedLessons);
    }
  };

  const reorderSelectedLessons = (lesson: Lesson, up: boolean) => {
    if (selectedLessons) {
      const localSelectedLessons = [...selectedLessons];
      const indexOfLesson = localSelectedLessons.indexOf(lesson);
      if (indexOfLesson > -1 && indexOfLesson < localSelectedLessons.length) {
        localSelectedLessons.splice(indexOfLesson, 1);
        if (up) {
          localSelectedLessons.splice(indexOfLesson - 1, 0, lesson);
        } else {
          localSelectedLessons.splice(indexOfLesson + 1, 0, lesson);
        }
      }

      setSelectedLessons(localSelectedLessons);
    }
  };

  const getCourse = useCallback(async () => {
    if (moduleId) {
      const course = await getCourseService(moduleId);
      if (course && Object.keys(course).length) {
        setName(course.title);
        setDescription(course.description);
        if (course.exam) {
          const exam = await getExam(course.exam);
          setSelectedExam({ ...exam } as Exam);
        }

        if (course.updated_at) {
          let date = new Date(course.updated_at);
          setUpdatedAt(date.toLocaleString());
        }

        if (course.contents && course.contents.length) {
          const convertedCourses =
            course.contents && course.contents.length
              ? course.contents
                  .sort((a: any, b: any) => (a.position > b.position ? 1 : -1))
                  .map((course: Content) => {
                    return convertContent(course);
                  })
              : [];
          setSelectedLessons(convertedCourses);
        }
      }
    }
  }, [moduleId]);

  const goToCourses = () => {
    history.push("/courses/modules");
  };

  const selectExam = () => {
    showModal(
      "Selecionar Prova",
      <SelectExam onSelect={onSelectExam} initialSelectedExam={selectedExam} />
    );
  };

  const onSelectExam = (exam?: Exam) => {
    setSelectedExam(exam);
  };

  const removeContent = (lesson: any) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja remover esse conteúdo?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setSelectedLessons([...selectedLessons.filter((sl) => sl !== lesson)]);
      }
    });
  };

  useEffect(() => {
    getCourse();
  }, [getCourse]);

  const isEditting = useMemo(() => {
    if (moduleId) {
      return true;
    }

    return false;
  }, [moduleId]);

  return (
    <CreateAndEditCourseContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/trails/contents">Trilhas</Link>,
          <span>{isEditting ? "Editar" : "Criar"} Módulo</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Módulo
        {updatedAt && (
          <UpdatedAtLabel> - Última atualização em {updatedAt}</UpdatedAtLabel>
        )}
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Título
          </label>
          <DefaultInput
            value={name}
            onChange={(e) => setName(e.target.value)}
            id="name"
            required
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <TextareaReactQuill state={description} setState={setDescription} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="description">Prova</label>
          <div className="select-exam">
            <DefaultButton type="button" onClick={selectExam}>
              Selecionar Prova
            </DefaultButton>
            {selectedExam && (
              <div className="selected-exam">
                {selectedExam.title}
                <span className="actions">
                  <DefaultButton
                    className="small danger"
                    onClick={() => setSelectedExam(undefined)}
                  >
                    <BiTrash />
                  </DefaultButton>
                </span>
              </div>
            )}
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="reference">
            Conteúdo
          </label>

          <LessonSelectionContainer>
            {selectedLessons && selectedLessons.length ? (
              <SortLessonsContainer>
                {selectedLessons.map((lesson, index) => (
                  <div key={lesson.id} className="selected-lessons">
                    <div className="lesson-title">
                      {index + 1} - {lesson.title}
                    </div>
                    <div className="buttons">
                      {index > 0 ? (
                        <DefaultButton
                          type="button"
                          title="Subir Aula"
                          className="small white up"
                          onClick={() => reorderSelectedLessons(lesson, true)}
                        >
                          <AiOutlineUp />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      {index < selectedLessons.length - 1 ? (
                        <DefaultButton
                          type="button"
                          title="Descer Aula"
                          className="small white down"
                          onClick={() => reorderSelectedLessons(lesson, false)}
                        >
                          <AiOutlineDown />
                        </DefaultButton>
                      ) : (
                        <></>
                      )}
                      <DefaultButton
                        type="button"
                        onClick={() => removeContent(lesson)}
                        className={`small danger`}
                      >
                        <BiTrash />
                      </DefaultButton>
                    </div>
                  </div>
                ))}
              </SortLessonsContainer>
            ) : (
              <></>
            )}
            <DefaultButton type="button" onClick={selectLessons}>
              Selecionar Conteúdo
            </DefaultButton>
          </LessonSelectionContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCourses}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) => (isEditting ? updateCourse(e) : createCourse(e))}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditCourseContainer>
  );
};

export default CreateAndEditCourse;
