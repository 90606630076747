import httpClient from "../http-client";

const getGoogleCalendarConfig = async () => {
  return (await httpClient.get('/meet-config'));
}

interface putGoogleCalendarConfigProps {
  clientId: string,
  clientSecret: string
}

const putGoogleCalendarConfig = async ({ 
  clientId, 
  clientSecret 
}: putGoogleCalendarConfigProps) => {
  return (await httpClient.put('/meet-config', { 
    client_id: clientId,
    client_secret: clientSecret
  }))
}

interface getGoogleCalendarTokenProps {
  scope: string,
  code: string
}

const getGoogleCalendarToken = async ({ 
  code, 
  scope 
}:getGoogleCalendarTokenProps) => {
  return (await httpClient.get(`/webhooks/meet/token?code=${code}&scope=${scope}`))
}


export { getGoogleCalendarConfig, putGoogleCalendarConfig,getGoogleCalendarToken };