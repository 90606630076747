import { EditorContainer } from "./style";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useCallback, useEffect } from "react";
import { EditorState, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import { useRef } from "react";
import { uploadFile } from "../../services/files";
import getErrorMessage from "../../helpers/get-error-message";

interface InputDescriptionProps {
  description: string;
  setDescription: React.Dispatch<React.SetStateAction<string>>;
  setEditorState: React.Dispatch<React.SetStateAction<EditorState>>;
  editorState: EditorState;
}

const InputDescription: React.FC<InputDescriptionProps> = ({
  description,
  setDescription,
  setEditorState,
  editorState,
}) => {
  const inputDescriptionWrapper = useRef<HTMLDivElement>(null);
  const onEditorStateChange = useCallback(
    (editorState: any) => {
      setEditorState(editorState);
    },
    [setDescription]
  );

  useEffect(() => {
    const contentBlock = htmlToDraft(description);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorStateCreated = EditorState.createWithContent(contentState);
      setEditorState(editorStateCreated);
    }
  }, [description]);

  useEffect(() => {
    if (inputDescriptionWrapper.current) {
      const addUploadButton = () => {
        const rdwImageModals =
          inputDescriptionWrapper.current?.querySelectorAll(".rdw-image-modal");
        if (rdwImageModals && rdwImageModals.length) {
          for (let rdwImageModal of rdwImageModals) {
            const rdwImageModalHeaders = Array.from(
              rdwImageModal.getElementsByClassName("rdw-image-modal-header")
            );
            if (rdwImageModalHeaders && rdwImageModalHeaders.length) {
              for (let rdwImageModalHeader of rdwImageModalHeaders) {
                const uploadImageButton = document.createElement("button");
                uploadImageButton.classList.add("upload-image-button");
                uploadImageButton.innerText = "Enviar Imagem";

                const fileInput = document.createElement("input");
                fileInput.type = "file";
                fileInput.style.display = "none";
                fileInput.accept = "*/image";

                const imageUrlInput = rdwImageModal.querySelector(
                  ".rdw-image-modal-url-input"
                ) as HTMLInputElement;
                if (imageUrlInput) {
                  uploadImageButton.addEventListener("click", () => {
                    fileInput.click();
                  });
                }

                fileInput.onchange = async (event: any) => {
                  event.preventDefault();

                  if (event.currentTarget) {
                    let file =
                      event.currentTarget.files &&
                      event.currentTarget.files.length
                        ? event.currentTarget.files[0]
                        : null;

                    const formData = new FormData();
                    formData.append("file", file);
                    const { reference } = await uploadFile(formData);
                    if (imageUrlInput) {
                      imageUrlInput.focus();
                      imageUrlInput.value = reference;

                      const rdwImageModalSizeInputs = Array.from(
                        rdwImageModal.getElementsByClassName(
                          "rdw-image-modal-size-input"
                        )
                      ) as HTMLInputElement[];
                      if (
                        rdwImageModalSizeInputs &&
                        rdwImageModalSizeInputs.length
                      ) {
                        for (let rdwImageModalSizeInput of rdwImageModalSizeInputs) {
                          rdwImageModalSizeInput.focus();
                        }
                      }
                    }
                  }
                };

                rdwImageModal.replaceChild(
                  uploadImageButton,
                  rdwImageModalHeader
                );
              }
            }
          }
        }
      };

      const rdwImageWrappers = Array.from(
        document.getElementsByClassName("rdw-image-wrapper")
      );
      if (rdwImageWrappers && rdwImageWrappers.length) {
        for (let rdwImageWrapper of rdwImageWrappers) {
          rdwImageWrapper.addEventListener("click", () => {
            process.nextTick(() => addUploadButton());
          });
        }
      }
    }
  }, [inputDescriptionWrapper.current, description]);

  return (
    <EditorContainer ref={inputDescriptionWrapper}>
      <Editor
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        editorStyle={{ padding: "0 8px" }}
        wrapperStyle={{ width: "100%" }}
      />
    </EditorContainer>
  );
};

export default InputDescription;
