import axios from "axios";

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
});

httpClient.interceptors.request.use(function (config) {
  const loading = document.getElementById("loading");
  if (loading && !config.url?.includes("/files/url-signed")) {
    loading.style.display = "flex";
  }

  const token = window.localStorage.getItem("leveeducacao-api-token");
  config.headers.Authorization = `Bearer ${token}`;

  return config;
});

httpClient.interceptors.response.use(
  function (response) {
    const loading = document.getElementById("loading");
    if (loading) {
      if (response.config.method !== "GET") {
        loading.style.display = "none";
      } else {
        setTimeout(() => {
          loading.style.display = "none";
        }, 300);
      }
    }

    return response;
  },
  function (error) {
    setTimeout(() => {
      const loading = document.getElementById("loading");
      if (loading) {
          loading.style.display = "none";
      }
    }, 300);
    if (error.response.status === 401 && window.location.pathname !== '/') {
      localStorage.removeItem('leve-educacao-api-token');
      localStorage.removeItem('leve-educacao-user');
      window.location.href="/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default httpClient;
