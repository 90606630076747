import styled from "styled-components";

const SelectExamContainer = styled.div`
  padding: 8px 16px;
  padding-bottom: 64px;
  height: 100%;
  overflow: auto;
  position: relative;

  & .apply-selection {
    position: fixed;
    bottom: 12.5%;
    right: 14%;
  }

  & .exams {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    margin: 8px 0;
  }
`;

const ExamCardContainer = styled.div`
  padding: 16px;
  border: solid 1px #cbcbcb;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s;

  :hover,
  &.selected {
    background: rgba(29, 38, 128, 0.3);
  }
`;

export { SelectExamContainer, ExamCardContainer };
