import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 8px;
`;

export const SelectWrapper = styled.div`
  display: flex;
  flex-grow: 1;
  border: 1px solid #e0e0e0;
  padding: 0 10px;
  border-radius: 8px;
  width: 100%;
  > div:first-of-type {
    width: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
    > .css-yk16xz-control,
    .css-1pahdxg-control {
      width: 100%;
      border: none;
      border-color: transparent;
      box-shadow: none;
      &:hover {
        border-color: transparent;
        box-shadow: none;
      }
    }
    .css-1okebmr-indicatorSeparator {
      display: none;
    }
  }
  > div:nth-of-type(2) {
    border-left: 1px solid #e0e0e0;
    width: 100%;
    flex-grow: 1;
    > .css-yk16xz-control,
    .css-1pahdxg-control {
      min-height: 100%;
      border: none;
      border-color: transparent;
      box-shadow: none;
      &:hover {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
  .segmentationLimitHeight__control {
    max-height: 300px;
    overflow-y: auto;
  }
`;

export const TableContainer = styled.div`
  width: 100%;
  margin-top: 2.5rem;
  border: solid 1px var(--regular-gray);
  border-radius: 8px;
  align-self: center;
`

export const TableHeader = styled.div`
  background-color: var(--secondary-color);
  height: 40px;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px;
  border-radius: 7px 7px 0px 0px;
`;

export const TableTitle = styled.p`
  color: #FEFEFE;
  font-size: 18px;
  font-weight: 700;
`;

export const TableActions = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  margin: 2rem 0;
  padding: 0px 4rem;
`;

export const SelectAllContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  input {
    cursor: pointer;
  }

  label {
    font-size: 16px;
    font-weight: 700;
    color: var(--regular-gray);
    margin: 0;
  }
`;

interface ActionButtonProps {
  danger?: boolean;
}

export const ActionButton = styled.button<ActionButtonProps>`
  border: 0;
  background: transparent;
  color: ${(props) => props.danger ? 'var(--danger-color)' : 'var(--regular-gray)'};
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;

  :hover {
    text-decoration: underline;
  }
`;  

export const SegmentationLineContainer = styled.div`
  overflow:auto;
  max-height: 450px;
  padding: 0px 4rem 2rem;
`;