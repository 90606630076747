import { convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import React, { useEffect, useMemo, useState } from "react";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import { BiTrash } from "react-icons/bi";
import { IoAdd } from "react-icons/io5";
import { Link, useHistory, useParams } from "react-router-dom";
import Switch from "react-switch";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, {
  DefaultCreationFormButtonGroup,
  DefaultCreationFormGroup
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import { DefaultTextArea } from "../../components/DefaultTextArea";
import InputDescription from "../../components/InputDescription";
import SelectSambaVideosContent from "../../components/SelectSambaVideosContent";
import checkEmptyString from "../../helpers/check-empty-string";
import getErrorMessage from "../../helpers/get-error-message";
import { hideModal, showModal } from "../../helpers/modal";
import MediaFromResponse from "../../models/from-api-response/media";
import {
  createQuestion as createQuestionService, getCorrectAlternativeId, getQuestion as getQuestionService, updateQuestion as updateQuestionService
} from "../../services/questions";
import { AlternativesList, ContentUploadOrSelectContainer, CreateAndEditQuestionContainer } from "./style";

interface Alternative {
  value: string;
  order?: number;
  is_correct: boolean;
}

interface CreateAndEditQuestionParams {
  questionId: string;
}

const CreateAndEditQuestion: React.FC = () => {
  const { questionId } = useParams<CreateAndEditQuestionParams>();
  const history = useHistory();
  const [enunciated, setEnunciated] = useState("");
  const [points, setPoints] = useState(1);
  const [randomize, setRandomize] = useState(false);
  const [alternatives, setAlternatives] = useState([] as Alternative[]);
  const [actualAlternativeDescription, setActualAlternativeDescription] =
    useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [sambaVideoReference, setSambaVideoReference] = useState("");

  const onSelectContent = (sambaVideosContent: MediaFromResponse) => {
    if (sambaVideosContent) {
      if (sambaVideosContent.files && sambaVideosContent.files.length) {
        const firstFile = sambaVideosContent.files[0];

        if (firstFile) {
          const playerKey = process.env.REACT_APP_PLAYER_KEY;
          const referenceUrl = `${process.env.REACT_APP_PLAYER_INITIAL_URL}/${playerKey}/${sambaVideosContent.id}`;
          setSambaVideoReference(referenceUrl);
        }
      }
    }

    hideModal();
  };
  
  const selectContent = () => {
    showModal(
      "Selecionar Conteúdo",
      <SelectSambaVideosContent onSelectContent={onSelectContent} />
    );
  };

  const goToQuestions = () => {
    history.push("/questions");
  };

  const getQuestion = async () => {
    const localQuestion = await getQuestionService(questionId);
    if (localQuestion && Object.keys(localQuestion).length) {
      setEnunciated(localQuestion.enunciated);
      setPoints(localQuestion.points);
      setRandomize(localQuestion.randomizeAlternatives);
      setSambaVideoReference(localQuestion.info.sambaVideo)

      const correctAlternativeId = await getCorrectAlternativeId(questionId);
      setAlternatives(
        localQuestion.alternative && localQuestion.alternative.length
          ? localQuestion.alternative.map((alt) => ({
              value: alt.value,
              order: alt.order,
              is_correct: alt.id === correctAlternativeId,
            }))
          : []
      );
    }
  };

  const addAlternative = () => {
    if (!checkEmptyString(actualAlternativeDescription)) {
      setAlternatives([
        ...alternatives,
        {
          value: actualAlternativeDescription,
          is_correct: !alternatives.length,
        },
      ]);
      setActualAlternativeDescription("");
    } else {
      Swal.fire({
        title: "Erro",
        text: "Informe uma descrição válida para a alternativa!",
        icon: "error",
      });
    }
  };

  const setCorrectAnswer = (alternative: Alternative) => {
    for (let alt of alternatives) {
      alt.is_correct = false;
    }

    alternative.is_correct = true;

    setAlternatives([...alternatives]);
  };

  const validadeRegexEnunciated = (enunciated: string) => {
    const regex = /^<p>(?!&nbsp;)[^-\s][a-z0-9\s\D]+<\/p>\s?$/i;
    return !regex.test(enunciated);
  };
  const validate = () => {
    const enunciatedLocal = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    if (validadeRegexEnunciated(enunciatedLocal)) {
      throw new Error("Informe um enunciado válido para a questão!");
    }
    if (!alternatives || alternatives.length < 2) {
      throw new Error("Informe ao menos duas alternativas!");
    } else if (!alternatives.find((alt) => alt.is_correct)) {
      throw new Error("Informe a alternativa correta!");
    }
  };

  const createQuestion = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      validate();

      const enunciatedLocal = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      const question = {
        enunciated: enunciatedLocal,
        alternative: !randomize
          ? alternatives.map((alt, index) => ({ ...alt, order: index + 1 }))
          : alternatives.map((alt) => ({
              value: alt.value,
              is_correct: alt.is_correct,
            })),
        points: points,
        randomize_alternatives: randomize,
        question_type: "objetiva",
        info: {},
      }
      if(!!sambaVideoReference){
        question.info = { samba_video_reference: sambaVideoReference }
      }

      await createQuestionService(question);

      Swal.fire({
        title: "Sucesso",
        text: "Questão cadastrada com sucesso!",
        icon: "success",
      });

      goToQuestions();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        text:
          (errorMessage.includes("alternativa")
            ? "Ops... "
            : "Erro ao cadastrar Questão. ") + errorMessage,
        icon: errorMessage.includes("alternativa") ? "warning" : "error",
      });
    }
  };

  const updateQuestion = async (event: React.FormEvent) => {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    try {
      validate();

      const enunciatedLocal = draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      );

      const question = {
        enunciated: enunciatedLocal,
        alternative: !randomize
          ? alternatives.map((alt, index) => ({ ...alt, order: index + 1 }))
          : alternatives.map((alt) => ({
              value: alt.value,
              is_correct: alt.is_correct,
            })),
        info: { },   
        points: points,
        randomize_alternatives: randomize,
        question_type: "objetiva",
      }
      
      if(!!sambaVideoReference){
        question.info = { samba_video_reference: sambaVideoReference  }
      }

      await updateQuestionService(questionId, question);

      Swal.fire({
        title: "Sucesso",
        text: "Questão editada com sucesso!",
        icon: "success",
      });

      goToQuestions();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        title: "Erro",
        text:
          (errorMessage.includes("alternativa")
            ? "Ops... "
            : "Erro ao cadastrar Questão. ") + errorMessage,
        icon: errorMessage.includes("alternativa") ? "warning" : "error",
      });
    }
  };

  const removeAlternative = (alternative: Alternative) => {
    Swal.fire({
      icon: "question",
      text: "Tem certeza que deseja remover esta alternativa?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        setAlternatives([...alternatives.filter((alt) => alt !== alternative)]);
      }
    });
  };

  const changeAlternativePosition = (
    event: React.MouseEvent,
    alternative: Alternative,
    up: boolean
  ) => {
    if (
      event.currentTarget &&
      (event.currentTarget as HTMLButtonElement).disabled
    ) {
      return;
    }

    const indexOfAlternative = alternatives.indexOf(alternative);
    if (up) {
      const nextAlternative = alternatives[indexOfAlternative - 1];

      alternatives[indexOfAlternative] = nextAlternative;
      alternatives[indexOfAlternative - 1] = alternative;
    } else {
      const previousAlternative = alternatives[indexOfAlternative + 1];

      alternatives[indexOfAlternative] = previousAlternative;
      alternatives[indexOfAlternative + 1] = alternative;
    }

    setAlternatives([...alternatives]);
  };

  const isCorrectAnswer = (alternative: Alternative) => {
    return !!(alternatives.find((alt) => alt.value === alternative.value) || {})
      .is_correct;
  };

  const isEditting = useMemo(() => {
    return !!questionId;
  }, [questionId]);

  useEffect(() => {
    if (questionId) {
      getQuestion();
    }
  }, [questionId]);

  return (
    <CreateAndEditQuestionContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <span>Questões</span>,
          <span>{isEditting ? "Editar" : "Criar"} Questão</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditting ? "Editar" : "Criar"} Questão
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="enunciated">
            Enunciado
          </label>
          <InputDescription
            description={enunciated}
            setDescription={setEnunciated}
            editorState={editorState}
            setEditorState={setEditorState}
          />
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="name">Randomizar Alternativas</label>
          <div
            style={{
              border: "solid 1px var(--default-dark-gray)",
              borderRadius: "5px",
              width: "100%",
              padding: "10px",
            }}
          >
            <DefaultInput
              checked={randomize}
              onChange={(e) => setRandomize(e.target.checked)}
              type="checkbox"
              style={{ height: "32px" }}
            />
          </div>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label htmlFor="name">Adicionar Vídeo</label>
          <ContentUploadOrSelectContainer>
         
              <DefaultButton type="button" onClick={selectContent}>
                Selecionar Vídeo
              </DefaultButton>

              {sambaVideoReference ? (
              <iframe
                title="referenced-video"
                allowFullScreen
                src={sambaVideoReference}
                frameBorder={0}
              ></iframe>
            ) : (
              <></>
            )}
          </ContentUploadOrSelectContainer>
        </DefaultCreationFormGroup>

        <DefaultCreationFormGroup>
          <label className="required" htmlFor="name">
            Alternativas
          </label>
          <div
            style={{
              border: "solid 1px var(--default-dark-gray)",
              borderRadius: "5px",
              width: "100%",
              padding: "10px",
            }}
          >
            <DefaultCreationFormGroup>
              <label htmlFor="alternative">Descrição da alternativa</label>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <DefaultTextArea
                  style={{
                    marginRight: "10px",
                  }}
                  id="alternative"
                  value={actualAlternativeDescription}
                  onChange={(e) =>
                    setActualAlternativeDescription(e.target.value)
                  }
                />
                <DefaultButton
                  type="button"
                  onClick={addAlternative}
                  className="small"
                >
                  <IoAdd size={26} />
                </DefaultButton>
              </div>
            </DefaultCreationFormGroup>

            {alternatives && alternatives.length ? (
              <AlternativesList>
                {alternatives.map((alt, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    <p style={{ flexGrow: 1 }}>{alt.value}</p>

                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <Switch
                        onChange={(checked: any) =>
                          checked && setCorrectAnswer(alt)
                        }
                        checked={isCorrectAnswer(alt)}
                      />
                      {!randomize ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={(event) =>
                              changeAlternativePosition(event, alt, true)
                            }
                            className={`small white up`}
                            disabled={!(alternatives[0] !== alt)}
                          >
                            <AiOutlineUp />
                          </DefaultButton>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                      {!randomize ? (
                        <>
                          <DefaultButton
                            type="button"
                            onClick={(event) =>
                              changeAlternativePosition(event, alt, false)
                            }
                            className={`small white down`}
                            disabled={
                              !(alternatives[alternatives.length - 1] !== alt)
                            }
                          >
                            <AiOutlineDown />
                          </DefaultButton>{" "}
                        </>
                      ) : (
                        <></>
                      )}
                      <DefaultButton
                        type="button"
                        onClick={() => removeAlternative(alt)}
                        className={`small danger`}
                      >
                        <BiTrash />
                      </DefaultButton>
                    </div>
                  </div>
                ))}
              </AlternativesList>
            ) : (
              <></>
            )}
          </div>
        </DefaultCreationFormGroup>
        <DefaultCreationFormButtonGroup>
          <DefaultButton
            type="button"
            className="danger"
            onClick={goToQuestions}
          >
            Cancelar
          </DefaultButton>
          <DefaultButton
            onClick={(e) =>
              isEditting ? updateQuestion(e) : createQuestion(e)
            }
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </CreateAndEditQuestionContainer>
  );
};

export default CreateAndEditQuestion;