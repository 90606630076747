import React, { useEffect, useState, useCallback, useMemo } from "react";
import {
  AiOutlineCheck,
  AiOutlineClose,
  AiOutlineSearch,
} from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";

import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import { DefaultCreateButtonAndSearchFormContainer } from "../../components/DefaultCreateButtonAndSearchFormContainer";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";

import { getCertificates, deleteCertificate, updatedCertificate } from "../../services/certificate";
import getErrorMessage from "../../helpers/get-error-message";
import { ICertificateResponse } from "../../models/from-api-response/certificates";

import * as S from './style';

export function Certificate() {
  const [certificateName, setCertificateName] = useState("");
  const [allCertificates, setAllCertificates] = useState([] as ICertificateResponse[]);
  const [isLoading, setIsLoading] = useState(true);
  const history = useHistory();

  const redirectToCreateCertificate = () => {
    history.push("/certificate/create-certificate");
  };

  const getAllCertificates = useCallback(async () => {
    setIsLoading(true);

    const allCertificateReturn = await getCertificates();
    if(allCertificateReturn && allCertificateReturn.length){
      setAllCertificates(allCertificateReturn);
    }

    setIsLoading(false);
  }, []);

  const editCertificate = useCallback(
    (certificateId: any) => {
      history.push(`/certificate/edit-certificate/${certificateId}`);
    },
    [history]
  );

  const removeCertificate = useCallback(async (certificateId: any) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este template?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCertificate(certificateId);
          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Template excluído com sucesso!",
          });

          await getAllCertificates();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir o template!",
            text: errorMessage,
          });
        }
      }
    });
  }, []);

  const ActiveOrInactiveCertificate = async (certificate: ICertificateResponse) => {
    const result = await Swal.fire({
      icon: "question",
      text: `Tem certeza que deseja ${
        certificate.active ? "inativar" : "ativar"
      } este template?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      try {
        await updatedCertificate({ 
          active: !certificate.active, 
          certificateId: certificate.certificate_template_id,
        });

        Swal.fire({
          icon: "success",
          title: `Template ${certificate.active ? "inativado" : "ativado"} com sucesso!`,
        });

        await getAllCertificates();
      } catch (error) {
        const errorMessage = getErrorMessage(error);
        Swal.fire({
          icon: "error",
          title: `Erro ao ${certificate.active ? "inativar" : "ativar"} template`,
          text: errorMessage,
        });
      }
    }
  }

  const certificatesToShown = useMemo(() => {
    return allCertificates && allCertificates.length ? 
    allCertificates.map((certificate) => ({
      id: certificate.certificate_template_id,
      name: certificate.title,
      active: certificate.active ? <AiOutlineCheck /> : <AiOutlineClose />,
      actions: (
        <div>
          <DefaultButton
            className="small info"
            onClick={() => editCertificate(certificate.certificate_template_id)}
          >
            <BiEdit size={16}/>
          </DefaultButton>{" "}
          <DefaultButton
            title="Ativar/Inativar Template"
            className="small warning"
            onClick={() => ActiveOrInactiveCertificate(certificate)}
          >
            {certificate.active ? (
              <AiOutlineClose size={16}/>
            ) : (
              <AiOutlineCheck size={16}/>
            )}
          </DefaultButton>{" "}
          <DefaultButton
            onClick={() => removeCertificate(certificate.certificate_template_id)}
            className="small danger"
            title="Excluir template"
          >
            <BiTrash size={16}/>
          </DefaultButton>
        </div>
      )
    })) : [];
  }, [allCertificates, editCertificate, removeCertificate]);

  const searchCertificates = useCallback(async (certificate?: React.FormEvent) => {
    if (certificate && certificate.preventDefault) {
      certificate.preventDefault();
    }

    setIsLoading(true);
    try {
      const allCertificatesReturn = await getCertificates({ 
        title: certificateName ? certificateName : undefined,
      });
      setAllCertificates(allCertificatesReturn);

      setIsLoading(false)
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao excluir o template!",
        text: errorMessage,
      });
    }

    setIsLoading(false)
  }, [certificateName])

  useEffect(() => {
    getAllCertificates();
  }, [getAllCertificates]);

  return (
    <S.Container>
      <BreadCrumb
        crumbs={[<Link to="/profile">Perfil</Link>, <span>Templates</span>]}
      />

      <DefaultPageTitle>Templates</DefaultPageTitle>

      <DefaultCreateButtonAndSearchFormContainer>
        <CreateButtonDefaultContainer>
          <DefaultButton onClick={() => redirectToCreateCertificate()}>Criar Template</DefaultButton>
        </CreateButtonDefaultContainer>

        <form
          onSubmit={searchCertificates}
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "stretch",
            gap: "10px",
          }}
        >
          <DefaultInput
            type="search"
            placeholder="Pesquise por nome aqui..."
            value={certificateName}
            onChange={(e) => setCertificateName(e.target.value)}
            style={{ margin: 0 }}
          />
          <DefaultButton className="small" style={{ margin: 0 }} type="submit">
            <AiOutlineSearch size={24} />
          </DefaultButton>
        </form>
      </DefaultCreateButtonAndSearchFormContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Nome</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={certificatesToShown}
        isLoading={isLoading}
        emptyListMessage={"Não foram encontrados templates cadastrados!"}
      />
    </S.Container>
  );
};
