import styled from "styled-components";

export const TableContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  input {
    cursor: pointer;
  }
`;

export const RowName = styled.p`
  color: var(--regular-gray);
  font-size: 16px;
`;