import httpClient from "../http-client";

import { 
  CompanyResponse,
  DepartmentResponse,
  ConstructionResponse 
} from '../models/from-api-response/segmentation';

const listCompany = async () => {
  const companies = 
  (await httpClient.get<CompanyResponse[]>('/companies')).data

  if(companies && companies.length > 0) {
    return companies.map((company) => ({
      value: company.company_id,
      label: company.name,
    }))
  }

  return [] as { value: string; label: string }[];
}

const listDepartment = async () => {
  const department = 
  (await httpClient.get<DepartmentResponse[]>('/departaments')).data;
  if(department && department.length > 0) {
    return department.map((constriction) => ({
      value: constriction.departament_id,
      label: constriction.name
    }))
  }

  return [] as { value: string; label: string }[];
}

const listConstructions = async () => {
  const constructions = 
  (await httpClient.get<ConstructionResponse[]>('/constructions')).data;

  if(constructions && constructions.length > 0) {
    return constructions.map((constriction) => ({
      value: constriction.construction_id,
      label: constriction.name
    }))
  }

  return [] as { value: string; label: string }[];
}

export { listCompany, listDepartment, listConstructions };