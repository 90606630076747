import React from "react";
import {
  CircleSwitch,
  DefaultSwitchContainer,
  LabelSwitch,
  Switch,
} from "./style";

interface IPropsSwitch {
  switchState: string;
  setSwitchState: React.Dispatch<React.SetStateAction<string>>;
  states: { label: string; value: string }[];
}

const DefaultSwitch: React.FC<IPropsSwitch> = ({
  switchState,
  setSwitchState,
  states,
}) => {
  const switchValueState = () => {
    setSwitchState(
      switchState === states[0].value ? states[1].value : states[0].value
    );
  };

  return (
    <DefaultSwitchContainer onClick={switchValueState}>
      <Switch>
        <LabelSwitch className={switchState === states[0].value ? "left" : "right"}>
          {switchState === states[0].value ? states[0].label : states[1].label}
        </LabelSwitch>
        <CircleSwitch
          className={switchState === states[0].value ? "left" : "right"}
        />
      </Switch>
    </DefaultSwitchContainer>
  );
};

export default DefaultSwitch;
