import React, { useCallback, useEffect, useMemo, useState } from "react";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { BiEdit, BiTrash } from "react-icons/bi";
import { Link, useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import BreadCrumb from "../../components/BreadCrumb";
import { CreateButtonDefaultContainer } from "../../components/CreateButtonDefaultContainer";
import DefaultButton from "../../components/DefaultButton";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import DefaultTable from "../../components/DefaultTable";
import getErrorMessage from "../../helpers/get-error-message";
import convertSecondsToTimeText from "../../helpers/seconds-to-time-text";
import Course from "../../models/course";
import {
  deleteCourse,
  getAllCourses,
  activateOrInactivateCourse as activateOrInactivateCourseService,
} from "../../services/modules";
import { CoursesContainer } from "./style";

const Modules: React.FC = () => {
  const history = useHistory();

  const [modules, setModules] = useState([] as Course[]);

  const getCourses = async () => {
    const localCourses = await getAllCourses();
    if (localCourses && localCourses.length) {
      setModules(localCourses);
    }
  };

  const editCourse = useCallback(
    (courseId: string) => {
      history.push(`edit-module/${courseId}`);
    },
    [history]
  );

  const removeCourse = useCallback(async (courseId: string) => {
    Swal.fire({
      title: "<strong>Confirmação</strong>",
      html: "Tem certeza que deseja remover este módulo?",
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await deleteCourse(courseId);

          Swal.fire({
            icon: "success",
            title: "Sucesso!",
            text: "Módulo excluido com sucesso!",
          });

          await getCourses();
        } catch (error) {
          const errorMessage = getErrorMessage(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao excluir módulo.",
            text: errorMessage,
          });
        }
      }
    });
  }, []);

  const createCourse = async () => {
    history.push("create-module");
  };

  const activateOrInactivateCourse = useCallback(
    async (courseId: string, activate: boolean) => {
      Swal.fire({
        icon: "error",
        title: "Confirmação",
        text: `Tem certeza que deseja ${
          activate ? "ativar" : "inativar"
        } este módulo?`,
        showCancelButton: true,
        cancelButtonText: "Cancelar",
        focusConfirm: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await activateOrInactivateCourseService(courseId, activate);

            getCourses();
          } catch (error) {
            const errorMessage = getErrorMessage(error);
            Swal.fire({
              icon: "error",
              title:
                "Erro ao " + (activate ? "ativar" : "inativar") + " módulo",
              text: `Erro ao ${
                activate ? "ativar" : "inativar"
              } módulo. ${errorMessage}`,
            });
          }
        }
      });
    },
    []
  );

  const modulesToBeShown = useMemo(() => {
    return modules && modules.length
      ? modules.map((module) => ({
          name: module.title,
          description: module.description,
          duration: convertSecondsToTimeText(module.duration),
          average: `${module.average}`,
          active: module.is_active ? <AiOutlineCheck /> : <AiOutlineClose />,
          actions: (
            <>
              <DefaultButton
                onClick={() => editCourse(module.course_id)}
                className="small info"
              >
                <BiEdit />
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() =>
                  activateOrInactivateCourse(
                    module.course_id,
                    !module.is_active
                  )
                }
                className="small warning"
                title={(module.is_active ? "Inativar" : "Ativar") + " Conteúdo"}
              >
                {module.is_active ? <AiOutlineClose /> : <AiOutlineCheck />}
              </DefaultButton>{" "}
              <DefaultButton
                onClick={() => removeCourse(module.course_id)}
                className="small danger"
                title="Excluir Módulo"
              >
                <BiTrash />
              </DefaultButton>
            </>
          ),
        }))
      : [];
  }, [modules, editCourse, removeCourse, activateOrInactivateCourse]);

  useEffect(() => {
    getCourses();
  }, []);

  return (
    <CoursesContainer>
      <BreadCrumb
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <span>Trilhas</span>,
          <span>Módulos</span>,
        ]}
      />

      <DefaultPageTitle>Módulos</DefaultPageTitle>

      <CreateButtonDefaultContainer>
        <DefaultButton onClick={createCourse}>Criar Módulo</DefaultButton>
      </CreateButtonDefaultContainer>

      <DefaultTable
        headersConfig={[
          {
            headerLabel: <span>Título</span>,
            propName: "name",
          },
          {
            headerLabel: <span>Descrição</span>,
            propName: "description",
          },
          {
            headerLabel: <span>Ativo</span>,
            propName: "active",
          },
          {
            headerLabel: <span>Ações</span>,
            propName: "actions",
          },
        ]}
        items={modulesToBeShown}
        emptyListMessage={"Não foram encontrados módulos cadastrados!"}
      />
    </CoursesContainer>
  );
};

export default Modules;
