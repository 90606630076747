import React, { useRef, useEffect } from "react";
import { useState } from "react";
import Exam from "../../../../models/exam";
import { ExamCardContainer, SelectExamContainer } from "./style";
import { getExams as getExamsService } from "../../../../services/exams";
import DefaultButton from "../../../../components/DefaultButton";
import $clamp from "clamp-js";
import { hideModal } from "../../../../helpers/modal";

interface ExamCardProps {
  exam: Exam;
  isSelected: boolean;
  selectExam: (exam: Exam) => void;
}

const ExamCard: React.FC<ExamCardProps> = ({
  exam,
  selectExam,
  isSelected,
}) => {
  const examTitle = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (examTitle.current) {
      $clamp(examTitle.current, { clamp: 1 });
    }
  }, [examTitle]);

  return (
    <ExamCardContainer
      onClick={() => selectExam(exam)}
      className={isSelected ? "selected" : ""}
    >
      <h4 ref={examTitle} className="exam-title">
        {exam.title}
      </h4>
    </ExamCardContainer>
  );
};

interface SelectExamProps {
  onSelect: (exam?: Exam) => void;
  initialSelectedExam?: Exam;
}

const SelectExam: React.FC<SelectExamProps> = ({
  onSelect,
  initialSelectedExam,
}) => {
  const [exams, setExams] = useState([] as Exam[]);
  const [selectedExam, setSelectedExam] = useState(
    undefined as Exam | undefined
  );

  const applySelection = () => {
    onSelect(selectedExam);
    hideModal();
  };

  const selectExam = (exam: Exam) => {
    setSelectedExam(exam);
  };

  const getExams = async () => {
    const localExams = await getExamsService({ enabled: true });
    setExams(localExams);
  };

  useEffect(() => {
    getExams();
  }, []);

  useEffect(() => {
    if (initialSelectedExam) {
      setSelectedExam(initialSelectedExam);
    }
  }, [initialSelectedExam]);

  return (
    <SelectExamContainer>
      <DefaultButton
        className="apply-selection"
        onClick={applySelection}
        type="button"
      >
        Aplicar Seleção
      </DefaultButton>

      <div className="exams">
        {exams && exams.length ? (
          exams.map((exam) => (
            <ExamCard
              key={exam.id}
              exam={exam}
              selectExam={selectExam}
              isSelected={!!(selectedExam && exam.id === selectedExam.id)}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </SelectExamContainer>
  );
};

export default SelectExam;
