import httpClient from "../http-client";
import download from "downloadjs";

const generateEngagementReport = async (
  format: string,
  queryString: string
) => {
  const reportResponse = await httpClient.get(`reports/engagement/${format}`, {
    params: queryString,
    responseType: "blob",
  });

  const contentType = reportResponse.headers["content-type"];

  download(reportResponse.data, `relatório_engajamento.${format}`, contentType);
};

const generateFinishedCoursesReport = async (
  format: string,
  queryString: string
) => {
  const reportResponse = await httpClient.get(
    `reports/trail-finished/${format}`,
    {
      params: queryString,
      responseType: "blob",
    }
  );

  const contentType = reportResponse.headers["content-type"];

  download(
    reportResponse.data,
    `relatório_cursos_finalizados.${format}`,
    contentType
  );
};

const generateUserAccessReport = async (
  format: string,
  queryString: string
) => {
  const reportResponse = await httpClient.get(
    `reports/user-access/${format}`,
    {
      params: queryString,
      responseType: "blob",
    }
  );

  const contentType = reportResponse.headers["content-type"];

  download(
    reportResponse.data,
    `relatório_acessos_usuário.${format}`,
    contentType
  );
};

export { generateEngagementReport, generateFinishedCoursesReport, generateUserAccessReport };
