import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Login from "./pages/Login";
import AuthProvider from "./context/AuthContext";
import Loading from "./components/Loading";
import "react-datepicker/dist/react-datepicker.css";

ReactDOM.render(
  <React.StrictMode>
    <Suspense fallback={<Loading />}>
      <Router>
        <AuthProvider>
          <Switch>
            <Route exact path="/" component={Login} />
            <Route exact path="/login" component={Login} />

            <Route component={App} />
          </Switch>
        </AuthProvider>
      </Router>
    </Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);
