import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import { AiOutlinePlus } from 'react-icons/ai';
import { BiTrash } from 'react-icons/bi';
import Select from "react-select";

import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, 
{ 
  DefaultCreationFormButtonGroup, 
  DefaultCreationFormGroup 
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";

import getErrorMessage from "../../helpers/get-error-message";
import { 
  getCertificate as getCertificateService,
  createCertificate as createCertificateService,
  updatedCertificate as updatedCertificateService, 
} from '../../services/certificate';
import { getSignatures } from '../../services/signatures';
 
import * as S from "./style";
import { SelectProps } from "../../models/select";
import { LoadingOnPage } from "../../components/LoadingOnPage";

interface CreateAndEditCertificateParams {
  certificateId: string;
}

export function CreateAndEditCertificate() {
  const history = useHistory();
  const { certificateId } = useParams<CreateAndEditCertificateParams>();
  
  const [templateName, setTemplateName] = useState("");
  const [signatures, setSignatures] = useState<SelectProps[]>([
    { label: '', value: ''}
  ]);
  const [listOfSignatures, setListOfSignatures] = useState<SelectProps[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const goToCertificates = () => {
    history.push('/certificates');
  }

  const checkSignatures = () => {
    const justSignaturesValue = [] as string[];

    signatures.map(signature => 
      signature.value !== '' && justSignaturesValue.push(signature.value)
    );

    return justSignaturesValue;
  }

  const validate = () => {
    checkSignatures()

    if(!templateName) {
      throw new Error("Informe um nome para o template!");
    }

    if(checkSignatures().length === 0) {
      throw new Error("Informe pelo menos uma assinatura para o template!")
    }
  }
  
  const createCertificate = async () => {
    try {
      validate();

      await createCertificateService({
        title: templateName,
        active: true,
        signatures: checkSignatures(),
      });

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Template cadastrado com sucesso!'
      });

      goToCertificates();
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao criar o template",
        text: errorMessage,
      });
    }
  }

  const updateCertificate = async () => {
    try {
      validate();

      await updatedCertificateService({
        certificateId,
        title: templateName,
        active: true,
        signatures: checkSignatures(),
      });
      
      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Template editado com sucesso!'
      });

      goToCertificates();
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao editar o template",
        text: errorMessage,
      });
    }
  }

  const getCertificate = useCallback(async () => {
    setIsLoading(true);
    try {
      const certificateReturn = await getCertificateService(certificateId);
      
      setTemplateName(certificateReturn.title);
      setSignatures(certificateReturn.signs.map(signature => ({ 
        label: signature.name,
        value: signature.sign_id
      })));
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao buscar o template",
        text: errorMessage,
      });
    }

    setIsLoading(false);
  }, [certificateId]);

  const getLisOfSignatures = useCallback(async () => {
    setIsLoading(true);

    const listOfSignaturesReturned = await getSignatures({ active: true });

    if(listOfSignaturesReturned && listOfSignaturesReturned.length > 0) {
      const listOfSignatureForSelect = listOfSignaturesReturned.map(signature => {
        return {
          label: signature.name,
          value: signature.sign_id
        }
      })

      setListOfSignatures(listOfSignatureForSelect);
    }
    setIsLoading(false);
  }, []);

  const handleAddSignature = (option: SelectProps | null, index: number) => {
    if(option) {
      const findAnySignature = signatures.find(
        signature => signature.value === option.value
      );

      if(findAnySignature && signatures[index] !== findAnySignature) {
        Swal.fire({
          icon: "info",
          title: "Assinatura já está sendo utilizada",
        });
      } else {
        setSignatures(prevState => {
          prevState[index] = option

          return [...prevState];
        })
      }
    } else {
      setSignatures(prevState => {
        prevState[index] = { label: '', value: '' }

        return [...prevState];
      })
    }
  }

  const handleExcludeSignature = async (index: number) => {
    const result = await Swal.fire({
      icon: "question",
      text: `Tem certeza que deseja excluir a assinatura ${index + 1}?`,
      showCancelButton: true,
      cancelButtonText: "Cancelar",
    });

    if (result.isConfirmed) {
      const newSignatures = [...signatures];
      newSignatures.splice(index, 1);

      setSignatures(newSignatures);
    }
  }

  const isEditing = useMemo(() => {
    return !!certificateId
  }, [certificateId]);

  useEffect(() => {
    getLisOfSignatures();
    
    if(isEditing) {
      getCertificate();
    }
  }, [isEditing, getCertificate, getLisOfSignatures])

  return (
    <div>
      <BreadCrumb 
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/certificates">Templates</Link>,
          <span>{isEditing ? "Editar" : "Criar"} Template</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditing ? "Editar" : "Criar"} Template
      </DefaultPageTitle>

      {!isLoading ? (
      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome do Template
          </label>

          <DefaultInput
            id="title"
            value={templateName}
            onChange={(e) => setTemplateName(e.target.value)}
          />
        </DefaultCreationFormGroup>

        {signatures.length > 0 && signatures.map((signature, index) => (
          <DefaultCreationFormGroup>
            <label htmlFor="signatures">{`Assinatura ${index + 1}`}</label>

            <S.SignatureContainer>
              <Select
                options={listOfSignatures}
                value={signature}
                onChange={(option) => {
                  handleAddSignature(option, index);
                }}
                id="signatures"
                placeholder="Selecione uma assinatura para este template."
                noOptionsMessage={() => 'Não foi encontrado nenhuma assinatura!'}
                isClearable
                styles={{
                  container: (provided) => ({
                    ...provided,
                    flexGrow: 1,
                    width: "100%",
                  }),
                }}
              />
              {signatures.length > 1 && (
                <S.ExcludeSignatureButton
                  onClick={(e) => {
                    e.preventDefault();
                    handleExcludeSignature(index);
                  }}
                >
                  <BiTrash />
                </S.ExcludeSignatureButton>
              )}
            </S.SignatureContainer>
          </DefaultCreationFormGroup>
        ))}
        
        {signatures.length <= 2 && (
          <DefaultCreationFormButtonGroup>
            <S.AddMoreSignaturesButton 
              onClick={(e) => {
                  e.preventDefault();
                  setSignatures(prevState => 
                    [...prevState, { label: '', value: ''}]
                  );
                }
              }
            >
              <AiOutlinePlus/>
              Adicionar assinatura
            </S.AddMoreSignaturesButton>
          </DefaultCreationFormButtonGroup>
        )}
        

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToCertificates}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            type="button"
            onClick={() => (isEditing ? updateCertificate() : createCertificate())}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
      ) : (
        <LoadingOnPage />
      )}
    </div>
  )
};
