import httpClient from "../http-client";
import { ICertificate } from "../models/certificate";
import { ICertificateUpdate } from "../models/for-update/certificates";
import { ICertificateResponse } from "../models/from-api-response/certificates";
import { IRequestCertificate } from "../models/for-request/certificate";

export const getCertificates = async (
    data?: IRequestCertificate
  ):Promise<ICertificateResponse[]> => {
  return (await httpClient.get('/certificate-template', { params: data })).data
}

export const getCertificate = async (
  certificateId: string
): Promise<ICertificateResponse> => {
  return (await httpClient.get(`/certificate-template/${certificateId}`)).data
}

export const createCertificate = async ({
  title, 
  active, 
  signatures 
}: ICertificate): Promise<void> => {
  await httpClient.post(`/certificate-template`, { 
    title,
    active,
    sign_ids: signatures
  });
}

export const updatedCertificate = async ({
  certificateId,
  title,
  active,
  signatures
}: ICertificateUpdate): Promise<void> => {
  await httpClient.put(`/certificate-template/${certificateId}`, {
    title,
    active,
    sign_ids: signatures
  });
}

export const activateOrInactiveEvent = async (certificateId: string) => {
  return (await httpClient.put(`/certificate-template/${certificateId}/toggle-status`));
};

export const deleteCertificate = async (certificateId: string) => {
  await httpClient.delete(`/certificate-template/${certificateId}`);
};