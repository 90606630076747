import { useCallback, useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";

import BreadCrumb from "../../components/BreadCrumb";
import DefaultButton from "../../components/DefaultButton";
import DefaultCreationForm, 
{ 
  DefaultCreationFormButtonGroup, 
  DefaultCreationFormGroup 
} from "../../components/DefaultCreationForm";
import DefaultInput from "../../components/DefaultInput";
import { DefaultPageTitle } from "../../components/DefaultPageTitle";
import TextareaReactQuill from "../../components/TextareaReactQuill/TextareaReactQuill";

import getErrorMessage from "../../helpers/get-error-message";
import { parseDateAndTime } from '../../converters/convert-date';
import { 
  createEvent as createEventService,
  getEvent as getEventService,
  editEvent
 } from "../../services/googleEvents";

 import * as S from './styled'


interface CreateAndEditEventParams {
  eventId: string;
}

const CreateAndEditEvent: React.FC = () => {
  const history = useHistory();
  const { eventId } = useParams<CreateAndEditEventParams>();
  const today = parseDateAndTime(new Date().toString());
  
  const [eventName, setEventName] = useState("");
  const [eventStartTime, setEventStartTime] = useState(today);
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventDescription, setEventDescription] = useState("");


  const goToEvents = () => {
    history.push('/events');
  }

  const validate = () => {
    if(!eventName) {
      throw new Error("Informe um nome para o evento!");
    }

    if(!eventStartTime) {
      throw new Error("Informe um horário do início para o evento!");
    }

    if(!eventEndTime) {
      throw new Error("Informe um horário do fim para o evento!");
    }

    if(eventStartTime < parseDateAndTime(new Date().toString())) {
      throw new Error("Informe um horário do início maior que o horario atual!");
    }

    if(eventEndTime < parseDateAndTime(new Date().toString())) {
      throw new Error("Informe um horário do fim maior que o horario atual!");
    }

    if(new Date(eventStartTime) >= new Date(eventEndTime)) {
      throw new Error("Informe um horário do fim maior que o horario de início!");

    }
  }
  const createEvent = async () => {
    try {
      validate();

      if(eventStartTime && eventEndTime) {
        await createEventService({
          name: eventName,
          startDate: parseDateAndTime(eventStartTime),
          stopDate: parseDateAndTime(eventEndTime),
          description: eventDescription
        });

        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Evento cadastrado com sucesso!'
        });

        goToEvents();
      }
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao criar evento",
        text: errorMessage,
      });
    }
  }

  const updateEvent = async () => {
    try {
      validate();

      if(eventStartTime && eventEndTime) {
        await editEvent(eventId, {
          name: eventName,
          startDate: parseDateAndTime(eventStartTime),
          stopDate: parseDateAndTime(eventEndTime),
          description: eventDescription
        });

        Swal.fire({
          icon: 'success',
          title: 'Sucesso!',
          text: 'Evento cadastrado com sucesso!'
        });

        goToEvents();
      }

      Swal.fire({
        icon: 'success',
        title: 'Sucesso!',
        text: 'Evento editado com sucesso!'
      });

      goToEvents();
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao editar evento",
        text: errorMessage,
      });
    }
  }

  const getEvent = useCallback(async () => {
    try {
      const eventReturn = await getEventService(eventId);
      
      setEventName(eventReturn.name);
      setEventStartTime(parseDateAndTime(eventReturn.start_date));
      setEventEndTime(parseDateAndTime(eventReturn.stop_date));
      setEventDescription(eventReturn.description);
    } catch(error) {
      const errorMessage = getErrorMessage(error);
      Swal.fire({
        icon: "error",
        title: "Erro ao buscar o evento",
        text: errorMessage,
      });
    }

  }, [eventId]);

  const isEditing = useMemo(() => {
    return !!eventId
  }, [eventId]);

  useEffect(() => {
    if(isEditing) {
      getEvent();
    }
  }, [isEditing])

  return (
    <div>
      <BreadCrumb 
        crumbs={[
          <Link to="/profile">Perfil</Link>,
          <Link to="/events">Eventos</Link>,
          <span>{isEditing ? "Editar" : "Criar"} Evento</span>,
        ]}
      />

      <DefaultPageTitle>
        {isEditing ? "Editar" : "Criar"} Evento
      </DefaultPageTitle>

      <DefaultCreationForm>
        <DefaultCreationFormGroup>
          <label className="required" htmlFor="title">
            Nome do evento
          </label>

          <DefaultInput
            id="title"
            value={eventName}
            onChange={(e) => setEventName(e.target.value)}
          />
        </DefaultCreationFormGroup>

        <S.WrapperDate >
          <DefaultCreationFormGroup>
            <label className="required" htmlFor="eventStartTime">
              Horário do início
            </label>

            <S.InputDate
              type='datetime-local'
              id='eventStartTime'
              min={today}
              value={isEditing ? eventStartTime : undefined}
              defaultValue={today}
              required
              onChange={(e) => setEventStartTime(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return false;
              }}
            />
          </DefaultCreationFormGroup>

          <DefaultCreationFormGroup>
            <label className="required" htmlFor="eventEndTime">
              Horário do fim
            </label>

            <S.InputDate
              type='datetime-local'
              id='eventEndTime'
              min={eventStartTime}
              value={isEditing ? eventEndTime : undefined}
              required
              onChange={(e) => setEventEndTime(e.target.value)}
              onKeyDown={(e) => {
                e.stopPropagation();
                e.preventDefault();
                return false;
              }}
            />
          </DefaultCreationFormGroup>
        </S.WrapperDate>
        <DefaultCreationFormGroup>
          <label htmlFor="description">Descrição</label>
          <TextareaReactQuill state={eventDescription} setState={setEventDescription} />
        </DefaultCreationFormGroup>

        <DefaultCreationFormButtonGroup>
          <DefaultButton type="button" className="danger" onClick={goToEvents}>
            Cancelar
          </DefaultButton>
          <DefaultButton
            type="button"
            onClick={() => (isEditing ? updateEvent() : createEvent())}
            className="success"
          >
            Salvar
          </DefaultButton>
        </DefaultCreationFormButtonGroup>
      </DefaultCreationForm>
    </div>
  )
}

export default CreateAndEditEvent;
